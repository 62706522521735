<template>
  <div class="welcome-container">
    <DialogRap
      v-model:visible="showDialog"
      class="p-dialog-maximized"
      :closeOnEscape="false"
      :closable="false"
    >
      <template #container="{ closeCallback }">
        <div class="tutorial-content">
          <CarouselRap
            :value="slides"
            :numVisible="1"
            :numScroll="1"
            :circular="false"
            :showNavigators="false"
            :showIndicators="false"
            class="tutorial-carousel"
            :class="{ transitioning: isTransitioning }"
            v-model:page="currentPage"
            @page="onPageChange"
          >
            <template #item="slotProps">
              <div
                class="slide-wrapper"
                :class="{ 'slide-enter': !isTransitioning }"
                :key="currentPage"
              >
                <div class="illustration-area">
                  <template v-if="currentPage !== 3">
                    <div class="image-container">
                      <picture v-if="currentPage === 0">
                        <!-- Mobile devices -->
                        <source
                          media="(max-width: 767px)"
                          :srcset="kv1Mobile"
                        />
                        <!-- Tablets -->
                        <source
                          media="(max-width: 1023px)"
                          :srcset="kv1Tablet"
                        />
                        <!-- Desktop -->
                        <source
                          media="(min-width: 1024px)"
                          :srcset="kv1Desktop"
                        />
                        <!-- Fallback -->
                        <img
                          :src="kv1Tablet"
                          :alt="slotProps.data.title"
                          class="slide-image"
                          style="border-radius: 10px"
                        />
                      </picture>
                      <picture v-else-if="currentPage === 1">
                        <!-- Mobile devices -->
                        <source
                          media="(max-width: 767px)"
                          :srcset="kv2Mobile"
                        />
                        <!-- Tablets -->
                        <source
                          media="(max-width: 1023px)"
                          :srcset="kv2Tablet"
                        />
                        <!-- Desktop -->
                        <source
                          media="(min-width: 1024px)"
                          :srcset="kv2Desktop"
                        />
                        <!-- Fallback -->
                        <img
                          :src="kv2Tablet"
                          :alt="slotProps.data.title"
                          class="slide-image"
                          style="border-radius: 10px"
                        />
                      </picture>
                      <picture v-else-if="currentPage === 2">
                        <!-- Mobile devices -->
                        <source
                          media="(max-width: 767px)"
                          :srcset="kv3Mobile"
                        />
                        <!-- Tablets -->
                        <source
                          media="(max-width: 1023px)"
                          :srcset="kv3Tablet"
                        />
                        <!-- Desktop -->
                        <source
                          media="(min-width: 1024px)"
                          :srcset="kv3Desktop"
                        />
                        <!-- Fallback -->
                        <img
                          :src="kv3Tablet"
                          :alt="slotProps.data.title"
                          class="slide-image"
                          style="border-radius: 10px"
                        />
                      </picture>
                    </div>
                  </template>
                  <template v-else>
                    <div class="loading-container">
                      <div class="loading-header">
                        <div
                          class="icon-container"
                          :class="{ 'icon-animate': iconAnimating }"
                          :style="{
                            backgroundColor: `${slotProps.data.color}15`,
                          }"
                        >
                          <i
                            :class="slotProps.data.icon"
                            :style="{ color: slotProps.data.color }"
                          ></i>
                        </div>
                      </div>

                      <div class="loading-progress">
                        <div
                          class="progress-bar-animated"
                          :style="{ width: `${loadingProgress}%` }"
                        ></div>
                      </div>

                      <div class="loading-steps">
                        <div
                          v-for="(step, index) in slotProps.data.loadingSteps"
                          :key="index"
                          class="loading-step"
                          :style="{
                            animationDelay: `${index * 0.2}s`,
                            color:
                              loadingProgress >= getStepThreshold(index)
                                ? '#60C3D9'
                                : '#666',
                          }"
                        >
                          <i
                            class="pi"
                            :class="[
                              index === 3 &&
                              loadingProgress >= 75 &&
                              loadingProgress < 100
                                ? 'pi-spin pi-spinner'
                                : loadingProgress >= getStepThreshold(index)
                                ? 'pi-check-circle'
                                : 'pi-clock',
                            ]"
                            :style="{
                              color:
                                loadingProgress >= getStepThreshold(index)
                                  ? '#60C3D9'
                                  : '#666',
                            }"
                          ></i>
                          {{ step }}
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </CarouselRap>

          <div class="bottom-controls">
            <div class="progress-container">
              <div class="progress-bar">
                <div
                  class="progress-fill"
                  :style="{
                    width: `${((currentPage + 1) / slides.length) * 100}%`,
                    backgroundColor: '#AFD60F',
                  }"
                ></div>
              </div>
            </div>

            <div class="navigation-controls">
              <ButtonRap
                v-if="currentPage > 0"
                label="Anterior"
                icon="pi pi-arrow-left"
                @click="handlePrevious"
                text
                severity="secondary"
                outlined
                class="hover:!bg-white/10"
              />
              <div v-else class="placeholder-button"></div>

              <ButtonRap
                :label="
                  currentPage === slides.length - 1 ? 'Comenzar' : 'Siguiente'
                "
                :icon="
                  currentPage === slides.length - 1
                    ? 'pi pi-check'
                    : 'pi pi-arrow-right'
                "
                :iconPos="currentPage === slides.length - 1 ? 'left' : 'right'"
                @click="
                  currentPage === slides.length - 1
                    ? handleFinish(closeCallback)
                    : handleNext()
                "
                :style="{ backgroundColor: '#AFD60F', color: '#FFF' }"
              />
            </div>
          </div>
        </div>
      </template>
    </DialogRap>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from "vue";
import { useZohoChat } from "@/composables/useZohoChat";
// KV 1
import kv3Mobile from "../assets/device/KV 1/01-1242X2208.jpg";
import kv3Tablet from "../assets/device/KV 1/01-2048x2732.jpg";
import kv3Desktop from "../assets/device/KV 1/01-1920x1080.jpg";

// KV 2
import kv2Mobile from "../assets/device/KV 2/02-1242X2208.jpg";
import kv2Tablet from "../assets/device/KV 2/02-2048x2732.jpg";
import kv2Desktop from "../assets/device/KV 2/02-1920x1080.jpg";

// KV 3
import kv1Mobile from "../assets/device/KV 3/03-1242x2208.jpg";
import kv1Tablet from "../assets/device/KV 3/03-2048X2732.jpg";
import kv1Desktop from "../assets/device/KV 3/03-1920x1080.jpg";

const { hideChat } = useZohoChat();
const showDialog = ref(false);
const currentPage = ref(0);
const iconAnimating = ref(false);
const loadingProgress = ref(0);
const isTransitioning = ref(false);

const WELCOME_COMPLETED_KEY = "welcome_tutorial_completed";

const slides = ref([
  {
    icon: "pi pi-home text-6xl",
    title: "Tu Portal RAP",
    description:
      "Consulta tus fondos y realiza tus gestiones de manera segura y rápida.",
    color: "#AFD60F",
  },
  {
    icon: "pi pi-money-bill text-6xl",
    title: "Préstamos y Beneficios",
    description:
      "Solicita préstamos y descubre todos los beneficios disponibles para ti.",
    color: "#FF9700",
  },
  {
    icon: "pi pi-shield text-6xl",
    title: "Tu Seguridad es Importante",
    description: "Mantén tus datos seguros y protegidos.",
    color: "#60C3D9",
  },
  {
    icon: "pi pi-shield text-6xl",
    title: "Configurando Tu Dispositivo",
    description:
      "Estamos preparando todo para brindarte una experiencia segura.",
    color: "#60C3D9",
    loadingSteps: [
      "Verificando dispositivo...",
      "Configurando servicios...",
      "Activando protección...",
      "¡Casi listo!",
    ],
  },
]);

const hasCompletedTutorial = () => {
  return localStorage.getItem(WELCOME_COMPLETED_KEY) === "true";
};

const saveTutorialCompletion = () => {
  localStorage.setItem(WELCOME_COMPLETED_KEY, "true");
};

const getStepThreshold = index => {
  if (index === 3) return 100;
  return (index + 1) * 25;
};

const handleTransition = async direction => {
  isTransitioning.value = true;

  setTimeout(() => {
    if (direction === "next") {
      currentPage.value++;
    } else {
      currentPage.value--;
    }

    isTransitioning.value = false;
  }, 300);

  hideChat();
};

const handleNext = () => handleTransition("next");
const handlePrevious = () => handleTransition("prev");

const handleFinish = closeCallback => {
  if (loadingProgress.value < 100) return;

  setTimeout(() => {
    saveTutorialCompletion();
    closeCallback();
  }, 500);
};

const onPageChange = () => {
  animateIcon();
};

const animateIcon = () => {
  iconAnimating.value = true;
  setTimeout(() => {
    iconAnimating.value = false;
  }, 1000);
};

watch(currentPage, newValue => {
  if (newValue === 3) {
    startLoadingProgress();
  }
});

const startLoadingProgress = () => {
  loadingProgress.value = 0;
  const interval = setInterval(() => {
    if (loadingProgress.value < 75) {
      loadingProgress.value += 25;
    } else if (loadingProgress.value === 75) {
      setTimeout(() => {
        loadingProgress.value = 100;
      }, 2000);
      clearInterval(interval);
    }
  }, 1000);
};

onMounted(() => {
  if (!hasCompletedTutorial()) {
    showDialog.value = true;
    hideChat();
  }
});
</script>

<style scoped>
.tutorial-content {
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #fff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.tutorial-carousel {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.tutorial-carousel.transitioning::after {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(255, 255, 255, 0.1);
  pointer-events: none;
  opacity: 1;
  transition: opacity 0.3s ease-out;
}

.tutorial-carousel :deep(.p-carousel-items-content) {
  height: 100%;
  width: 100%;
}

.tutorial-carousel :deep(.p-carousel-items-container) {
  height: 100%;
  width: 100%;
}

.tutorial-carousel :deep(.p-carousel-item) {
  height: 100%;
  width: 100%;
}

.slide-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 0;
  padding-bottom: 80px;
  gap: 0;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.3s ease-out;
  position: relative;
}

.slide-wrapper.slide-enter {
  opacity: 1;
  transform: translateY(0);
}

.illustration-area {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 100px);
  transition: opacity 0.3s ease-out;
  padding: 0;
}

.image-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.slide-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-out;
}

.brand-logo-container {
  position: fixed;
  top: 30px;
  left: 30px;
  z-index: 10;
}

.brand-logo {
  width: 80px;
  height: auto;
}

.loading-container {
  padding: 1.5rem;
  width: 100%;
  max-width: 280px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 16px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease-out;
}

.loading-header {
  text-align: center;
  margin-bottom: 1.5rem;
}

.loading-progress {
  height: 6px;
  background: #eee;
  border-radius: 3px;
  overflow: hidden;
  margin: 1rem 0;
}

.progress-bar-animated {
  height: 100%;
  background: linear-gradient(90deg, #60c3d9, #afd60f);
  transition: width 0.3s ease-out;
}

.loading-steps {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.loading-step {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  font-size: 0.9rem;
  padding: 0.8rem;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease-out;
  position: relative;
}

.bottom-controls {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.9);
  z-index: 10;
}

.progress-container {
  padding: 0 1rem;
  margin-bottom: 0.5rem;
}

.progress-bar {
  width: 100%;
  height: 4px;
  background-color: #e9ecef;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
}

.progress-fill {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  transition: width 0.3s ease-in-out;
  border-radius: 2px;
}

.navigation-controls {
  display: flex;
  justify-content: end;
  align-items: flex-start;
  gap: 0.5rem;
  width: 100%;
  padding: 0 1rem;
}

.icon-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  transition: transform 0.3s ease-out;
}

.icon-container.icon-animate {
  animation: iconPulse 1s ease-out;
}

/* Mobile devices */
@media (max-width: 767px) {
  .brand-logo-container {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  .illustration-area {
    height: calc(100vh - 120px);
  }

  .image-container {
    width: 100%;
    max-width: none;
    height: 100%;
  }

  .slide-image {
    object-fit: cover;
  }

  .navigation-controls {
    padding: 0 0.5rem;
  }
}

/* Tablets */
@media (min-width: 768px) and (max-width: 1023px) {
  .brand-logo-container {
    top: 30px;
    left: 30px;
  }

  .illustration-area {
    height: calc(100vh - 120px);
  }

  .image-container {
    width: 100%;
    max-width: none;
    height: 100%;
  }
}

/* Desktop */
/* Desktop */
/* Desktop */
@media (min-width: 1024px) {
  .illustration-area {
    height: auto;
    min-height: 400px;
    max-height: calc(100vh - 100px);
    /* padding: 1rem; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-container {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 1rem; */
  }

  .slide-image {
    width: auto;
    height: calc(100vh - 100px);
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

/* For very small height screens */
/* @media (max-height: 600px) {
  .illustration-area {
    height: calc(100vh - 100px);
  }

  .image-container {
    max-height: calc(100vh - 100px);
  }
} */

@keyframes iconPulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
