<template>
  <!--not logged bar-->
  <div
    class="menu bg-med-blue px-4 py-3 flex z-5"
    v-if="!global.firebaseToken"
    :class="{ hidden: !user.token }"
  >
    <!--account icon-->
    <router-link to="/" class="w-full text-center">
      <div class="m-auto">
        <div class="icon relatve w-7 inline-block">
          <img class="inline w-full" src="@/assets/png/icons/home.png" />
        </div>
        <div class="text font-bold text-pal-1 text-sm">Inicio</div>
      </div>
    </router-link>

    <!--info icon-->
    <router-link to="/info" class="w-full text-center">
      <div class="m-auto">
        <div class="icon relatve w-7 inline-block">
          <img class="inline w-full" src="@/assets/png/icons/info.png" />
        </div>
        <div class="text font-bold text-pal-1 text-sm">Información</div>
      </div>
    </router-link>
  </div>

  <!--logged in bar-->
  <div
    class="menu bg-med-blue px-4 py-3 z-5 md:w-50 md:z-0 md:bg-pal-menu md:p-4"
    v-else-if="!global.updateMode"
  >
    <!--brand-->
    <div
      class="bg-white p-4 mb-3 -ml-6 rounded-r-md text-center flex hidden md:block"
    >
      <img class="w-2/6 m-auto" src="@/assets/png/brand.png" />
    </div>

    <div class="w-full hidden md:block text-center mt-2 -mb-2">
      <img
        class="w-2/5 rounded-full inline mb-4"
        src="@/assets/jpg/profile.jpg"
      />
    </div>

    <div class="font-bold text-white text-center mb-3 hidden md:block">
      {{ user.info.firstName }} {{ user.info.firstLastName }}
    </div>

    <div
      class="flex md:flex-col w-full text-pal-1 md:text-white md:space-y-3"
      style="margin-bottom: 70px"
    >
      <!--account icon-->
      <router-link
        to="/account"
        class="w-full text-center md:bg-white/40 rounded-md hover:opacity-70"
      >
        <div class="m-auto md:flex md:w-full md:px-4 md:py-2">
          <div class="icon relatve w-7 inline-block">
            <img
              class="inline w-full md:hidden"
              src="@/assets/png/icons/home.png"
            />
            <img
              class="inline w-full hidden md:inline w-5 md:w-5"
              src="@/assets/png/icons/white/home_white.png"
            />
          </div>
          <div class="text font-bold text-sm my-auto">Inicio</div>
        </div>
      </router-link>

      <!--loans icon-->
      <router-link
        to="/loans"
        class="w-full text-center md:bg-white/40 rounded-md hover:opacity-70"
      >
        <div class="m-auto md:flex md:w-full md:px-4 md:py-2">
          <div class="icon relatve w-7 inline-block">
            <img
              class="inline w-full md:hidden"
              src="@/assets/png/icons/briefcase_.png"
            />
            <img
              class="inline w-full hidden md:inline w-5 md:w-5"
              src="@/assets/png/icons/white/briefcase_white.png"
            />
          </div>
          <div class="text font-bold text-sm my-auto">Préstamos</div>
        </div>
      </router-link>

      <!--info icon-->
      <router-link
        to="/info"
        class="w-full text-center md:bg-white/50 rounded-md hover:opacity-70"
      >
        <div class="m-auto md:flex md:w-full md:px-4 md:py-2">
          <div class="icon relatve w-7 inline-block">
            <img
              class="inline w-full md:hidden"
              src="@/assets/png/icons/book.png"
            />
            <img
              class="inline w-full hidden md:inline w-5 md:w-5"
              src="@/assets/png/icons/white/book_white.png"
            />
          </div>
          <div class="text font-bold text-sm my-auto">Información</div>
        </div>
      </router-link>

      <router-link
        to="/profile"
        class="w-full text-center md:bg-white/50 rounded-md hidden md:block hover:opacity-70"
      >
        <div class="m-auto md:flex md:w-full md:px-4 md:py-2">
          <div class="icon relatve w-7 inline-block">
            <img
              class="inline w-full md:hidden"
              src="@/assets/png/icons/account.png"
            />
            <img
              class="inline w-full hidden md:inline w-5 md:w-5"
              src="@/assets/png/icons/white/account-white.png"
            />
          </div>
          <div class="text font-bold text-sm my-auto">Perfil</div>
        </div>
      </router-link>

      <router-link
        to="/updateData"
        class="w-full text-center md:bg-white/50 rounded-md hidden md:block hover:opacity-70"
      >
        <div class="m-auto md:flex md:w-full md:px-4 md:py-2">
          <div class="icon relatve w-7 inline-block">
            <img
              class="inline w-full md:hidden"
              src="@/assets/png/icons/replace.png"
            />
            <img
              class="inline w-full hidden md:inline w-5 md:w-5"
              src="@/assets/png/icons/white/replace_white.png"
            />
          </div>
          <div class="text font-bold text-sm my-auto">Actualizar Datos</div>
        </div>
      </router-link>

      <router-link
        to="/config"
        class="w-full text-center md:bg-white/50 rounded-md hidden md:block hover:opacity-70"
      >
        <div class="m-auto md:flex md:w-full md:px-4 md:py-2">
          <div class="icon relatve w-7 inline-block">
            <img
              class="inline w-full md:hidden"
              src="@/assets/png/icons/settings.png"
            />
            <img
              class="inline w-full hidden md:inline w-5 md:w-5"
              src="@/assets/png/icons/white/settings_white.png"
            />
          </div>
          <div class="text font-bold text-sm my-auto">Configuración</div>
        </div>
      </router-link>

      <div
        v-if="isNativePlatform && global.visibleBiometric"
        class="logout w-full text-center md:bg-white/50 rounded-md hidden md:block hover:opacity-70 cursor-pointer"
        @click="activeBiometric"
      >
        <div class="m-auto md:flex md:w-full md:px-4 md:py-2">
          <div class="icon relatve w-7 inline-block">
            <img
              class="inline w-full hidden md:inline w-5"
              src="@/assets/png/icons/white/fingerprint.png"
            />
          </div>
          <div class="text font-bold text-sm my-auto">
            {{
              this.global.activeBiometric
                ? "Desactivar Biométrico"
                : "Activar Biométrico"
            }}
          </div>
        </div>
      </div>

      <!-- <div
        class="logout w-full text-center md:bg-white/50 rounded-md hidden md:block hover:opacity-70 cursor-pointer"
        @click="clearCache"
      >
        <div class="m-auto md:flex md:w-full md:px-4 md:py-2">
          <div class="icon relatve w-7 inline-block">
            <img
              class="inline w-full md:hidden"
              src="@/assets/png/icons/account.png"
            />
            <img
              class="inline w-full hidden md:inline w-5 md:w-5"
              src="@/assets/png/icons/white/account-white.png"
            />
          </div>
          <div class="text font-bold text-sm my-auto">Limpiar Cache</div>
        </div>
      </div> -->

      <div
        class="logout w-full text-center md:bg-white/50 rounded-md hidden md:block hover:opacity-70 cursor-pointer"
        @click="logout"
      >
        <div class="m-auto md:flex md:w-full md:px-4 md:py-2">
          <div class="icon relatve w-7 inline-block">
            <img
              class="inline w-full md:hidden"
              src="@/assets/png/icons/logout.png"
            />
            <img
              class="inline w-full hidden md:inline w-5 md:w-5"
              src="@/assets/png/icons/white/logout_white.png"
            />
          </div>
          <div class="text font-bold text-sm my-auto">Cerrar Sesión</div>
        </div>
      </div>

      <!--support icon-->
      <!--<router-link to = "/support" class = "w-full text-center">
            <div class = "m-auto">
                <div class = "icon relatve w-7 inline-block">
                    <img class = "inline w-full" src = "@/assets/png/icons/support.png">
                </div>
                <div class = "text font-bold text-pal-1 text-sm">
                    Soporte
                </div>
            </div>
        </router-link>-->
    </div>
  </div>

  <div
    class="overlay fixed w-full h-full overflow-x-hidden z-100"
    :class="{ 'pointer-events-none': !global.showMenu }"
    v-if="global.showMenu"
  >
    <div
      class="overlay absolute w-full h-full left-0 top-0 cursor-pointer bg-black/40 transition-opacity duration-500 backdrop-blur-sm"
      @click="global.showMenu = false"
      :class="{ 'opacity-0': !global.showMenu }"
    ></div>
    <div
      class="panel bg-lit-blue h-full w-4/5 absolute right-0 left-auto p-6 flex flex-col transition-right duration-300"
      :class="{ '-right-full': !global.showMenu }"
    >
      <div class="flex mb-4">
        <div class="w-full">
          <img class="w-12" src="@/assets/png/brand.png" />
        </div>
        <div class="w-full text-right">
          <img
            class="h-6 inline cursor-pointer"
            src="@/assets/png/icons/close.png"
            @click="closeMenu()"
          />
        </div>
      </div>

      <div class="h-full flex">
        <div class="w-full my-auto text-center">
          <img
            class="w-1/2 rounded-full inline mb-4"
            src="@/assets/jpg/profile.jpg"
          />
          <div class="name font-bold mb-3 text-lg">
            {{ user.info.firstName }} {{ user.info.firstLastName }}
          </div>
          <router-link
            to="/profile"
            class="bg-white font-bold rounded-xl p-2 drop-shadow mb-4 block opacity-100"
            @click="global.showMenu = false"
          >
            Perfil
          </router-link>
          <router-link
            to="/updateData"
            class="bg-white font-bold rounded-xl p-2 drop-shadow mb-4 block opacity-100"
            @click="global.showMenu = false"
          >
            Actualizar Datos
          </router-link>
          <router-link
            to="/config"
            class="bg-white font-bold rounded-xl p-2 drop-shadow mb-4 block opacity-100"
            @click="global.showMenu = false"
          >
            Configuración
          </router-link>

          <div
            v-if="global.visibleBiometric"
            class="bg-white font-bold rounded-xl p-2 drop-shadow mb-4 cursor-pointer"
            @click="activeBiometric"
          >
            {{
              this.global.activeBiometric
                ? "Desactivar Biométrico"
                : "Activar Biométrico"
            }}
          </div>
          <!-- <div
            class="bg-white font-bold rounded-xl p-2 drop-shadow mb-4 cursor-pointer"
            @click="clearCache"
          >
            Limpiar Cache
          </div> -->

          <div
            class="bg-white font-bold rounded-xl p-2 drop-shadow mb-4 cursor-pointer"
            @click="logout"
          >
            Cerrar Sesión
          </div>
          <div class="bg-black/10 p-4 rounded-xl text-center hidden">
            <img class="w-1/3 inline-block" src="@/assets/svg/bell.svg" /><br />
            No tienes notificaciones nuevas
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useGlobalStore } from "@/store/GlobalStore";
import { useUserStore } from "@/store/UserStore";
import { NativeBiometric } from "capacitor-native-biometric";
import { Capacitor } from "@capacitor/core";
import { useLog } from "@/composables/useLog";

export default {
  data() {
    return {
      biometric: false,
      isNativePlatform: false,
    };
  },

  setup() {
    const global = useGlobalStore();
    const user = useUserStore();
    const log = useLog();

    const closeMenu = () => {
      global.showMenu = false;
    };

    return { global, user, closeMenu, log };
  },

  created() {
    this.isNativePlatform = Capacitor.isNativePlatform();
    this.global.activeBiometric =
      localStorage.getItem("credentialsSet") === "true";
  },

  methods: {
    // async clearCache() {
    //   const clear = confirm(
    //     "¿Está seguro que desea limpiar la cache? \n\nSi lo hace, deberá volver a iniciar sesión con su usuario y contraseña"
    //   );

    //   if (clear) {
    //     this.deleteCredential();

    //     // Eliminar el almacenamiento local
    //     localStorage.clear();

    //     // Eliminar el almacenamiento de sesión
    //     sessionStorage.clear();

    //     // Eliminar el caché
    //     if ("caches" in window) {
    //       try {
    //         const keyList = await caches.keys();
    //         await Promise.all(keyList.map(key => caches.delete(key)));
    //         console.log("El caché ha sido eliminado.");
    //       } catch (error) {
    //         console.error("Error al eliminar el caché:", error);
    //       }
    //     } else {
    //       console.log("El API de caché no está soportado en este navegador.");
    //     }

    //     console.log("El almacenamiento local y de sesión han sido eliminados.");

    //     this.logout();
    //   } else {
    //     console.log("No se eliminó la cache");
    //   }
    // },

    async logout() {
      const username = localStorage.getItem("saveusername") || "";

      try {
        // Iniciar el registro de actividad exitosa
        await this.log.registerAuthActivity(username, true, "logout");

        // Intentar hacer el signOut
        await this.user.signOut();
        this.$router.go();
      } catch (error) {
        console.error("Error during logout:", error);

        // Si ocurre cualquier error, registrar la actividad como fallida
        try {
          await this.log.registerAuthActivity(username, false, "logout");
        } catch (regError) {
          console.error("Error registering failed logout:", regError);
        }

        // Intentar forzar el signOut de todos modos
        try {
          await this.user.signOut();
        } catch (signOutError) {
          console.error("Error during force signOut:", signOutError);
        }

        // Recargar la página en cualquier caso
        this.$router.go();
      }
    },

    deleteCredential() {
      NativeBiometric.deleteCredentials({
        server: "https://piloto.rap.hn",
      });
      localStorage.removeItem("credentialsSet");
      localStorage.removeItem("uid");
      localStorage.removeItem("unb");
    },

    activeBiometric() {
      const credentialsSet = localStorage.getItem("credentialsSet");

      if (!credentialsSet || credentialsSet === "false") {
        const activation = confirm(
          "¿Está seguro que desea activar el biométrico? \n\nSi lo hace, deberá volver a iniciar sesión con su usuario y contraseña"
        );

        if (activation) {
          this.deleteCredential();
          this.global.activeBiometric = true;
          this.logout();
        } else {
          localStorage.setItem("credentialsSet", "false");
          this.global.activeBiometric = false;
        }
      } else {
        if (credentialsSet === "true") {
          const deactivation = confirm(
            "¿Está seguro que desea desactivar el biométrico? \n\nSi lo hace, deberá volver a iniciar sesión con su usuario y contraseña"
          );

          if (deactivation) {
            this.deleteCredential();
            this.global.activeBiometric = false;
            this.logout();
          } else {
            localStorage.setItem("credentialsSet", "true");
            this.global.activeBiometric = true;
          }
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.menu {
  z-index: 5;
}

.overlay {
  z-index: 10;
}

.panel {
  z-index: 15;
  padding-top: calc(24px + env(safe-area-inset-top));
}

@media only screen and (max-width: 768px) {
  .menu {
    box-shadow: 0 -2px 8px rgba(black, 0.075);
    padding-bottom: calc(0.75rem + env(safe-area-inset-bottom));

    a:not(.router-link-active) {
      opacity: 0.5;
    }
  }
}

@media only screen and (min-width: 768px) {
  .menu {
    a:not(.router-link-active) {
      background-color: rgba(255, 255, 255, 0.15) !important;
      //border: 1px white;
    }

    .logout {
      background-color: rgba(255, 255, 255, 0.15) !important;
    }
  }
}
</style>
