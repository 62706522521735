import { initDB } from './db';
import moment from 'moment';
export const dbStatement = () => {

	const storeName = 'statement';


	// const deleteTotalStatement = async () => {
	// 	console.log('Initializing deleteStatement: ');
	// 	try {
	// 		deleteDB();
	// 	} catch (error) {
	// 		console.log('Error in deleteStatement:', error);
	// 	}
	// };

	const createStatement = async (id, statement) => {
		try {
			const db = await initDB([storeName]);
			const transaction = db.transaction([storeName], "readwrite");
			const store = transaction.objectStore(storeName);
			const serializableStatement = JSON.parse(JSON.stringify(statement));
			const request = store.add({ id, date: new Date(), statement: serializableStatement });
			return new Promise((resolve, reject) => {
				request.onsuccess = (event) => {
					resolve(event);
				};
				request.onerror = (event) => {
					console.log('Statement failed to create');
					reject(event);
				};
			});
		} catch (error) {
			console.error('Error in createStatement:', error);
			throw error;
		}
	};
	const getStatement = async (id) => {
		try {
			const db = await initDB([storeName]);
			const transaction = db.transaction([storeName], "readonly");
			const store = transaction.objectStore(storeName);
			const request = store.get(id);
			return new Promise((resolve, reject) => {
				request.onsuccess = () => {
					resolve(request.result);
				};
				request.onerror = (event) => {
					console.log('Statement failed to retrieve', event);
					reject(event);
				};
			});
		} catch (error) {
			console.error('Error in getStatement:', error);
			throw error;
		}
	};

	// const updateStatement = async (id, statement) => {
	// 	try {
	// 		const db = await initDB([storeName]);
	// 		const transaction = db.transaction([storeName], "readwrite");
	// 		const store = transaction.objectStore(storeName);
	// 		const serializableStatement = JSON.parse(JSON.stringify(statement));
	// 		let request;
	// 		if (serializableStatement.statement == undefined) {
	// 			request = store.put({ id, date: new Date(), statement: serializableStatement });
	// 		} else {
	// 			request = store.put({ id, date: new Date(), statement: serializableStatement.statement });
	// 		}
	// 		return new Promise((resolve, reject) => {
	// 			request.onsuccess = (event) => {
	// 				console.log('Statement updated successfully');
	// 				resolve(event);
	// 			};
	// 			request.onerror = (event) => {
	// 				console.log('Statement failed to update');
	// 				reject(event);
	// 			};
	// 		});
	// 	} catch (error) {
	// 		console.error('Error in updateStatement:', error);
	// 		// throw error;
	// 	}
	// };
	const updateStatement = async (id, statement) => {
		try {
			const db = await initDB([storeName]);
			const transaction = db.transaction([storeName], "readwrite");
			const store = transaction.objectStore(storeName);
			const serializableStatement = JSON.parse(JSON.stringify(statement));
			const formattedDate = moment().format('YYYY-MM-DD HH:mm:ss'); // Formato de fecha legible
			let request;
			if (serializableStatement.statement == undefined) {
				request = store.put({ id, date: formattedDate, statement: serializableStatement });
			} else {
				request = store.put({ id, date: formattedDate, statement: serializableStatement.statement });
			}
			return new Promise((resolve, reject) => {
				request.onsuccess = (event) => {
					resolve(event);
				};
				request.onerror = (event) => {
					console.log('Statement failed to update');
					reject(event);
				};
			});
		} catch (error) {
			console.error('Error in updateStatement:', error);
			// throw error;
		}
	};
	const deleteStatement = async (id) => {
		console.log('Initializing deleteStatement: ', id);
		try {
			const db = await initDB([storeName]);
			const transaction = db.transaction([storeName], "readwrite");
			const store = transaction.objectStore(storeName);
			const request = store.delete(id);
			return new Promise((resolve, reject) => {
				request.onsuccess = (event) => {
					console.log('Statement deleted successfully');
					resolve(event);
				};
				request.onerror = (event) => {
					console.log('Statement failed to delete');
					reject(event);
				};
			});
		} catch (error) {
			console.error('Error in deleteStatement:', error);
			throw error;
		}
	};

	const updateAuthFields = async (id, { token, jwt, sid }) => {
		try {
			const db = await initDB([storeName]);
			const transaction = db.transaction([storeName], "readwrite");
			const store = transaction.objectStore(storeName);

			// Primero, obtenemos el estado actual
			const currentState = await new Promise((resolve, reject) => {
				const getRequest = store.get(id);
				getRequest.onsuccess = () => resolve(getRequest.result);
				getRequest.onerror = (event) => reject(event);
			});

			if (!currentState) {
				throw new Error('No se encontró el estado para actualizar');
			}

			// Actualizamos solo los campos necesarios
			const updatedState = {
				...currentState,
				statement: {
					...currentState.statement,
					token,
					jwt,
					sid
				},
				date: moment().format('YYYY-MM-DD HH:mm:ss')
			};

			// Guardamos el estado actualizado
			const putRequest = store.put(updatedState);

			return new Promise((resolve, reject) => {
				putRequest.onsuccess = (event) => {
					resolve(event);
				};
				putRequest.onerror = (event) => {
					reject(event);
				};
			});
		} catch (error) {
			console.error('Error en updateAuthFields:', error);
			throw error;
		}
	};

	const updateValidateLoadState = async (id, newValidateValue) => {
		console.log('Initializing updateValidateLoadState: ', id, newValidateValue);
		try {
			const db = await initDB([storeName]);
			const transaction = db.transaction([storeName], "readwrite");
			const store = transaction.objectStore(storeName);

			// Primero, obtenemos el estado actual
			const currentState = await new Promise((resolve, reject) => {
				const getRequest = store.get(id);
				getRequest.onsuccess = () => resolve(getRequest.result);
				getRequest.onerror = (event) => reject(event);
			});

			if (!currentState) {
				throw new Error('No se encontró el estado para actualizar');
			}

			// Actualizamos solo el campo validate en loadState
			const updatedState = {
				...currentState,
				loadState: {
					...currentState.loadState,
					validate: newValidateValue // Actualizamos solo validate
				},
				date: moment().format('YYYY-MM-DD HH:mm:ss') // Actualizamos la fecha
			};

			// Guardamos el estado actualizado
			const putRequest = store.put(updatedState);

			return new Promise((resolve, reject) => {
				putRequest.onsuccess = (event) => {
					console.log('Estado actualizado con éxito');
					resolve(event);
				};
				putRequest.onerror = (event) => {
					console.log('Error al actualizar el estado');
					reject(event);
				};
			});
		} catch (error) {
			console.error('Error en updateValidateLoadState:', error);
			throw error;
		}
	};

	const clearAllStatements = async () => {
		try {
			const db = await initDB(['statement']);
			const transaction = db.transaction(['statement'], "readwrite");
			const store = transaction.objectStore('statement');
			const request = store.clear(); // Este método borra todos los registros

			return new Promise((resolve, reject) => {
				request.onsuccess = (event) => {
					console.log('All statements cleared successfully');
					resolve(event);
				};
				request.onerror = (event) => {
					console.log('Failed to clear statements');
					reject(event);
				};
			});
		} catch (error) {
			console.error('Error in clearAllStatements:', error);
			throw error;
		}
	};
	const getSerializableState = (state) => {
		return {
			token: state.token,
			jwt: state.jwt,
			sid: state.sid,
			infoHash: state.infoHash,
			infoAccount: state.infoAccount,
			info: state.info,
			accounts: state.accounts,
			account: state.account,
			jobs: state.jobs,
			terms: state.terms,
			destinations: state.destinations,
			loans: state.loans,
			loanDetail: state.loanDetail,
			loanRequest: state.loanRequest,
			quoteLoan: state.quoteLoan,
			interestRate: state.interestRate,
			preApprovedLoans: state.preApprovedLoans,
			formLink: state.formLink,
			amountMaxLoan: state.amountMaxLoan,
			// loadState: state.loadState,
			// fatalErrorUser: state.fatalErrorUser,
			// statementData: {
			// 	data: state.statementData.data,
			// 	summary: state.statementData.summary,
			// 	sumSummary: state.statementData.sumSummary
			// },
			statementData: state.statementData,
			userDataLoan: state.userDataLoan,
			loansPageStorage: state.loansPageStorage,
			etiquetasFondosSaldos: state.etiquetasFondosSaldos,
			saldosFondos: state.saldosFondos,
			saldosTypoSaldo: state.saldosTypoSaldo,
			ecDataPersonal: state.ecDataPersonal,
			ecFundsResumed: state.ecFundsResumed,
			ecCommissionFunds: state.ecCommissionFunds,
			ecFundsResumedGeneralTypeFunds: state.ecFundsResumedGeneralTypeFunds,
			cotVPercentRCI: state.cotVPercentRCI
		};
	}



	return {
		initDB,
		createStatement,
		getStatement,
		updateStatement,
		deleteStatement,
		getSerializableState,
		updateAuthFields,
		updateValidateLoadState,
		clearAllStatements
	}
}
