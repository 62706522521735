import axios from 'axios';

export const useLog = () => {
	const getDeviceInfo = () => {
		const userAgent = navigator.userAgent;

		const getBrowser = () => {
			const ua = userAgent.toLowerCase();
			if (ua.includes('edg/')) return 'Edge';
			if (ua.includes('chrome')) return 'Chrome';
			if (ua.includes('firefox')) return 'Firefox';
			if (ua.includes('mozilla')) return 'Firefox';
			if (ua.includes('safari') && !ua.includes('chrome')) return 'Safari';
			if (ua.includes('opera') || ua.includes('opr/')) return 'Opera';
			return 'Unknown Browser';
		};

		const getOS = () => {
			const platform = navigator.platform || userAgent;
			if (/Win/.test(platform)) return 'Windows';
			if (/Mac/.test(platform)) return 'MacOS';
			if (/Linux/.test(platform)) return 'Linux';
			if (/Android/.test(userAgent)) return 'Android';
			if (/iPhone|iPad|iPod/.test(platform)) return 'iOS';
			return 'Unknown OS';
		};

		const getDevice = () => {
			if (navigator.userAgentData) {
				const mobile = navigator.userAgentData.mobile;
				if (mobile) return 'Mobile Device';
				return 'Desktop';
			}

			const ua = userAgent.toLowerCase();
			if (/ipad/.test(ua)) return 'iPad';
			if (/iphone/.test(ua)) return 'iPhone';
			if (/android/.test(ua) && /mobile/.test(ua)) return 'Android Phone';
			if (/android/.test(ua)) return 'Android Tablet';

			if (window.matchMedia('(max-width: 768px)').matches) return 'Mobile Device';
			return 'Desktop';
		};

		return {
			timestamp: new Date().toISOString(),
			device: getDevice(),
			browser: getBrowser(),
			operatingSystem: getOS(),
			userAgent
		};
	};

	const registerAuthActivity = async (username, success = true, action = 'login') => {
		try {
			if (!username) {
				throw new Error('Username is required');
			}

			const deviceInfo = getDeviceInfo();

			const response = await axios.post(
				`${process.env.VUE_APP_URL_API}/httpAdminServices`,
				{
					data: {
						method: 'registerUserAuthActivity',
						activityData: {
							username,
							loginHistory: {
								...deviceInfo,
								status: success ? 'success' : 'failed',
								action
							}
						}
					}
				}
			);

			return response.data;
		} catch (error) {
			console.error('Error registering auth activity:', error);
			throw error;
		}
	};

	const registerUserLogHistory = async (
		// username,
		status,
		action,
		module,
		// details,
	) => {
		try {
			// if (!username) {
			// 	throw new Error('Username is required');
			// }
			if (!action) {
				throw new Error('Action is required');
			}
			if (!module) {
				throw new Error('Module is required');
			}
			if (!status || !['success', 'failed'].includes(status)) {
				throw new Error('Status must be either "success" or "failed"');
			}

			const details = {
				before: "Consultando Servicio",
				after: "Obteniendo Respuesta",
			};

			const username = localStorage.getItem("saveusername") || "";

			const deviceInfo = getDeviceInfo();

			const response = await axios.post(
				`${process.env.VUE_APP_URL_API}/httpAdminServices`,

				{
					data: {
						method: 'registerUserLogHistory',
						logData: {
							username,
							status,
							action,
							module,
							details: details || null,
							timestamp: deviceInfo.timestamp,
							device: deviceInfo.device,
							browser: deviceInfo.browser,
							operatingSystem: deviceInfo.operatingSystem
						}
					}
				}
			);

			return response.data;
		} catch (error) {
			console.error('Error registering user log:', error);
			throw error;
		}
	};

	return { registerAuthActivity, registerUserLogHistory };
};
