const dbName = "rap-local";
const dbVersion = 1;

export const initDB = (storeNames) => {
	return new Promise((resolve, reject) => {
		const request = indexedDB.open(dbName, dbVersion);

		request.onsuccess = (event) => {
			const db = event.target.result;
			resolve(db);
		};

		request.onerror = (event) => {
			console.log('Database failed to open');
			reject(event);
		};

		request.onupgradeneeded = (event) => {
			const db = event.target.result;
			storeNames.forEach(storeName => {
				if (!db.objectStoreNames.contains(storeName)) {
					db.createObjectStore(storeName, { keyPath: 'id' });
				}
			});
		};
	});
};

export const validateDB = (storeName, key) => {
	return new Promise((resolve, reject) => {
		initDB([storeName])
			.then(db => {
				const transaction = db.transaction([storeName], 'readonly');
				const store = transaction.objectStore(storeName);
				const request = store.get(key);

				request.onsuccess = (event) => {
					const result = event.target.result;
					resolve(!!result); // Devuelve true si el registro existe, false si no
				};

				request.onerror = (event) => {
					console.error('Error validating database:', event.target.error);
					reject(event.target.error);
				};
			})
			.catch(error => {
				console.error('Error opening database:', error);
				reject(error);
				return false;
			});
	});
};
