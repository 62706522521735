<template>
  <DialogRap
    v-model:visible="user.visibleUserBiometric"
    class="p-dialog-maximized"
  >
    <template #container="{ closeCallback }">
      <img
        src="../assets/png/brand.png"
        style="width: 80px; margin: 30px; position: fixed"
        class="brand__terms"
      />
      <div
        class="container__terms"
        style="overflow-y: scroll; margin-top: 30px"
      >
        <h1>TÉRMINOS Y CONDICIONES PARA EL USO DE ACCESO BIOMÉTRICO</h1>

        <p>
          Los presentes términos y condiciones para el uso de acceso Biométrico
          /Web, regulan los términos y condiciones para la activación de la
          funcionalidad del uso de datos biométricos en el sitio web del Régimen
          de Aportaciones Privadas en adelante denominado (RAP) por el usuario
          en su dispositivo Smartphone o tableta (en delante denominado,
          Dispositivo o Dispositivos).
        </p>

        <h2>RECOMENDACIONES DE SEGURIDAD</h2>
        <p>
          Al activar el acceso mediante datos biométricos para iniciar sesión,
          usted esta permitiendo que cualquier persona que tenga una huella
          digital almacenada en su dispositivo acceda a su cuenta. Por lo que el
          RAP recomienda al usuario, revisar las huellas digitales almacenadas
          en sus dispositivos y así asegurarse de que todas deben ser
          autorizadas al acceder a la información de la cuenta disponible a
          través del sitio web.
        </p>

        <h2>PRIVACIDAD</h2>

        <p>
          El sitio web del RAP utilizara datos biométricos, como huellas
          dactilares o reconocimiento facial, proporcionados por el dispositivo
          del usuario para autenticar su identidad. Los datos biométricos, junto
          con el usuario y la contraseña encriptados, se utilizarán para
          autenticar la identidad del usuario en la página web del RAP y en
          otros casos donde se necesite autenticar al usuario. su nombre de
          usuario y contraseña serán encriptados y almacenados por el RAP en su
          dispositivo. El RAP no tiene acceso a la información de sus datos
          Biométricos.
        </p>

        <p>
          El usuario es el único responsable sobre la seguridad de los
          dispositivos que utilizara para acceder a su perfil en el sitio web
          del RAP. Dicha responsabilidad incluye la de mantener sus dispositivos
          electrónicos con un programa de antivirus actualizado y funcional,
          esto para proteger sus usuarios y contraseñas seguros de cualquier
          vulnerabilidad causada por virus informáticos, mantener sus
          contraseñas bajo custodia, y todo lo que conlleva la seguridad de la
          información propia. El RAP no es responsable por quebrantamientos de
          seguridad en los dispositivos del usuario causados por terceros, ni
          por los daños y/o perjuicios que puedan provocarse al usuario que
          incumpla esta obligación.
        </p>

        <p>
          Declaro que he leído y entiendo los términos y condiciones anteriores,
          y que al aceptar los mismos reconozco y acepto bajo mi propia
          responsabilidad que toda persona con huella digital registrada en
          el/los dispositivos podrían llegar a tener acceso a la cuenta. Por lo
          tanto, libero a el RAP de todo tipo de responsabilidad pasada,
          presente o futura en caso de que dicho(s) dispositivo(s) o su sistema
          operativo (incluyendo las configuraciones de seguridad del sistema
          operativo) llegue(n) a estar comprometido(s).
        </p>
      </div>

      <div class="flex items-center justify-center w-full mt-2">
        <!-- <ToggleButtonRap
          v-model="checked"
          onIcon="pi pi-check"
          offIcon="pi pi-times"
          onLabel="Si"
          offLabel="No"
          style="background-color: #fff; height: 30px"
        />
        <p class="ml-2">he leído los términos</p> -->

        <div
          style="display: flex; justify-content: center; align-items: center"
        >
          <CheckboxRap
            v-model="checked"
            inputId="acceptterms"
            name="accept"
            :binary="true"
          />
          <label for="acceptterms" class="ml-2">
            He leído y acepto los términos y condiciones
          </label>
        </div>
      </div>
      <div class="flex items-center gap-4 w-full justify-around mt-5 mb-5">
        <ButtonRap
          label="Cancelar"
          @click="handleCancel(closeCallback)"
          text
          severity="secondary"
          outlined
          class="hover:!bg-white/10"
        ></ButtonRap>
        <ButtonRap
          label="Aceptar"
          @click="handleAccept(closeCallback)"
          :disabled="!checked"
        ></ButtonRap>
      </div>
    </template>
  </DialogRap>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { Capacitor } from "@capacitor/core";
import { useGlobalStore } from "@/store/GlobalStore";
import { useUserStore } from "@/store/UserStore";

const user = useUserStore();
const global = useGlobalStore();
// const visible = ref(false);
const checked = ref(false);

onMounted(() => {
  // const acceptedTerms = localStorage.getItem("acceptedBiometric");
  // if (!acceptedTerms) {
  //   visible.value = true;
  // } else {
  //   visible.value = false;
  // }
  // user.visibleUserData = false;
  if (Capacitor.isNativePlatform()) {
    const credentialsSet = localStorage.getItem("credentialsSet");

    global.visibleBiometric =
      localStorage.getItem("uid") === global.firebaseToken.uid ||
      localStorage.getItem("uid") === null;

    if (!credentialsSet) {
      user.visibleUserBiometric = true;
      // user.visibleUserData = false;
    } else {
      user.visibleUserBiometric = false;
    }
  }
});

function handleCancel(closeCallback) {
  localStorage.setItem("credentialsSet", "false");
  global.activeBiometric = false;
  closeCallback();
}

function handleAccept(closeCallback) {
  // localStorage.setItem("acceptedBiometric", "true");
  localStorage.setItem("credentialsSet", "true");
  localStorage.setItem("uid", global.firebaseToken.uid);
  global.activeBiometric = true;
  closeCallback();
}
</script>

<style scoped>
.container__terms > h1,
.container__terms > h2 {
  color: #333;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
  font-weight: 900;
}

.container__terms > p,
.container__terms > ul {
  margin-bottom: 15px;
}

.container__terms > ul {
  margin-left: 20px;
}

.container__terms {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  background-color: #fff;
  /* border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.terms {
  margin-top: 20px;
  font-size: 14px;
}

.contract-section {
  margin-bottom: 20px;
}

/* Teléfonos pequeños (por ejemplo, iPhone SE) */
@media (max-width: 320px) {
  .brand__terms {
    display: none;
  }
  .container__terms {
    border: 1px solid #fff;
    border-radius: 0px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0);
  }
}

/* Teléfonos medianos (por ejemplo, iPhone X, Galaxy S10) */
@media (min-width: 321px) and (max-width: 480px) {
  .brand__terms {
    display: none;
  }
  .container__terms {
    border: 1px solid #fff;
    border-radius: 0px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0);
  }
}

/* Teléfonos grandes (por ejemplo, iPhone 12 Pro Max, Galaxy Note) */
@media (min-width: 481px) and (max-width: 767px) {
  .brand__terms {
    display: none;
  }
  .container__terms {
    border: 1px solid #fff;
    border-radius: 0px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0);
  }
}
</style>
