<script>
    export default {
      props: {
        values: Array,
      },
      data() {
        return {
          sum: 0,
          palette: ['#65D8F1', '#AFD60F', '#FF9700', '#FED000'],
          cone: '',
        };
      },
      methods: {
        calculateSegments() {
          this.sum = this.values.reduce((a, b) => a + b, 0);
          this.cone = 'conic-gradient(';
          let deg = 0;
    
          for (let i = 0; i < this.values.length; i += 1) {
            const frac = this.values[i] / this.sum;
            const deg1 = deg;
            const deg2 = deg + frac * 360;
            this.cone += `${this.palette[i]} ${deg1}deg, ${this.palette[i]} ${deg2}deg, `;
            deg = deg2;
          }
    
          this.cone = this.cone.slice(0, -2);
          this.cone += ')';
        },
      },
      watch: {
        values() {
          this.calculateSegments();
        },
      },
      created() {
        this.calculateSegments();
      },
    };
    </script>
    
<template>
    <div class = "chart">
        <div class = "col" :style = "'background: '+cone"></div>
    </div>
</template>

<style lang = "scss" scoped>
    .chart {
        display: inline-block;
        min-width:  48px;
        aspect-ratio: 1;
        position: relative;

        div {
            position: absolute;
            transform: translate(-50%,-50%);
            left: 50%;
            top:  50%;
        }

        .col {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            }

        .sum {
            font-family: "Neo Sans";
            text-align: center;
        }
        }
    </style>
    