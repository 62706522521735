import { createApp } from 'vue'
import { createPinia } from 'pinia';
import App from './App.vue'
// import {  URLOpenListenerEvent } from '@capacitor/app';
import './index.css'
import router from './router';
import moment from 'moment'
import PrimeVue from 'primevue/config';
import { definePreset } from '@primevue/themes';
import Aura from '@primevue/themes/aura';
import Button from "primevue/button"
import Dialog from 'primevue/dialog';
import Fieldset from 'primevue/fieldset';
import ToggleButton from 'primevue/togglebutton';
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import Calendar from 'primevue/calendar';
import Checkbox from 'primevue/checkbox';
import FloatLabel from 'primevue/floatlabel';
import Carousel from 'primevue/carousel';


import Card from 'primevue/card'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'






import 'primeicons/primeicons.css'

moment.locale('es-mx');


const app = createApp(App)

const formatter = new Intl.NumberFormat('en-US', {
  // style: 'currency',
  // currency: 'HNL',
  // maximumFractionDigits: 2,
  currency: 'USD',
});

app.config.globalProperties.$filters = {
  currency(value, coin) {
    coin = coin ? coin : '';
    value = value ? value : 0;
    if (value >= 0)
      return coin + " " + formatter.format(value.toFixed(2)).replace(/HNL/g, "")
    else
      return '-' + coin + formatter.format(Math.abs(value.toFixed(2))).replace(/HNL/g, "")
  },
  capitalize(miString) {
    miString = miString ? miString : '';
    return miString.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
  },
  dniHND(p) {
    // var dniregex = /^\d{2}-\d{3}-\d{4}-\d{3}$/
    return p = p.substr(0, 4) + '-' + p.substr(4, 4) + '-' + p.substr(8, 13);
  },
  formatDateEspLat(date) {
    date = date ? date : new Date('YYYYMMDD');
    return date = moment(date, 'YYYYMMDD').format('L') || "Fecha no valida";
  },
  formatDateRAP(date) {
    return date = moment(date).format('L') || "Fecha no valida";
  },
  formatYearRAP(date) {
    return date = (moment(date, "YYYYMMDD").format('YYYY') || "Fecha no valida").toString().toUpperCase();
  },
  formatMonthRAP(date) {
    date = (moment(date, "YYYYMMDD").format('MMMM') || "Fecha no valida").toString();
    return date = date[0].toUpperCase() + date.slice(1);
  },
  formatDayRAP(date) {
    return date = (moment(date, "YYYYMMDD").format('DD') || "Dia no valido").toString().toUpperCase();
  },
}
// app.config.globalProperties.$filters = {
//   capitalize(miString) {
//     miString = miString ? miString : '';
//     return miString.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
//   }
// }




// app.use(createPinia()).use(router).mount('#app')


const MyPreset = definePreset(Aura, {
  semantic: {
    primary: {
      50: '#F9FBE6',   // Muy claro
      100: '#F0F8C2',  // Claro
      200: '#E2F296',  // Claro
      300: '#D3EB70',  // Medio
      400: '#C5E64D',  // Medio
      500: '#AFD60F',  // Base
      600: '#9CBE0C',  // Oscuro
      700: '#86A70A',  // Más oscuro
      800: '#728F08',  // Muy oscuro
      900: '#566904',  // Muy oscuro
      950: '#3D4D03'   // Casi negro
    },

  },


});



app.use(createPinia());
app.use(router);

app.use(PrimeVue, {
  theme: {
    preset: MyPreset
  }
});

app.use(ToastService);
app.use(ConfirmationService);

app.component('ButtonRap', Button);
app.component('DialogRap', Dialog);
app.component('FieldsetRap', Fieldset);
app.component('ToggleButtonRap', ToggleButton);
app.component('ConfirmDialogRap', ConfirmDialog);
app.component('ToastRap', Toast);
app.component('CalendarRap', Calendar);
app.component('CheckboxRap', Checkbox);
app.component('FloatLabelRap', FloatLabel);
app.component('CarouselRap', Carousel);

app.component('CardRap', Card);
app.component('TabViewRap', TabView);
app.component('TabPanelRap', TabPanel);
app.component('AccordionRap', Accordion);
app.component('AccordionTabRap', AccordionTab);




app.mount('#app')