<template>
  <DialogRap v-model:visible="user.visibleUserTerms" class="p-dialog-maximized">
    <template #container="{ closeCallback }">
      <img
        src="../assets/png/brand.png"
        style="width: 80px; margin: 30px; position: fixed"
        class="brand__terms"
      />
      <div
        class="container__terms"
        style="overflow-y: scroll; margin-top: 30px"
      >
        <h1>TÉRMINOS Y CONDICIONES PARA EL USO DEL APP RAP</h1>
        <p>
          Las presentes condiciones de uso regulan los términos y condiciones
          para la adquisición/descarga de la aplicación informática APP RAP en
          adelante denominado, &quot;Aplicación&quot; por el usuario del RAP en
          adelante denominado “usuario” a su dispositivo Smartphone o tableta en
          adelante denominado &quot;Dispositivo&quot; o
          &quot;Dispositivos&quot;.
        </p>

        <p>
          La Aplicación, una vez descargada por el usuario, permitirá el acceso
          y uso por el usuario o afiliado del RAP de algunas funcionalidades de
          la aplicación únicamente si el usuario se encuentra afiliado al RAP y
          haya creado su usuario y credencial de acceso. Las condiciones de uso
          de la aplicación y las Funcionalidades de la aplicación y el acceso a
          las mismas a través de la Aplicación son las que se recogen en el
          contrato de términos y condiciones para el uso del APP RAP.
        </p>

        <p>
          El acceso y uso del servicio a través de la Aplicación queda
          condicionado a que los servicios del RAP estén disponibles para el
          usuario, y a que la identificación del usuario se realice de acuerdo
          con lo establecido en las condiciones del correspondiente a los
          Términos y Condiciones para el Uso del App.
        </p>

        <!-- <h2>A. Términos y Condiciones de Uso RAP en Línea:</h2> -->
        <p>
          Algunas de las Funcionalidades del servicio de la aplicación, pueden
          no ser totalmente operativas por razón de las características de los
          Dispositivos y/o por la imposibilidad física o práctica de poder
          cumplir a través de los mismos determinados trámites y/o requisitos
          establecidos con carácter previo por la normativa aplicable;
          circunstancias que, en la medida que la tecnología lo permita, serán
          puestas en conocimiento al usuario en el momento del acceso.
        </p>

        <p>
          La descarga de la Aplicación se podrá realizar a través de la tienda
          de aplicaciones del sistema operativo del Smartphone o Tableta del
          usuario. Una vez descargada la aplicación, seleccionando el icono
          &quot;APP RAP&quot; en el Dispositivo, el usuario accederá a la misma
          y se le mostrarán las presentes Condiciones.
        </p>
        <p>
          1. La descarga de la Aplicación y/o las funcionalidades previstas o
          que en el futuro se puedan permitir a través de la aplicación, tendrán
          carácter gratuito, no obstante, el RAP se reserva el derecho de
          introducir en el futuro alguna contraprestación, lo cual será
          comunicado al usuario por los medios que el RAP estime conveniente.
        </p>

        <p>
          2. En virtud del presente documento el RAP otorga, y el usuario
          acepta, una licencia de uso del Software, propiedad del RAP, personal
          e intransferible, limitada, no exclusiva y disponible, tras la
          aceptación de las presentes condiciones, a través de un proceso de
          descarga. Dicho uso incluye la reproducción total o parcial del
          Software exclusivamente mediante su utilización, descarga, ejecución y
          exhibición en los Dispositivos que el usuario elija, bajo el entendido
          que sólo se permitirá una sesión activa a la vez.
        </p>
        <p>
          3. La utilización del Software queda restringida al uso anteriormente
          expuesto y su empleo para cualquier otro fin queda expresamente
          prohibido y será considerado como acto de piratería de software que
          infringe las leyes de propiedad intelectual e industrial, de tal forma
          que el RAP podrá exigir responsabilidad por el incumplimiento de esta
          condición, según la normativa vigente. Asimismo, queda expresamente
          prohibida la manipulación, total o parcial, por parte del usuario o de
          terceros, del Software.
        </p>
        <p>
          4. Asimismo, la totalidad de la Aplicación, es decir, los elementos
          que la componen (textos, imágenes, marcas, logotipos, combinaciones de
          colores), así como la estructura, selección y orden de sus contenidos,
          se encuentran protegidos por la normativa de Propiedad Intelectual e
          Industrial, no pudiendo ser objeto de explotación, reproducción,
          distribución, modificación, comunicación pública, cesión o
          transformación o cualquier otra forma de difusión no autorizada
          expresamente. El acceso a la Aplicación no otorga al usuario derecho
          ni titularidad alguna sobre los derechos de propiedad intelectual y/o
          industrial de los contenidos que alberga la Aplicación.
        </p>
        <p>
          5. El RAP se reserva la posibilidad de ejercer las acciones legales
          que correspondan contra el usuario que viole o infrinja los derechos
          de propiedad intelectual y/o industrial.
        </p>
        <p>
          6. El usuario será responsable de comprobar que el Software es
          compatible con su Dispositivo, por lo que asume los daños que la
          utilización del Software, incluida su instalación, pudiera ocasionar
          en el Dispositivo.
        </p>
        <p>
          7. Corresponde al usuario la obtención de los dispositivos apropiados
          para los fines establecidos en estas Condiciones, y las líneas de
          comunicación, así como aceptar el consumo producido por la utilización
          de la red de datos, siendo de su cuenta su adquisición y los gastos
          que pudieran derivarse de los mismos. Es responsabilidad exclusiva del
          usuario comprobar, con carácter previo a realizar una descarga, las
          funcionalidades del Software, así como la idoneidad para atender sus
          necesidades y la compatibilidad con su sistema.
        </p>

        <p>
          8. El RAP no otorga ningún tipo de garantía sobre el Software
          proporcionado. Cada una de las partes será responsable frente a la
          otra de los daños y perjuicios derivados de incumplimientos de
          obligaciones asumidas en virtud de este contrato. Ninguna de las
          partes será responsable frente a la otra en el supuesto de
          incumplimiento de dichas obligaciones basado en fuerza mayor o caso
          fortuito.
        </p>

        <p>
          9. El RAP tampoco asumirá ninguna garantía y/o responsabilidad en el
          supuesto de que el usuario haya recibido el Software de un tercero
          distinto a El RAP, tratándose de una infracción grave de los derechos
          de propiedad intelectual e industrial del mismo.
        </p>

        <p>
          10. El RAP se reserva el derecho a realizar modificaciones y/o mejoras
          en el Software, en cualquier momento y sin previo aviso, que pasarán a
          ser propiedad exclusiva del RAP.
        </p>

        <p>
          11. El RAP se reserva el derecho a modificar, de forma parcial o
          total, las presentes Condiciones, así como a incluir otras nuevas. Las
          nuevas condiciones se mostrarán al usuario a través de la Aplicación.
        </p>

        <p>
          12. Asimismo, el RAP se reserva el derecho a actualizar, modificar o
          eliminar la información contenida en la Aplicación, pudiendo incluso
          limitar o no permitir el acceso a dicha información, sin previo aviso.
          Especialmente, el RAP se reserva el derecho a eliminar, limitar o
          impedir el acceso a su Aplicación cuando surjan dificultades técnicas
          por hechos o circunstancias ajenos a el RAP que, a su criterio,
          disminuyan o anulen los niveles de seguridad estándares adoptados para
          el adecuado funcionamiento de dicha Aplicación.
        </p>

        <p>
          13. Las presentes Condiciones serán vinculantes desde el momento de su
          aceptación por parte del usuario, de conformidad con lo establecido en
          el numeral 2.
        </p>

        <p>
          14. El RAP tendrá la facultad de rescindir el contrato en cualquier
          momento, quedando cancelada la licencia otorgada, mediante
          comunicación previa al usuario.
        </p>

        <p>
          15. El Usuario podrá rescindir el presente contrato en cualquier
          momento procediendo a desinstalar el Software del Dispositivo, sin
          necesidad de comunicar esta circunstancia a el RAP, cualquier servicio
          que haya quedado pendiente de confirmación la podrá visualizar por
          cualquier otro canal oficial del RAP.
        </p>

        <p>
          16. El presente contrato se regirá por la legislación hondureña; para
          la resolución de cualquier discrepancia que pudiera surgir en la
          interpretación y/o ejecución de este contrato, las partes se someten a
          la jurisdicción y competencia de los de lo Civil del Departamento de
          Francisco Morazán, República de Honduras.
        </p>

        <p>
          17. La Aplicación recoge información estadística del Servicio mediante
          el almacenamiento de información en el dispositivo del usuario;
          consistente en fecha, hora, tipo de Dispositivo, ubicación y registro
          de errores, en modo alguno identifica ni es susceptible de identificar
          personalmente al usuario, es decir, es totalmente anónima. La
          información recogida se utilizará por el RAP para adquirir mayor
          conocimiento sobre la actividad del usuario de la Aplicación, en aras
          de mejorar la misma y aumentar la efectividad de su presencia on-line.
        </p>

        <p>
          18. Para recabar dicha información y someterla a un tratamiento
          estadístico para el RAP, se utilizan servicios de Internet
          &quot;WebServices&quot;; ello implica que la Aplicación enviará al
          servidor del RAP dicha información.
        </p>

        <p>
          19. El usuario garantiza a el RAP que no forma parte de ninguna lista
          de excepciones.
        </p>

        <p>
          20. El Usuario es el único responsable sobre la seguridad de los
          equipos que utiliza, dicha responsabilidad incluye la de mantener sus
          dispositivos electrónicos con un programa de antivirus actualizado y
          funcional, esto para proteger sus usuarios y contraseñas seguros de
          cualquier vulnerabilidad causada por virus informáticos, mantener sus
          contraseñas bajo custodia, y todo lo que conlleva la seguridad de la
          información propia. El RAP no es responsable por quebrantamientos de
          seguridad en los dispositivos del usuario causados por terceros, ni
          por los daños y/o perjuicios que puedan provocarse al usuario que
          incumple esta obligación, incluyendo, pero no limitándose a daños o
          perjuicios de tipo Secundario.
        </p>

        <p>
          21. El hackeo de su dispositivo, o el quebrantamiento de la
          configuración de seguridad del fabricante del dispositivo pueden poner
          en riesgo la información o las credenciales de uso de esta aplicación.
          En caso de que el dispositivo del usuario haya sido objeto de hackeo o
          quebrantamiento no será responsabilidad del RAP el cual conserva el
          derecho de solicitar al usuario inspeccionar el dispositivo para
          determinar lo anterior, en cuyo caso y siempre y cuando no haya
          existido dolo de parte del RAP, se denegará cualquier reclamo y el
          usuario acepta desde ya a esta decisión de parte del RAP. El usuario
          Declara que ha leído y comprendido los presentes Términos y
          Condiciones y se obliga al cumplimiento en el uso de la aplicación.
        </p>
        <!-- <div class="terms">
          <h2>Terminación del Servicio:</h2>
          <p>
            Si alguno de los presentes Términos del Servicio fuese considerado
            nulo o inválido, ello no afectará la validez y exigibilidad de los
            demás.
          </p>

          <p>
            Aceptación electrónica de Términos y Condiciones: Acepto que he
            leído y comprendido los siguientes Términos y Condiciones de Uso de
            Plataforma electrónica “RAP en Línea”, así como utilizar el presente
            servicio, dejo constancia expresa que los acepto en forma
            electrónica.
          </p>

          <p>
            La cancelación o suspensión de este Servicio por parte del RAP, en
            cualquier momento, no dará derecho a ningún tipo de compensación u
            otras medidas a favor del Afiliado ni de ningún tercero.
          </p>
        </div> -->
      </div>
      <div class="flex items-center justify-center w-full mt-2">
        <!-- <ToggleButtonRap
          v-model="checked"
          onIcon="pi pi-check"
          offIcon="pi pi-times"
          onLabel="Si"
          offLabel="No"
          style="background-color: #fff; height: 30px"
        />
        <p class="ml-2">he leído los términos</p> -->

        <div
          style="display: flex; justify-content: center; align-items: center"
        >
          <CheckboxRap
            v-model="checked"
            inputId="acceptterms"
            name="accept"
            :binary="true"
          />
          <label for="acceptterms" class="ml-2">
            He leído y acepto los términos y condiciones
          </label>
        </div>
      </div>
      <div class="flex items-center gap-4 w-full justify-around mt-5 mb-5">
        <ButtonRap
          label="Cancelar"
          @click="handleCancel(closeCallback)"
          text
          severity="secondary"
          outlined
          class="hover:!bg-white/10"
        ></ButtonRap>
        <ButtonRap
          label="Aceptar"
          @click="handleAccept(closeCallback)"
          :disabled="!checked"
        ></ButtonRap>
      </div>
    </template>
  </DialogRap>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useGlobalStore } from "@/store/GlobalStore";
import { useUserStore } from "@/store/UserStore";
import { httpsCallable } from "firebase/functions";

const global = useGlobalStore();
const user = useUserStore();
// const visible = ref(false);
const checked = ref(false);

onMounted(() => {
  // const acceptedTerms = localStorage.getItem("acceptedTerms");
  // if (!acceptedTerms || acceptedTerms === "false") {
  //   visible.value = true;
  // } else {
  //   visible.value = false;
  // }
  // user.visibleUserData = false;
  checkTermsAcceptance();
});

const setAcceptedTerms = async username => {
  try {
    const httpValidatedServices = httpsCallable(
      global.functions,
      "httpValidatedServices"
    );
    const response = await httpValidatedServices({
      method: "setUpdateAcceptTerms",
      username: username,
      acceptTerms: true,
    });

    return response.data;
  } catch (error) {
    console.error("Error calling function:", error);
  }
};

const getTermsUser = async username => {
  try {
    const httpValidatedServices = httpsCallable(
      global.functions,
      "httpValidatedServices"
    );
    const response = await httpValidatedServices({
      method: "getAcceptedTermsUser",
      username: username,
      acceptTerms: true,
    });

    return response.data;
  } catch (error) {
    console.error("Error calling function:", error);
  }
};
const checkTermsAcceptance = async () => {
  let username = localStorage.getItem("saveusername");
  const isAccepted = await getTermsUser(username);
  // const acceptedTermsUsers = localStorage.getItem("acceptedTermsUsers") || "";
  // const userList = acceptedTermsUsers.split(",");
  // Asumimos que user.username es el nombre de usuario actual
  if (!isAccepted.data) {
    user.visibleUserTerms = true;
    // user.visibleUserData = false;
  } else {
    user.visibleUserTerms = false;
  }
};

function handleCancel(closeCallback) {
  user.signOut();
  closeCallback();
}

const handleAccept = async closeCallback => {
  let username = localStorage.getItem("saveusername");
  let acceptedTermsUsers = localStorage.getItem("acceptedTermsUsers") || "";
  const userList = acceptedTermsUsers.split(",");
  if (!userList.includes(username)) {
    if (acceptedTermsUsers) {
      acceptedTermsUsers += `,${username}`;
    } else {
      acceptedTermsUsers = username;
    }
    localStorage.setItem("acceptedTermsUsers", acceptedTermsUsers);
  }
  await setAcceptedTerms(username, true);

  closeCallback();
};

// function handleAccept(closeCallback) {
//   console.log("Aceptar");
//   localStorage.setItem("acceptedTerms", "true");
//   closeCallback();
// }
</script>

<style scoped>
.container__terms > h1,
.container__terms > h2 {
  color: #333;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
  font-weight: 900;
}

.container__terms > p,
.container__terms > ul {
  margin-bottom: 15px;
}

.container__terms > ul {
  margin-left: 20px;
}

.container__terms {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.terms {
  margin-top: 20px;
  font-size: 14px;
}

/* Teléfonos pequeños (por ejemplo, iPhone SE) */
@media (max-width: 320px) {
  .brand__terms {
    display: none;
  }
  .container__terms {
    border: 1px solid #fff;
    border-radius: 0px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0);
  }
}

/* Teléfonos medianos (por ejemplo, iPhone X, Galaxy S10) */
@media (min-width: 321px) and (max-width: 480px) {
  .brand__terms {
    display: none;
  }
  .container__terms {
    border: 1px solid #fff;
    border-radius: 0px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0);
  }
}

/* Teléfonos grandes (por ejemplo, iPhone 12 Pro Max, Galaxy Note) */
@media (min-width: 481px) and (max-width: 767px) {
  .brand__terms {
    display: none;
  }
  .container__terms {
    border: 1px solid #fff;
    border-radius: 0px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0);
  }
}
</style>
