<template>
  <div>
    <TopBar title="INICIO" :date="`${user.infoHash.fecha_saldo_des}`" brand>
    </TopBar>
    <DialogRap
      v-model:visible="user.visibleUserData"
      header="Actualización de datos"
      :style="{
        width: '30rem',
        borderStyle: 'none',
        boxShadow: '0 8px 8px rgba(0, 0, 0, 0.1)',
      }"
    >
      <template #header>
        <div
          class="inline-flex align-items-center justify-content-center gap-2"
        >
          <img
            src="../../assets/png/brand.png"
            style="width: 50px; margin-bottom: 10px"
          />
          <span class="font-bold white-space-nowrap">ACTUALIZACIÓN</span>
        </div>
      </template>
      <p class="mb-3 text-cyan-600 font-extrabold">
        Por favor actualiza tus datos
      </p>
      <p class="mb-3 text-gray-500 w-full text-sm mt-5">
        Con el afán de brindarte un mejor servicio y cumplir con la normativa
        019/2016 de la Comisión Nacional de Bancos y Seguros (CNBS) solicitamos
        de tu apoyo con la actualización de tus datos.
      </p>
      <!-- <p class="mb-3 mt-5 text-gray-800 w-full text-sm">
        {{ messageUserData }}
      </p> -->
      <ButtonRap
        icon="pi pi-user-edit"
        class="mt-6"
        @click="() => router.push({ name: 'user-data' })"
        label="Actualizar"
      ></ButtonRap>
    </DialogRap>
    <div
      class="p-6 w-full text-center md:bg-lit-blue md:m-6 md:w-auto rounded-xl"
      v-if="
        user.token == null || user.saldosFondos.total.mon_saldo_actual == ''
      "
    >
      <div class="md:w-1/3 m-auto">
        <img
          class="w-2/5 rounded-full inline mb-4 md:hidden"
          src="@/assets/jpg/profile.jpg"
        />
        <div style="width: 100%; display: flex; justify-content: center">
          <div
            class="mb-2 skeleton flex"
            style="width: 50%; height: 25px; border-radius: 10px"
          ></div>
        </div>

        <div style="width: 100%; display: flex; justify-content: center">
          <div
            class="mb-2 skeleton flex"
            style="width: 20%; height: 25px; border-radius: 10px"
          ></div>
        </div>
      </div>

      <div class="md:flex md:space-x-4">
        <div
          v-for="fund in 3"
          :key="fund"
          class="flex bg-white drop-shadow-sm py-2 border-b first:rounded-t-xl last:rounded-b-xl w-full md:rounded-xl md:flex-col"
        >
          <div class="p-4 my-auto md:mx-auto">
            <div class="w-3 h-3 rounded-full bg-low-blue"></div>
          </div>

          <div
            class="text-left leading-4 my-auto w-full md:width-auto md:text-center md:mx-auto md:mb-4"
          >
            <div
              class="font-bold md:hidden skeleton"
              style="width: 80%; height: 12px; border-radius: 10px"
            ></div>
            <div
              class="text-sm md:font-bold md:text-2xl skeleton skeleton__movil"
              style="
                width: 40%;
                height: 12px;
                border-radius: 10px;
                margin-top: 5px;
              "
            ></div>
            <div
              class="text-sm hidden md:block skeleton"
              style="
                width: 30%;
                height: 12px;
                border-radius: 10px;
                margin-top: 5px;
                margin-left: 30px;
              "
            ></div>
          </div>

          <i
            class="fa-solid fa-chevron-right md:hidden p-4"
            style="color: #cecece"
          ></i>
        </div>
      </div>

      <div class="md:w-1/3 m-auto">
        <div
          to="/account/history"
          class="flex bg-white drop-shadow-xl rounded-xl py-2 mt-6 md:py-0"
        >
          <div class="text-center my-auto w-full">
            <div class="font-bold p-4 text-black">Aportes por empresa</div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="p-6 w-full text-center md:bg-lit-blue md:m-6 md:w-auto rounded-xl"
      v-else
    >
      <div class="md:w-1/3 m-auto">
        <img
          class="w-2/5 rounded-full inline mb-4 md:hidden"
          src="@/assets/jpg/profile.jpg"
        />
        <div class="name font-bold mb-2 text-xl">
          {{ user.info.firstName }} {{ user.info.firstLastName }}
        </div>

        <div class="text-center font-bold text-4xl mb-2 md:text-5xl">
          {{ formatCurrency(user.saldosFondos.total.mon_saldo_actual, "L") }}
        </div>

        <div class="flex overflow-hidden rounded-xl mb-6 h-3 md:mb-6">
          <div
            v-for="fund in user?.saldosFondos.funds"
            :key="fund"
            class="w-full"
            :style="'width: ' + fund.porcentaje + '%'"
            :class="'bg-pal-' + fund.palette"
          ></div>
        </div>
      </div>

      <div class="md:flex md:space-x-4">
        <router-link
          v-for="fund in user?.saldosFondos.funds"
          :key="fund"
          class="flex bg-white drop-shadow-sm py-2 border-b first:rounded-t-xl last:rounded-b-xl w-full md:rounded-xl md:flex-col"
          :to="'/account/fund/' + fund.cod_inversion"
        >
          <div class="p-4 my-auto md:mx-auto">
            <div
              class="w-3 h-3 rounded-full"
              :class="'bg-pal-' + fund.palette"
            ></div>
          </div>

          <div
            class="text-left leading-4 my-auto w-full md:width-auto md:text-center md:mx-auto md:mb-4"
          >
            <div class="font-bold md:hidden">
              {{ fund.descripcion }}
            </div>
            <div class="text-sm md:font-bold md:text-2xl">
              {{ formatCurrency(fund.mon_saldo_actual, "L") }} ({{
                fund.porcentaje
              }}%)
            </div>
            <div class="text-sm hidden md:block">
              {{ fund.descripcion }}
            </div>
          </div>

          <i class="fa-solid fa-chevron-right md:hidden p-4"></i>
        </router-link>
      </div>

      <div class="flex mb-6 grayscale opacity-75 hidden">
        <div class="w-2/6 relative mr-2 my-auto flex">
          <PieChart class="w-full my-auto" :values="[66, 0, 34]"></PieChart>
        </div>
        <div class="w-4/6 my-auto">
          <div class="bg-white rounded-xl p-2 mb-2 w-full flex">
            <div class="p-2 my-auto">
              <div class="bg-pal-1 w-3 h-3 rounded-full"></div>
            </div>
            <div class="text-left leading-5 my-auto w-full">
              <div class="font-bold">
                {{ page?.labels?.contributions }} (66%)
              </div>
              <div class="">L 6,600.00</div>
            </div>
          </div>
          <div class="bg-white rounded-xl p-2 w-full flex">
            <div class="p-2 my-auto">
              <div class="bg-pal-3 w-3 h-3 rounded-full"></div>
            </div>
            <div class="text-left leading-5 my-auto w-full">
              <div class="font-bold">{{ page?.labels?.yield }} (34%)</div>
              <div class="">L 3,400.00</div>
            </div>
          </div>
        </div>
      </div>

      <div class="md:w-1/3 m-auto">
        <router-link
          to="/account/history"
          class="flex bg-white drop-shadow-xl rounded-xl py-2 mt-6 md:py-0"
        >
          <div class="text-center my-auto w-full">
            <div class="font-bold p-4">
              {{ page?.buttons?.contributions_by_company }}
            </div>
          </div>
        </router-link>
      </div>
    </div>

    <StatementGeneral />

    <TermsUser />
    <TermsBiometric />
    <WelcomeApp />
  </div>
</template>

<script>
import { onUpdated, ref, onMounted, watch } from "vue";
import TopBar from "@/components/TopBar.vue";
import PieChart from "@/components/PieChart.vue";
import TermsUser from "@/components/TermsUser.vue";
import TermsBiometric from "@/components/TermsBiometric.vue";
import StatementGeneral from "@/components/StatementGeneral.vue";
import { useGlobalStore } from "@/store/GlobalStore";
import { useUserStore } from "@/store/UserStore";
import { httpsCallable } from "firebase/functions";
import { FirebaseMessaging } from "@capacitor-firebase/messaging";
import { Capacitor } from "@capacitor/core";
import axios from "axios";
import { dbStatement } from "../../db/statement";
import { validateDB } from "../../db/db";
import { useRouter } from "vue-router";
import WelcomeApp from "@/components/WelcomeApp.vue";
import { useLog } from "@/composables/useLog";

export default {
  //-----------------------------------------------------------------------------------------------
  // DATA
  //-----------------------------------------------------------------------------------------------
  data() {
    return {
      page: null,
      pievalues: [1, 1, 1],

      loadState: {
        page: true,
        user: false,
      },
    };
  },

  //-----------------------------------------------------------------------------------------------
  // COMPONENTS
  //-----------------------------------------------------------------------------------------------
  components: {
    TopBar,
    PieChart,
    StatementGeneral,
    TermsUser,
    TermsBiometric,
    WelcomeApp,
  },

  //-----------------------------------------------------------------------------------------------
  // SETUP
  //-----------------------------------------------------------------------------------------------
  setup() {
    const global = useGlobalStore();
    const user = useUserStore();
    const localStatement = dbStatement();
    const router = useRouter();
    const log = useLog();

    const loadingPDF = ref(false);
    const loadingDowlandPDF = ref(false);
    const visible = ref(true);
    const account = ref({});
    // const visibleUserData = ref(false);
    const messageUserData = ref("");

    const date = ref({
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
    });

    // watch(
    //   () => user.$state,
    //   async newState => {
    //     try {
    //       handleUserUpdateData(newState);
    //     } catch (error) {
    //       console.error("Error al actualizar IndexedDB:", error);
    //     }
    //   },
    //   { deep: true }
    // );
    const initContinuousSync = () => {
      watch(
        () => user.$state,
        async newState => {
          try {
            handleUserUpdateData(newState);
          } catch (error) {
            console.error("Error al actualizar IndexedDB:", error);
          }
        },
        { deep: true }
      );
    };
    //---------------------------------------------------------------------------------------------
    // Message
    //-----------------
    const getTokenMessage = async () => {
      const result = await FirebaseMessaging.getToken();
      return result.token;
    };

    const setTokenMessage = async username => {
      let token = await getTokenMessage();

      let data = {
        method: "subscribe",
        token,
        topic: "allUsers",
        username,
      };
      let httpMessageSubscribeToTopic = httpsCallable(
        global.functions,
        "httpMessageSubscribeToTopic"
      );
      return Promise.resolve(
        httpMessageSubscribeToTopic(data)
          .then(result => {
            return result;
          })
          .catch(err => {
            console.log("ERROR MSTT: ", err);
            global.throwError(err);
            throw err;
          })
      );
    };

    //---------------------------------------------------------------------------------------------
    // Send PDF
    //-----------------
    const sendPdfFund = async () => {
      loadingPDF.value = true;
      const email = user.info.data.email;
      try {
        const httpStatementPdfSend = httpsCallable(
          global.functions,
          "httpStatement"
        );
        await httpStatementPdfSend({
          method: "statementEmailPDF",
          email,
        });
        loadingPDF.value = false;
      } catch (error) {
        loadingPDF.value = false;
        console.error("Error calling function:", error);
      }
    };

    const downlandPdfFund = async () => {
      loadingDowlandPDF.value = true;
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_URL_API}/httpStatement`,

          {
            responseType: "blob",
          }
        );

        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "document.pdf";
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
        loadingDowlandPDF.value = false;
      } catch (error) {
        loadingDowlandPDF.value = false;
        console.error("Error al descargar el PDF:", error);
        alert("Error al descargar el PDF");
      }
    };

    const getDataInfoGnralClient = async () => {
      try {
        const httpAffiliateRestServices = httpsCallable(
          global.functions,
          "httpAffiliateRestServices"
        );
        await httpAffiliateRestServices({
          method: "getDataInfoGnralClient",
          codClient: user.infoHash.cod_cliente,
        });
      } catch (error) {
        console.error("Error calling function:", error);
      }
    };

    const getDataInfoGeneralClient = async () => {
      try {
        const httpAffiliateRestServices = httpsCallable(
          global.functions,
          "httpAffiliateRestServices"
        );
        await httpAffiliateRestServices({
          method: "getDataInfoGeneralClient",
          codUser: "",
          codClient: user.infoHash.cod_cliente,
        });
      } catch (error) {
        console.error("Error calling function:", error);
      }
    };

    const getFundBalance = async () => {
      try {
        const accountHash = user.infoAccount[0].cod_cuenta_ref;
        const periodHash = user.infoHash.fecha_saldo;
        const httpAffiliateRestServices = httpsCallable(
          global.functions,
          "httpAffiliateRestServices"
        );
        await httpAffiliateRestServices({
          method: "getFundBalance",
          account: accountHash,
          period: periodHash,
        });
      } catch (error) {
        console.error("Error calling function:", error);
      }
    };

    const getTypeBalance = async () => {
      try {
        const accountHash = user.infoAccount[0].cod_cuenta_ref;
        const periodHash = user.infoHash.fecha_saldo;
        const httpAffiliateRestServices = httpsCallable(
          global.functions,
          "httpAffiliateRestServices"
        );
        await httpAffiliateRestServices({
          method: "getTypeBalance",
          account: accountHash,
          period: periodHash,
        });
      } catch (error) {
        console.error("Error calling function:", error);
      }
    };

    const getMovementBalance = async () => {
      try {
        const accountHash = user.infoAccount[0].cod_cuenta_ref;
        const periodHash = user.infoHash.fecha_saldo;
        const fechaRangoInicial = user.infoHash.fecha_rango_inicial;
        const fechaRangoFinal = user.infoHash.fecha_rango_final;
        const httpAffiliateRestServices = httpsCallable(
          global.functions,
          "httpAffiliateRestServices"
        );
        await httpAffiliateRestServices({
          method: "getMovementBalance",
          account: accountHash,
          period: periodHash,
          dateStart: fechaRangoInicial,
          dateEnd: fechaRangoFinal,
        });
      } catch (error) {
        console.error("Error calling function:", error);
      }
    };

    const getRestGeneralClient = async () => {
      try {
        const accountHash = user.infoAccount[0].cod_cuenta_ref;
        const periodHash = user.infoHash.fecha_saldo;
        const httpAffiliateRestServices = httpsCallable(
          global.functions,
          "httpAffiliateRestServices"
        );
        await httpAffiliateRestServices({
          method: "getRestGeneralClient",
          account: accountHash,
          period: periodHash,
        });

        const details = {
          before: "Consultando Servicio",
          after: "Obteniendo Respuesta",
        };

        const username = localStorage.getItem("saveusername") || "";
        await log.registerUserLogHistory(
          username,
          "success",
          "getRestGeneralClient",
          "Inicio",
          details
        );

        // console.log("######### Log #########");
        // console.log(logRespuesta);
      } catch (error) {
        console.error("Error calling function:", error);
      }
    };

    const getFundRestTypeBalance = async () => {
      try {
        const accountHash = user.infoAccount[0].cod_cuenta_ref;
        const periodHash = user.infoHash.fecha_saldo;
        const httpAffiliateRestServices = httpsCallable(
          global.functions,
          "httpAffiliateRestServices"
        );
        await httpAffiliateRestServices({
          method: "getFundRestTypeBalance",
          account: accountHash,
          period: periodHash,
        });
      } catch (error) {
        console.error("Error calling function:", error);
      }
    };

    const getTagsFundBalance = async () => {
      try {
        const httpAffiliateRestServices = httpsCallable(
          global.functions,
          "httpAffiliateRestServices"
        );
        await httpAffiliateRestServices({
          method: "getTagsFundBalance",
          codUser: "",
          codClient: user.info.code,
        });

        const details = {
          before: "Consultando Servicio",
          after: "Obteniendo Respuesta",
        };

        const username = localStorage.getItem("saveusername") || "";
        await log.registerUserLogHistory(
          username,
          "success",
          "getTagsFundBalance",
          "Inicio",
          details
        );
        // console.log("######### Log #########");
        // console.log(logRespuesta);
      } catch (error) {
        console.error("Error calling function:", error);
      }
    };

    const getInfoAccountClient = async () => {
      try {
        const httpAffiliateRestServices = httpsCallable(
          global.functions,
          "httpAffiliateRestServices"
        );
        await httpAffiliateRestServices({
          method: "getInfoAccountClient",
          codUser: "",
          codClient: user.infoHash.cod_cliente,
        });

        const details = {
          before: "Consultando Servicio",
          after: "Obteniendo Respuesta",
        };

        const username = localStorage.getItem("saveusername") || "";
        await log.registerUserLogHistory(
          username,
          "success",
          "getInfoAccountClient",
          "Inicio",
          details
        );
        // console.log("######### Log #########");
        // console.log(logRespuesta);
      } catch (error) {
        console.error("Error calling function:", error);
      }
    };

    const getInfoCommission = async () => {
      try {
        const httpAffiliateRestServices = httpsCallable(
          global.functions,
          "httpAffiliateRestServices"
        );
        await httpAffiliateRestServices({
          method: "getInfoCommission",
          codUser: "",
          codClient: user.infoHash.cod_cliente,
        });
        const details = {
          before: "Consultando Servicio",
          after: "Obteniendo Respuesta",
        };

        const username = localStorage.getItem("saveusername") || "";
        await log.registerUserLogHistory(
          username,
          "success",
          "getInfoCommission",
          "Inicio",
          details
        );
        // console.log("######### Log #########");
        // console.log(logRespuesta);
      } catch (error) {
        console.error("Error calling function:", error);
      }
    };

    const validatedUserData = async () => {
      // Primero verificamos si el tutorial ha sido completado
      const isTutorialCompleted =
        localStorage.getItem("welcome_tutorial_completed") === "true";

      if (!isTutorialCompleted) {
        // Si el tutorial no está completado, forzamos que se muestre la data
        user.visibleUserData = false;
        return;
      }

      // console.log("user.visibleUserTerms", user.visibleUserTerms);
      // console.log("user.visibleUserBiometric", user.visibleUserBiometric);

      if (user.visibleUserTerms || user.visibleUserBiometric) {
        console.log(
          "validatedUserData",
          user.visibleUserTerms,
          user.visibleUserBiometric
        );
        return;
      }

      // console.log("validatedUserData");

      try {
        const httpValidatedServices = httpsCallable(
          global.functions,
          "httpValidatedServices"
        );

        const result = await httpValidatedServices({
          method: "validateSetUpdateUserData",
          identidad: user.info.id,
        });

        if (
          result.data.data.body.RESULTADO.ESTATUS.TIENE_ACTUALIZADO !==
            undefined &&
          result.data.data.body.RESULTADO.ESTATUS.TIENE_ACTUALIZADO !== null
        ) {
          // console.log(
          //   "result:",
          //   (messageUserData.value =
          //     result.data.data.body.RESULTADO.ESTATUS.TIENE_ACTUALIZADO)
          // );

          if (
            result.data.data.body.RESULTADO.ESTATUS.TIENE_ACTUALIZADO === "S"
          ) {
            user.visibleUserData = false;
            return;
          }

          messageUserData.value =
            result.data.data.body.RESULTADO.ESTATUS.TIENE_ACTUALIZADO;
          user.visibleUserData = true;
        }

        // console.log("result:", result);
      } catch (error) {
        console.error("Error calling function:", error);
        // En caso de error, también podríamos mostrar la data
        user.visibleUserData = false;
      }
    };
    const formatCurrency = (valor, symbol = "", total = false) => {
      if (valor === "Cargando...") {
        return "Cargando...";
      }
      if (valor === null || valor === undefined) {
        return "Valor no disponible";
      }
      // Intentar convertir a número si es una cadena
      if (typeof valor === "string") {
        valor = parseFloat(valor.replace(/,/g, ""));
        if (isNaN(valor)) {
          return "Valor inválido";
        }
      }
      // Verificar si el valor es un número
      if (typeof valor !== "number" || isNaN(valor)) {
        return "Valor inválido";
      }

      // Formatear con lógica especial para total
      let formattedValue;
      if (total) {
        // Convertir a string para analizar los decimales
        const valorString = valor.toString();
        const [integerPart, decimalPart] = valorString.split(".");

        // Si tiene decimales y más de 4
        if (decimalPart && decimalPart.length > 4) {
          formattedValue = parseFloat(
            valorString.slice(0, integerPart.length + 5)
          );
        } else {
          // Si tiene 4 o menos decimales, dejarlo como está
          formattedValue = valor;
        }
      } else {
        formattedValue = valor;
      }

      // Separar la parte entera de los decimales
      const [integerPart, decimalPart] = formattedValue.toString().split(".");

      // Formatear la parte entera con comas para los miles
      const formattedInteger = integerPart.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );

      // Reconstruir el número
      const formattedNumber = decimalPart
        ? `${formattedInteger}.${decimalPart}`
        : formattedInteger;

      if (symbol) {
        return `${symbol} ${formattedNumber}`;
      }
      return `${formattedNumber}`;
    };

    const syncWithIndexedDB = async () => {
      try {
        if (user.initStore) {
          return;
        }

        const exists = await validateDB("statement", global.firebaseToken.uid);
        // console.log("exists", exists);
        if (exists) {
          const userDataLocal = await user.loadFromIndexedDB(
            global.firebaseToken.uid
          );
          if (
            userDataLocal &&
            userDataLocal.statement &&
            userDataLocal.statement.token !== null
          ) {
            user.$patch(userDataLocal);
          } else {
            const userData = localStatement.getSerializableState(user);
            await localStatement.updateStatement(
              global.firebaseToken.uid,
              userData
            );
          }
        } else {
          // console.log("No existe la base de datos");
          const userData = localStatement.getSerializableState(user);
          await localStatement.createStatement(
            global.firebaseToken.uid,
            userData
          );
        }
        initContinuousSync();
      } catch (error) {
        console.error("Error al sincronizar db:", error);
      } finally {
        user.initStore = true;
      }
    };

    onMounted(async () => {
      await syncWithIndexedDB();

      // const details = {
      //   before: "Consultando Servicio",
      //   period: "Obteniendo Respuesta",
      // };
      // const username = localStorage.getItem("saveusername") || "";
      // if (username) {
      //   console.log("log username", username);
      //   const logRespuesta = await log.registerUserLogHistory(
      //     username,
      //     "success",
      //     "getRestGeneralClient",
      //     "Inicio",
      //     details
      //   );

      //   console.log("######### Log #########");
      //   console.log(logRespuesta);
      // }
    });

    onUpdated(async () => {
      if (!global.updateMessages && global.setMessages) {
        if (Capacitor.isNativePlatform()) {
          setTimeout(() => {
            const username = localStorage.getItem("saveusername");
            console.log(username);
            setTokenMessage(username);
          }, 4000);
        }
        // console.log("Se ejecuto funcion");
        setTimeout(() => {
          validatedUserData();
        }, 4000);
        global.setMessages = false;
      }
      if (global.updateMessages) {
        global.updateMessages = false;
      }
    });

    const handleUserUpdateData = async newState => {
      try {
        const userData = localStatement.getSerializableState(newState);
        await localStatement.updateStatement(
          global.firebaseToken.uid,
          userData
        );
      } catch (error) {
        console.log("Error al manejar el proxy:", error);
      }
    };

    const parseDate = date => {
      let month = (date.month + 1).toString();
      if (month.length == 1) month = "0" + month;
      let year = date.year;

      let maxday = 31;
      switch (date.month + 1) {
        case 1:
        case 3:
        case 5:
        case 7:
        case 8:
        case 10:
        case 12:
          maxday = 31;
          break;

        case 4:
        case 6:
        case 9:
        case 11:
          maxday = 30;
          break;

        case 2:
          maxday = 28;
          break;
      }

      return [year + month + "01", year + month + maxday];
    };

    const getDetailedStatement = async account => {
      loadingDowlandPDF.value = true;
      let dateNow = parseDate(date.value);
      await user.getStatementDetail(account, dateNow[0], dateNow[1]);
      loadingDowlandPDF.value = false;
    };
    return {
      global,
      user,
      sendPdfFund,
      loadingPDF,
      downlandPdfFund,
      loadingDowlandPDF,
      getDetailedStatement,
      date,
      visible,
      account,
      getDataInfoGnralClient,
      getDataInfoGeneralClient,
      getFundBalance,
      getTypeBalance,
      getMovementBalance,
      getRestGeneralClient,
      getFundRestTypeBalance,
      getTagsFundBalance,
      getInfoAccountClient,
      getInfoCommission,
      formatCurrency,
      messageUserData,
      router,
      log,
    };
  },

  //-----------------------------------------------------------------------------------------------
  // METHODS
  //-----------------------------------------------------------------------------------------------
  methods: {
    //---------------------------------------------------------------------------------------------
    // GET PAGE DATA
    //---------------------------------------------------------------------------------------------
    getParameters() {
      let data = {
        screenId: "B1",
        method: "getScreen",
      };
      let httpScreenServices = httpsCallable(
        this.global.functions,
        "httpConfigServices"
      );
      return Promise.resolve(
        httpScreenServices(data)
          .then(result => {
            if (result.data.status != 2) {
              this.page = result.data.data;
              this.loadState.page = true;
            } else {
              this.global.throwError(result.data, "B1.getParameters().result");
              throw result.data;
            }
          })
          .catch(err => {
            this.global.throwError(err, "B1.getParameters()");
            throw err;
          })
      );
    },
  },

  async created() {
    try {
      this.global.showMenuInit = true;
      await this.getParameters();

      await this.user.getUserToken();
      await this.user.getUserInfo();
      await this.user.getInfoHashAffiliate();
      await this.user.getInfoAffiliate();
      await this.user.getFundBalance();

      if (this.$route.params.account === undefined) {
        this.$route.params.account = this.user.accounts[0].code;
      }

      this.account = this.user.accounts.find(
        acc => acc.code === this.$route.params.account
      );

      if (!this.account) {
        this.account = this.user.accounts[0];
        this.$route.params.account = this.user.accounts[0].code;
      }
    } catch (err) {
      // this.global.throwError(err, "B1.created()");
      console.error(err);
      throw err;
    }
  },
  //-----------------------------------------------------------------------------------------------
  // WATCH
  //-----------------------------------------------------------------------------------------------
  watch: {
    account: function () {
      this.loadState.funds = true;
      this.user.account = this.account;
      if (this.account != null) {
        this.user.getFundBalance();
        this.loadState.user = false;
        this.$router.replace("/account/overview/" + this.account.code);
        this.user.getFundsInfo(this.account).then(() => {
          // this.pievalues = [];
          // for (let key in this.account.funds)
          //   this.pievalues.push(this.account.funds[key].value);
          this.loadState.user = true;
        });
      }
    },
  },
};
</script>

<style>
.button__sendpdf {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
}

/* Solicitud de Estado de cuenta  */

.container {
  background-color: #f2f2f2;
  padding: 20px;
  position: relative;
  margin: 0 auto;
}
.header {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 10px;
  padding: 10px;
}
.header img {
  width: 100px;
}
.title {
  text-align: start;
}
.title h1 {
  font-size: 42px;
  margin: 0;
  font-weight: 900;
  opacity: 0.7;
}
.title h3 {
  font-size: 14px;
  margin: 0;
  opacity: 0.5;
  font-weight: 800;
}
.date {
  display: flex;
  align-items: center;
  background-color: rgba(255, 151, 0, 0.2);
  border: 2px solid #ff9700;
  padding: 10px;
  border-radius: 5px;
}
.date img {
  width: 30px;
  margin-right: 10px;
}
.date h1 {
  margin: 0;
  font-size: 12px;
  font-weight: 800;
  opacity: 0.8;
}
.date h2 {
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  opacity: 0.5;
}
.section {
  margin-top: 20px;
}
.section-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.section-title h1 {
  font-size: 20px;
  margin: 0;
  font-weight: 800;
  opacity: 0.6;
}
.section-title img {
  width: 30px;
  margin-left: 10px;
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
}
.card {
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 151, 0, 0.1);
  border: 2px solid #ff9700;
  border-radius: 5px;
  padding: 10px;
}
.card h1 {
  font-size: 14px;
  font-weight: 300;
  opacity: 0.7;
  margin: 6px 0px 0px 0px;
}
.card h2 {
  margin: 0;
  font-size: 12px;
  font-weight: 300;
  opacity: 0.5;
}
.summary {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  border: 2px solid #65d8f1;
  border-radius: 5px;
  background-color: #ffffff;
}
.summary div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 15px;
}
.summary h2 {
  font-size: 12px;
  margin: 0;
  font-weight: 300;
  opacity: 0.5;
}

.summary h1 {
  font-size: 12px;
  margin: 0;
  font-weight: 300;
  opacity: 0.7;
}

.summary__total {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  border: 2px solid #65d8f1;
  border-radius: 5px;
  background-color: #65d8f1;
}
.summary__total div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 15px;
}
.summary__total h2 {
  font-size: 12px;
  margin: 0;
  font-weight: 300;
  opacity: 0.5;
}

.summary__total h1 {
  font-size: 12px;
  margin: 0;
  font-weight: 300;
  opacity: 0.7;
}

.summary__comision {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  border-radius: 5px;
  gap: 20px;
}
.summary__comision div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 15px;
  border: 1px solid #000000;
  border-radius: 5px;
}
.summary__comision h2 {
  font-size: 12px;
  margin: 0;
  font-weight: 300;
  opacity: 0.5;
}

.summary__comision h1 {
  font-size: 12px;
  margin: 0;
  font-weight: 300;
  opacity: 0.7;
}

.summary__fondo {
  display: grid;
  grid-template-columns: 200px repeat(auto-fit, minmax(100px, 1fr));
  border: 2px solid #afd60f;
  border-radius: 5px;
  background-color: #afd60f;
}
.summary__fondo div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 15px;
}
.summary__fondo h2 {
  font-size: 10px;
  margin: 0;
  font-weight: 300;
  opacity: 0.5;
}

.summary__fondo h1 {
  font-size: 10px;
  margin: 0;
  font-weight: 300;
  opacity: 0.7;
}

.summary__fondo__sub {
  display: grid;
  grid-template-columns: 200px repeat(auto-fit, minmax(100px, 1fr));
  border-radius: 5px;
}
.summary__fondo__sub div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px 15px 0px 15px;
}
.summary__fondo__sub h2 {
  font-size: 12px;
  margin: 0;
  font-weight: 300;
  opacity: 0.5;
}

.summary__fondo__sub h1 {
  font-size: 12px;
  margin: 0;
  font-weight: 300;
  opacity: 0.7;
  margin-top: 5px;
}

.section-title__sub {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.section-title__sub h1 {
  font-size: 15px;
  margin: 0;
  font-weight: 300;
  padding: 0px 15px 0px 15px;
}

.skeleton {
  background: linear-gradient(90deg, #dbdbdb 25%, #cecece 37%, #dbdbdb 63%);
  background-size: 400% 100%;
  animation: shine 1.5s ease infinite;
}

@keyframes shine {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}

.skeleton__movil {
  margin-left: 0px;
}

/* Media query para pantallas de escritorio */
@media (min-width: 768px) {
  .skeleton__movil {
    margin-left: 0px; /* Margen para pantallas de escritorio */
  }
  .button__sendpdf {
    display: none;
  }
}

/* Media query para pantallas más grandes (PC) */
@media (min-width: 1024px) {
  .skeleton__movil {
    margin-left: 30px; /* Margen para pantallas de escritorio */
  }

  .button__sendpdf {
    display: none;
  }
}

/* Teléfonos pequeños (por ejemplo, iPhone SE) */
@media (max-width: 320px) {
  .container {
    display: none;
  }
}

/* Teléfonos medianos (por ejemplo, iPhone X, Galaxy S10) */
@media (min-width: 321px) and (max-width: 480px) {
  .container {
    display: none;
  }
}

/* Teléfonos grandes (por ejemplo, iPhone 12 Pro Max, Galaxy Note) */
@media (min-width: 481px) and (max-width: 767px) {
  .container {
    display: none;
  }
}
</style>
