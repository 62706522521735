<template>
  <div class="w-full text-center flex flex-col h-full md:flex-row">
    <div class="above w-full h-full overflow-hidden md:w-4/6 opacity-100">
      <img
        :src="imageMobile"
        v-if="imageMobile"
        class="w-full h-full object-cover md:hidden special"
      />
      <img
        :src="imageDesktop"
        v-if="imageDesktop"
        class="h-full object-cover hidden md:block special"
      />
    </div>

    <div class="p-6 md:w-2/6 md:bg-lit-blue z-10 relative">
      <div class="fade absolute w-full h-24 left-0 -top-24 -z-10"></div>

      <div class="md:h-full flex md:p-4">
        <form class="m-auto w-full" @submit.prevent="login()">
          <div class="-mt-12 mb-6 md:mt-0">
            <img class="w-2/5 inline md:w-36" src="@/assets/png/brand.png" />
          </div>

          <input
            type="text"
            class="rounded-lg bg-white drop-shadow-sm p-2 w-full mb-3 text-center md:text-left lg:text-left xl:text-left"
            placeholder="Nombre de Usuario"
            onkeyup="this.value = this.value.toLowerCase()"
            v-model="username"
            @input="checkUserBiometric"
          />

          <div
            class="w-full relative mb-3"
            v-if="!global.activeBiometric || autenticateMode === 'password'"
          >
            <!-- v-if="autenticateMode === 'password'" -->
            <input
              class="rounded-lg bg-white drop-shadow-sm p-2 w-full text-center md:text-left lg:text-left xl:text-left"
              placeholder="Contraseña"
              v-model="password"
              :type="showPassword ? 'text' : 'password'"
            />
            <div
              class="absolute w-12 top-0 right-0 left-auto h-full flex cursor-pointer"
              @click="showPassword = !showPassword"
            >
              <img
                src="@/assets/png/icons/show_password.png"
                class="h-4/6 m-auto"
                v-if="!showPassword"
              />
              <img
                src="@/assets/png/icons/hide_password.png"
                class="h-4/6 m-auto"
                v-else
              />
            </div>
          </div>

          <div
            class=""
            v-if="!global.activeBiometric || autenticateMode === 'password'"
          >
            <!-- v-if="" -->
            <button
              class="rounded-lg bg-pal-3 p-2 text-center text-white font-bold cursor-pointer drop-shadow-sm mb-3 flex relative block w-full"
              :disabled="loading"
              type="submit"
            >
              <div class="m-auto" :class="loading ? 'invisible' : ''">
                Iniciar sesión
              </div>
              <div
                class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex"
              >
                <LoadSpinner v-if="loading" color="white" class="m-auto">
                </LoadSpinner>
              </div>
            </button>

            <!-- <button
              class="rounded-lg bg-pal-5 p-2 text-center text-white font-bold cursor-pointer drop-shadow-sm mb-3 flex relative block w-full"
              :disabled="loading"
              @click="sendMessage()"
              type="button"
            >
              <div class="m-auto" :class="loading ? 'invisible' : ''">
                Enviar notificación
              </div>
            </button>  -->
          </div>

          <!-- v-if="global.activeBiometric " -->
          <div class="button__biometric">
            <div
              @click="changeModeAutenticate('password')"
              v-if="autenticateMode === 'biometric' && global.activeBiometric"
              class="cursor-pointer text-pal-3 my-2 d-flex justify-center items-center font-bold"
            >
              Ingresa con
              <div class="inline-block">usuario y contraseña</div>
            </div>
          </div>

          <div
            @click="changeModeAutenticate('biometric')"
            v-if="global.activeBiometric && isNative"
            class="button__biometric cursor-pointer text-pal-3 my-2 d-flex justify-center items-center font-bold"
          >
            <div class="inline-block" v-if="autenticateMode === 'password'">
              Ingresa con

              <div class="inline-block" v-if="biometricType === 'FACE_ID'">
                Face ID
              </div>
              <div class="inline-block" v-if="biometricType === 'TOUCH_ID'">
                Touch ID
              </div>
              <div
                class="inline-block"
                v-if="biometricType === 'FACE_AUTHENTICATION'"
              >
                Face Autentication
              </div>
              <div class="inline-block" v-if="biometricType === 'FINGERPRINT'">
                Finger Print
              </div>
            </div>
          </div>

          <!--  -->
          <div
            class="flex flex-row justify-center items-center w-full"
            v-if="global.activeBiometric === true"
          >
            <div
              @click="checkCredential"
              v-if="
                autenticateMode === 'biometric' &&
                global.activeBiometric === true
              "
              class="biometric-button flex flex-row justify-center items-center drop-shadow-lg rounded-full bg-pal-1 p-2 text-center text-white cursor-pointer drop-shadow-sm mb-4"
            >
              <img
                v-if="
                  biometricType === 'FACE_ID' ||
                  biometricType === 'FACE_AUTHENTICATION'
                "
                class="m-2 animate-pulse biometric-icon"
                src="@/assets/png/icons/white/facial-recognition.png"
                alt="Facial Recognition Icon"
              />
              <img
                v-if="
                  biometricType === 'TOUCH_ID' ||
                  biometricType === 'FINGERPRINT'
                "
                class="m-2 animate-pulse biometric-icon"
                src="@/assets/png/icons/white/fingerprint.png"
                alt="Fingerprint Icon"
              />
            </div>
          </div>

          <div
            class="text-red text-center w-full text-sm transition-all overflow-y-hidden mb-2"
            :class="error.show ? 'min-h-6' : 'h-0'"
          >
            {{ error.message }}
          </div>

          <div
            class="flex sm:flex-col"
            :class="error.show ? 'mt-0' : '-mt-2'"
            style="margin-bottom: 70px"
          >
            <!-- href="https://devonboardingfrontendrap.azurewebsites.net/Home" -->
            <a
              class="bg-white rounded-xl drop-shadow-sm font-bold p-2 w-full text-sm mr-1 flex cursor-pointer mt-1"
              target="_blank"
              href="https://usuario.rap.hn/"
            >
              <div class="m-auto">Crear Usuario</div>
            </a>

            <div
              class="bg-white rounded-xl drop-shadow-sm font-bold p-2 w-full text-sm cursor-pointer block w-full mt-1"
              @click="recoveryMode = true"
            >
              ¿Olvidaste tu contraseña?
            </div>
          </div>
        </form>
      </div>

      <!-- recovery mode -->
      <form
        class="bg-lit-blue fixed md:absolute left-0 top-0 p-8 w-full h-full flex"
        v-if="recoveryMode"
        @submit.prevent="sendPasswordReset()"
      >
        <div class="m-auto w-full">
          <div class="mb-6">
            <img class="w-2/5 inline md:w-36" src="@/assets/png/brand.png" />
          </div>

          <!-- page 1 -->
          <div class="" v-if="recoveryStep == 0">
            <div class="w-full relative mb-3 flex">
              <!-- <img
                class="w-6 m-auto md:w-5 cursor-pointer"
                src="@/assets/png/icons/arrow.png"
                @click="exitRecovery()"
              /> -->
              <i class="fa-solid fa-chevron-left" @click="exitRecovery()"></i>
              <div class="w-full text-left ml-4">
                Ingresa tu usuario y te enviaremos un enlace de recuperación a
                tu correo:
              </div>
            </div>

            <input
              type="text"
              class="rounded-lg bg-white drop-shadow-sm p-2 w-full mb-3"
              placeholder="Nombre de Usuario"
              v-model="recoveryemail"
            />

            <button
              class="rounded-lg bg-pal-3 p-2 text-center text-white font-bold cursor-pointer drop-shadow-sm mb-3 flex relative block w-full"
              type="submit"
            >
              <div class="m-auto" :class="loading ? 'invisible' : ''">
                Enviar correo de recuperación
              </div>
              <div
                class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex"
              >
                <LoadSpinner v-if="loading" color="white" class="m-auto">
                </LoadSpinner>
              </div>
            </button>

            <div
              class="text-red text-center w-full text-sm transition-all overflow-y-hidden mb-2"
              :class="errorRecovery.show ? 'h-full' : 'h-0'"
            >
              {{ errorRecovery.message }}
            </div>
          </div>

          <!-- page 1 -->
          <div class="" v-if="recoveryStep == 1">
            <div class="w-full relative mb-3 flex">
              ¡Listo! Se ha enviado un enlace de recuperación a tu correo.
            </div>

            <div
              class="bg-white rounded-xl drop-shadow-sm font-bold p-2 w-full text-sm cursor-pointer"
              @click="
                recoveryMode = false;
                recoveryStep = 0;
              "
            >
              Regresar
            </div>
          </div>

          <!-- page 2 -->
          <div class="" v-if="recoveryStep == 2">
            <div class="w-full relative mb-3 flex flex-col">
              Usuario no encontrado, puede crear una nueva cuenta en:

              <a
                style="color: #ff9700"
                href="https://usuario.rap.hn/"
                target="_blank"
                >https://usuario.rap.hn</a
              >
            </div>

            <div
              class="bg-white rounded-xl drop-shadow-sm font-bold p-2 w-full text-sm cursor-pointer"
              @click="
                recoveryMode = false;
                recoveryStep = 0;
              "
            >
              Regresar
            </div>
          </div>

          <!-- page 2 -->
          <div class="" v-if="recoveryStep == 3">
            <div class="w-full relative mb-3 flex">
              Se ha enviado a su correo la clave temporal generada
            </div>

            <div
              class="bg-white rounded-xl drop-shadow-sm font-bold p-2 w-full text-sm cursor-pointer"
              @click="
                recoveryMode = false;
                recoveryStep = 0;
              "
            >
              Regresar
            </div>
          </div>
        </div>
      </form>

      <!-- reset mode -->
      <form
        class="bg-lit-blue fixed md:absolute left-0 top-0 p-8 w-full h-full flex"
        v-if="resetMode || updateMode"
        @submit.prevent="
          resetMode
            ? changePassword()
            : updateMode
            ? passwordUpdate()
            : handleSubmit()
        "
      >
        <div class="m-auto w-full">
          <div class="mb-6">
            <img class="w-2/5 inline md:w-36" src="@/assets/png/brand.png" />
          </div>

          <div v-if="!resetModeResolve">
            <LoadSpinner color="black" class="w-6 m-auto"> </LoadSpinner>
          </div>

          <div class="" v-else-if="resetModeInvalid">
            <div class="mb-4">
              El enlace de cambio de contraseña ha expirado. Solicita otro para
              realizar este proceso.
            </div>

            <div
              class="bg-white rounded-xl drop-shadow-sm font-bold p-2 w-full text-sm cursor-pointer"
              @click="closePasswordReset()"
            >
              Regresar
            </div>
          </div>

          <div v-else-if="!resetModeSent">
            <div class="mb-4">
              {{
                resetMode == true
                  ? `Escribe la nueva contraseña para tu cuenta ${resetModeEmail}`
                  : "Por tu seguridad debes cambiar tu contraseña a una más segura"
              }}

              <!-- Escribe la nueva contraseña para tu cuenta <b>{{resetModeEmail}}</b> -->
            </div>

            <div class="w-full relative mb-3">
              <input
                class="rounded-lg bg-white drop-shadow-sm p-2 w-full"
                placeholder="Nueva contraseña"
                v-model="password"
                :type="showResetPassword ? 'text' : 'password'"
                autocomplete="new-password"
                @input="checkPassword"
              />
              <!-- v-model="password" -->
              <div
                class="absolute w-12 top-0 right-0 left-auto h-full flex cursor-pointer"
                @click="showResetPassword = !showResetPassword"
              >
                <img
                  src="@/assets/png/icons/show_password.png"
                  class="h-4/6 m-auto"
                  v-if="!showResetPassword"
                />
                <img
                  src="@/assets/png/icons/hide_password.png"
                  class="h-4/6 m-auto"
                  v-else
                />
              </div>
            </div>

            <div class="w-full relative mb-3">
              <!-- <input class = "rounded-lg bg-white drop-shadow-sm p-2 w-full"
                    placeholder = "Repetir contraseña" v-model = "resetpasswordrepeat" :type="showResetPasswordRepeat ? 'text' : 'password'" autocomplete="new-password-repeat"
                    @input="checkPassword"
                    > -->
              <input
                class="rounded-lg bg-white drop-shadow-sm p-2 w-full"
                placeholder="Repetir contraseña"
                v-model="password1"
                :type="showResetPasswordRepeat ? 'text' : 'password'"
                autocomplete="new-password-repeat"
                @input="checkPassword"
              />
              <!-- v-model="password1" -->
              <div
                class="absolute w-12 top-0 right-0 left-auto h-full flex cursor-pointer"
                @click="showResetPasswordRepeat = !showResetPasswordRepeat"
              >
                <img
                  src="@/assets/png/icons/show_password.png"
                  class="h-4/6 m-auto"
                  v-if="!showResetPasswordRepeat"
                />
                <img
                  src="@/assets/png/icons/hide_password.png"
                  class="h-4/6 m-auto"
                  v-else
                />
              </div>
            </div>

            <div class="md:relative">
              <div
                class="input_container rounded-xl p-3 mt-6 mb-6"
                style="border: 1px solid #afd60f; text-align: left"
              >
                <strong class="pal-2">Tu contraseña debe contener:</strong>

                <ul class="check-list">
                  <li
                    class="grey--text"
                    v-bind:class="{ is_valid: contains_eight_characters }"
                  >
                    Mínimo 8 caracteres
                  </li>
                  <li
                    class="grey--text"
                    v-bind:class="{ is_valid: contains_number }"
                  >
                    Al menos un número
                  </li>
                  <li
                    class="grey--text"
                    v-bind:class="{ is_valid: contains_uppercase }"
                  >
                    Al menos una letra mayúscula
                  </li>
                  <li
                    class="grey--text"
                    v-bind:class="{ is_valid: contains_special_character }"
                  >
                    Al menos un caracter especial !@#$%^&*_+()-=[];:|,./?
                  </li>
                  <li
                    class="grey--text"
                    v-bind:class="{ is_valid: contains_matchPasswords }"
                  >
                    Las contraseñas coinciden
                  </li>
                </ul>

                <div
                  class="checkmark_container"
                  v-bind:class="{ show_checkmark: valid_password }"
                >
                  <svg width="50%" height="50%" viewBox="0 0 140 100">
                    <path
                      class="checkmark"
                      v-bind:class="{ checked: valid_password }"
                      d="M10,50 l25,40 l95,-70"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <button
              :disabled="!valid_password"
              class="rounded-lg bg-pal-3 p-2 text-center text-white font-bold drop-shadow-sm mb-3 flex relative block w-full"
              type="submit"
            >
              <div class="m-auto" :class="loading ? 'invisible' : ''">
                Cambiar contraseña
              </div>
              <div
                class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex"
              >
                <LoadSpinner v-if="loading" color="white" class="m-auto">
                </LoadSpinner>
              </div>
            </button>

            <div
              class="text-red text-center w-full text-sm transition-all overflow-y-hidden mb-2"
              :class="errorReset.show ? 'min-h-6' : 'h-0'"
            >
              {{ errorReset.message }}
            </div>
          </div>

          <div v-else>
            <div class="mb-4">
              Se ha hecho el cambio de contraseña, ahora ingresa con tus nuevas
              credenciales.
            </div>

            <div
              class="bg-white rounded-xl drop-shadow-sm font-bold p-2 w-full text-sm cursor-pointer"
              @click="resetMode ? closePasswordReset() : closePasswordUpdate()"
            >
              Ir a login
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {
  signInWithEmailAndPassword,
  verifyPasswordResetCode,
  confirmPasswordReset,
  updatePassword,
} from "firebase/auth"; //, connectAuthEmulator
import { useGlobalStore } from "@/store/GlobalStore";
import { httpsCallable } from "firebase/functions";
// import firebase from 'firebase/app';
// import "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";
import LoadSpinner from "@/components/LoadSpinner.vue";
import { Capacitor } from "@capacitor/core";
import { useUserStore } from "@/store/UserStore";
// import { doc, onSnapshot } from "firebase/firestore";
import { NativeBiometric } from "capacitor-native-biometric";
import { FirebaseMessaging } from "@capacitor-firebase/messaging";
import { onMounted } from "vue";
import { useLog } from "@/composables/useLog";
import axios from "axios";

export default {
  data() {
    return {
      biometric: false,
      // biometricType: false,
      biometricType: "TOUCH_ID",

      isNativePlatform: false, //valida si e sun telefono
      isAvailableNativeBiometric: false, //valida que este dispoibe le biometrico
      isSetCredentials: false, //variable que verifica si  estan configuradas las credenciales de bimetrico

      auth: null,
      username: "",
      // password: "",
      loading: false,
      showPassword: false,

      autenticateMode: "password",

      // checkvalidator Charlies
      password: null,

      password1: null,
      password_length: 0,
      contains_eight_characters: false,
      contains_max_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_special_character: false,
      valid_password: false,
      contains_matchPasswords: false,

      error: {
        show: false,
        message: "Default error",
      },

      errorRecovery: {
        show: false,
        message: "Default error",
      },

      imageDesktop: null,
      imageMobile: null,

      fadeMe: true,

      recoveryMode: false,
      recoveryStep: 0,
      recoveryType: "email",
      recoveryemail: "",

      resetMode: false,
      resetModeResolve: false,
      resetModeInvalid: false,
      resetModeEmail: "",
      resetModeSent: false,

      updateMode: false,

      createMode: false,

      errorReset: {
        show: false,
        message: "Default error",
      },

      resetpassword: "",
      resetpasswordrepeat: "",
      showResetPassword: false,
      showResetPasswordRepeat: false,
    };
  },

  setup() {
    const global = useGlobalStore();
    const user = useUserStore();
    const log = useLog();

    const isNative = Capacitor.isNativePlatform();

    //solicitud permiso de notificacion
    onMounted(async () => {
      let permStatus = await FirebaseMessaging.checkPermissions();

      if (permStatus.receive === "prompt") {
        permStatus = await FirebaseMessaging.requestPermissions();
      }
    });

    return { global, user, isNative, log };
  },

  async created() {
    //console.log("QUERIES: ",this.$route.query,this.$route.query.mode)
    this.username = "";
    this.recoveryemail = "";
    this.password = "";
    this.password1 = "";

    this.username = localStorage.getItem("saveusername");

    //validando si es un telefono
    // this.isNativePlatform = Capacitor.isNativePlatform();

    // if (this.isNativePlatform) {
    //   console.log("this.isNativePlatform", this.isNativePlatform);

    //   this.username = localStorage.getItem("saveusername");
    //   // this.username = localStorage.getItem("saveusername");

    //   //validando si esta dispnible el biometrico
    //   const result = await NativeBiometric.isAvailable();
    //   this.isAvailableNativeBiometric = result.isAvailable;
    //   console.log(
    //     "this.isAvailableNativeBiometric",
    //     this.isAvailableNativeBiometric
    //   );

    //   try {
    //     this.isSetCredentials = await NativeBiometric.getCredentials({
    //       server: "https://piloto.rap.hn",
    //     });

    //     console.log("isSetCredentials", JSON.stringify(this.isSetCredentials));
    //     // this.isSetCredentials = false;
    //     this.autenticateMode = "password";

    //     if (this.isSetCredentials) {
    //       this.isSetCredentials = true;
    //       this.autenticateMode = "biometric";
    //     }
    //   } catch (error) {
    //     console.log("ERROR isSetCredentials", error);
    //     this.isSetCredentials = false;
    //     this.autenticateMode = "password";
    //   }

    //   //si ya estab aconfigurado el biometrico pues coloco por defecto el modo en biometrico por defecto
    // }
    const storageRef1 = ref(
      this.global.firestorage,
      "gs://beanrap-dev.appspot.com/media/screens/a1/desktop.jpg"
    );
    getDownloadURL(storageRef1).then(url => {
      this.imageDesktop = url;
      this.fadeMe = false;
    });

    const storageRef2 = ref(
      this.global.firestorage,
      "gs://beanrap-dev.appspot.com/media/screens/a1/mobile.jpg"
    );
    getDownloadURL(storageRef2).then(url => {
      this.imageMobile = url;
      this.fadeMe = false;
    });

    if ("mode" in this.$route.query) {
      switch (this.$route.query.mode) {
        case "resetPassword":
          this.resetMode = true;
          this.verifyCode();
          break;
        // case 'firstLogin':
        //   // this.createPasswordMode=true
        //   console.log('levantar el modal del creacion de pasword en modo firtLogin')
        //   break;

        default:
          break;
      }
    }

    // this.global.activeBiometric =
    //   localStorage.getItem("credentialsSet") === "true";

    if (Capacitor.isNativePlatform()) {
      //si e stelefono
      const credentialsSet = localStorage.getItem("credentialsSet") == "true";
      if (credentialsSet) {
        this.global.activeBiometric = true;
        this.autenticateMode = "biometric";
      } else {
        this.global.activeBiometric = false;
        this.autenticateMode = "password";
      }
    }
    // this.checkCredential();
    this.checkUserBiometric();
  },

  methods: {
    askConfirmation(evt) {
      var msg =
        "Si recarga la página perdera todos los datos ingresados.\n¿Deseas recargar la página?";
      evt.returnValue = msg;
      return msg;
    },

    //-------------------------------------------------------------------------------------------
    // MESSAGE
    //-------------------------------------------------------------------------------------------
    // async getTokenMessage() {
    //   const result = await FirebaseMessaging.getToken();
    //   console.log("Token result: ", result);
    //   return result.token;
    // },

    // async setTokenMessage(username) {
    //   let token = await this.getTokenMessage();

    //   // alert(`TOKEN MESSAGE: ${token}`);
    //   let data = {
    //     method: "subscribe",
    //     token,
    //     topic: "allUsers",
    //     username,
    //   };
    //   let httpMessageSubscribeToTopic = httpsCallable(
    //     this.global.functions,
    //     "httpMessageSubscribeToTopic"
    //   );
    //   return Promise.resolve(
    //     httpMessageSubscribeToTopic(data)
    //       .then(result => {
    //         console.log("RESULT MSTT: ", result);
    //         // alert(`RESULT MSTT: ${JSON.stringify(result)}`);
    //       })
    //       .catch(err => {
    //         console.log("ERROR MSTT: ", err);
    //         this.global.throwError(err);
    //         throw err;
    //       })
    //   );
    // },

    getIdentityToken() {
      return new Promise((resolve, reject) => {
        this.global.fireauth.currentUser
          .getIdToken(true)
          .then(token => {
            resolve(token);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    //-------------------------------------------------------------------------------------------
    // BIOMETRIC
    //-------------------------------------------------------------------------------------------
    setCredential(username, password) {
      // Guarda las credenciales del usuario
      if (Capacitor.isNativePlatform()) {
        NativeBiometric.setCredentials({
          username: username,
          password: password,
          server: "https://piloto.rap.hn",
        }).then();
      }
    },

    checkCredential() {
      // Verificar si las credenciales ya se han establecido
      const credentialsSet = localStorage.getItem("credentialsSet") == "true";

      NativeBiometric.isAvailable().then(result => {
        const isAvailable = result.isAvailable;

        if (isAvailable) {
          if (credentialsSet) {
            this.biometricType = result.biometryType || false;
            // Obtener las credenciales del usuario
            NativeBiometric.getCredentials({
              server: "https://piloto.rap.hn",
            }).then(credentials => {
              // Autenticar usando biometría antes de iniciar sesión
              NativeBiometric.verifyIdentity({
                reason: "Para proteger tu cuenta",
                title: "Verificación de Identidad",
                subtitle: "¡Bienvenida(o) de nuevo!",
                description:
                  "Por favor, utiliza tu huella dactilar para iniciar sesión de manera segura en RAP",
              })
                .then(() => {
                  this.username = credentials.username;
                  this.password = credentials.password;
                  this.login();
                })
                .catch(err => {
                  // Fallo en la autenticación biométrica
                  alert("Error, Por favor vuelva a intentarlo", err);
                });
            });
          } else {
            alert(
              "Es necesario que inicies sesión al menos una vez para poder usar la autenticación biométrica."
            );
          }
        } else {
          alert("Autenticación biométrica no disponible.");
        }
      });
    },

    checkUserBiometric() {
      const unb = localStorage.getItem("unb") || "notuser";
      if (this.username === null) {
        return;
      }

      if (this.username.toLowerCase() === unb.toLowerCase()) {
        this.global.activeBiometric = true;
      } else {
        this.global.activeBiometric = false;
      }
    },
    //-------------------------------------------------------------------------------------------
    // FIREBASE LOGIN
    //-------------------------------------------------------------------------------------------
    // login() {
    //   this.error.show = false;
    //   this.global.getRAPToken().then(() => {
    //     try {
    //       this.loading = true;
    //       signInWithEmailAndPassword(
    //         this.global.fireauth,
    //         this.username.trim() + "@auth.rap.hn",
    //         this.password
    //       )
    //         .then(async userCredential => {
    //           this.global.firebaseToken = userCredential.user;

    //           // if (Capacitor.isNativePlatform()) {
    //           //   this.setTokenMessage(this.username);
    //           // }
    //           localStorage.setItem(
    //             "saveusername",
    //             this.username.toLowerCase().trim()
    //           );

    //           // if(){

    //           // }
    //           if (Capacitor.isNativePlatform()) {
    //             const credentialsSet = localStorage.getItem("credentialsSet");

    //             if (!credentialsSet) {
    //               this.setCredential(this.username, this.password);
    //               localStorage.setItem("unb", this.username);
    //             }
    //           }

    //           this.log.registerAuthActivity(
    //             this.username.toLowerCase().trim(),
    //             true,
    //             "login"
    //           );

    //           this.$router.push("/account");
    //         })
    //         .catch(err => {
    //           console.log(err);
    //           this.log.registerAuthActivity(
    //             this.username.toLowerCase().trim(),
    //             false,
    //             "login"
    //           );
    //           console.log(err);
    //           this.loading = false;
    //           switch (err.code) {
    //             case "auth/emulator-config-failed":
    //             case "auth/missing-email":
    //               this.showError("Ingresa un nombre de usuario y contraseña.");
    //               break;

    //             case "auth/network-request-failed":
    //               this.global.isConnectedToInternet = false;
    //               break;

    //             case "auth/wrong-password":
    //               this.showError("Revisa que tu credenciales sean correctas.");
    //               break;

    //             case "auth/internal-error":
    //               this.showError(
    //                 "Algo salió mal al tratar de ingresar, por favor intenta de nuevo."
    //               );
    //               break;
    //             case "auth/invalid-login-credentials":
    //               this.showError("Las credenciales no son correctas.");
    //               break;

    //             case "auth/too-many-requests":
    //               this.showError(
    //                 "Tu usuario ha sido bloqueado por seguridad, por favor reestablece tu contraseña."
    //               );
    //               break;

    //             case "auth/user-not-found":
    //             case "auth/invalid-email":
    //               //El usuario no existe, intentando migración
    //               console.log("err.code", err.code);
    //               this.legacyLogin(this.username.trim(), this.password);

    //               // if(this.createPasswordMode === true){
    //               //   console.log('SI no es reset pasword es create password')
    //               //   if(this.$route.query.mode ==='resetPassword'){
    //               //   console.log('si el usuario no existe  y el reset pasword es true enotnces migrarlo')

    //               // }
    //               // }else{

    //               // }

    //               break;

    //             default:
    //               // this.global.throwError(err);
    //               console.log(err);
    //               throw err;
    //           }
    //         });
    //     } catch (err) {
    //       this.loading = false;
    //       this.showError("Revisa que tu usuario y contraseña sean correctos.");
    //     }
    //   });
    // },

    async login() {
      this.error.show = false;
      try {
        this.loading = true;
        const email = this.username.trim() + "@auth.rap.hn";

        // Primero verificamos si el usuario existe usando nuestra Cloud Function
        const checkUserResponse = await axios.post(
          `${process.env.VUE_APP_URL_API}/httpAdminServices`,
          {
            data: {
              method: "checkUserExists",
              email: email,
            },
          }
        );

        const checkUserResult = checkUserResponse;
        // console.log("user exist: ", checkUserResult);

        if (!checkUserResult.data.data.exists) {
          // Si el usuario no existe, vamos directamente al legacy login
          this.loading = false;
          return this.legacyLogin(this.username.trim(), this.password);
        }

        // Si el usuario existe, intentamos el login con Firebase
        try {
          const userCredential = await signInWithEmailAndPassword(
            this.global.fireauth,
            email,
            this.password
          );

          this.global.firebaseToken = userCredential.user;

          localStorage.setItem(
            "saveusername",
            this.username.toLowerCase().trim()
          );

          if (Capacitor.isNativePlatform()) {
            const credentialsSet = localStorage.getItem("credentialsSet");
            if (!credentialsSet) {
              this.setCredential(this.username, this.password);
              localStorage.setItem("unb", this.username);
            }
          }

          this.log.registerAuthActivity(
            this.username.toLowerCase().trim(),
            true,
            "login"
          );

          this.$router.push("/account");
        } catch (err) {
          console.log(err);
          this.log.registerAuthActivity(
            this.username.toLowerCase().trim(),
            false,
            "login"
          );
          this.loading = false;

          switch (err.code) {
            case "auth/network-request-failed":
              this.global.isConnectedToInternet = false;
              break;
            case "auth/invalid-login-credentials":
              // En este punto sabemos que el usuario existe pero la contraseña es incorrecta
              this.showError("Tus credenciales son incorrectas.");
              break;
            case "auth/too-many-requests":
              this.showError(
                "Tu usuario ha sido bloqueado por seguridad, por favor reestablece tu contraseña."
              );
              break;
            default:
              this.showError(
                "Ocurrió un error al intentar iniciar sesión. Por favor intenta de nuevo."
              );
              console.log(err);
          }
        }
      } catch (err) {
        this.loading = false;
        console.error("Error en proceso de login:", err);
        this.showError("Ocurrió un error al verificar las credenciales.");
      }
    },

    //-----------------------------------------------------------------------------------------
    // LEGACY LOGIN
    //-----------------------------------------------------------------------------------------
    legacyLogin() {
      try {
        let data = {
          method: "migrateUser",
          token: this.global.rapToken,
          user: this.username.toLowerCase(),
          password: this.password,
        };
        this.loading = true;
        let httpAffiliateServices = httpsCallable(
          this.global.functions,
          "httpUserServices"
        );
        return Promise.resolve(
          httpAffiliateServices(data)
            .then(result => {
              if (result.data.status == 2) {
                this.showError(result.data.message);
                this.loading = false;
                return;
              }

              if (result.data.message == 1) {
                this.showError(result.data.message);
                this.loading = false;
                return;
              }

              if (result.data.status == 0) {
                this.error.show = false;
                signInWithEmailAndPassword(
                  this.global.fireauth,
                  result.data.data.email,
                  this.password
                )
                  .then(userCredential => {
                    this.global.firebaseToken = userCredential.user;

                    // check if password needs change
                    if (result.data.changePassword === false) {
                      // needs to change password
                      // set new password
                      this.updateMode = true;
                      // this.createMode = true
                      this.global.updateMode = true;
                      this.resetModeResolve = true;
                      this.loading = false;

                      this.clearInputs(); //limpiando entrada
                      // this.username='';

                      //TODO evitar recargo de pagina
                      window.addEventListener(
                        "beforeunload",
                        this.askConfirmation
                      );
                    } else {
                      // this.createMode=false
                      this.updateMode = false;
                      this.$router.push("/account");
                    }
                  })
                  .catch(err => {
                    this.showError(err);
                  });
                return true;
              } else if (result.data.status == 3) {
                this.showError(
                  "Ocurrió un error al intentar iniciar sesión, por favor contacta a soporte técnico."
                );
                this.loading = false;
                return false;
              } else {
                this.showError(
                  "Revisa que tu usuario y contraseña sean correctos."
                );
                this.loading = false;
                return false;
              }
            })
            .catch(err => {
              this.global.throwError(err, "A1.migrateUser().results");
              throw err;
            })
        );
      } catch (err) {
        this.global.throwError(err, "A1.migrateUser()");
        throw err;
      }
    },

    //-------------------------------------------------------------------------------------------
    // SHOW ERROR
    //-------------------------------------------------------------------------------------------
    showError(message) {
      this.error.show = true;
      this.error.message = message;
    },

    //-------------------------------------------------------------------------------------------
    // EXIT RECOVERY
    //-------------------------------------------------------------------------------------------
    exitRecovery() {
      this.recoveryemail = "";
      this.errorRecovery.show = false;
      this.recoveryMode = false;
    },

    //-------------------------------------------------------------------------------------------
    // RESET PASSWORD
    //-------------------------------------------------------------------------------------------
    // async sendPasswordReset() {
    //   this.errorRecovery.show = false;

    //   if (this.recoveryemail == "") {
    //     this.errorRecovery.show = true;
    //     this.errorRecovery.message = "Por favor ingresa tu nombre de usuario.";
    //     return false;
    //   }

    //   this.loading = true;

    //   // retrieve system data for password reset email
    //   const operatingSytemAndBrowser = () => {
    //     let userAgent = navigator.userAgent;
    //     let browserName;
    //     let OSName;

    //     if (userAgent.match(/chrome|chromium|crios/i)) {
    //       browserName = "Chrome";
    //     } else if (userAgent.match(/firefox|fxios/i)) {
    //       browserName = "Firefox";
    //     } else if (userAgent.match(/safari/i)) {
    //       browserName = "Safari";
    //     } else if (userAgent.match(/opr\//i)) {
    //       browserName = "Opera";
    //     } else if (userAgent.match(/edg/i)) {
    //       browserName = "Edge";
    //     } else {
    //       browserName = "No browser detection";
    //     }
    //     console.log("browserName...:", browserName);

    //     if (userAgent.match(/Win|win/i)) {
    //       OSName = "Windows";
    //     } else if (userAgent.match(/Mac|mac/i)) {
    //       OSName = "MacOS";
    //     } else if (userAgent.match(/X11|x11/i)) {
    //       OSName = "UNIX";
    //     } else if (userAgent.match(/Linux|linux/i)) {
    //       OSName = "Linux";
    //     } else {
    //       OSName = "Unknown OS";
    //     }
    //     console.log("OSName...:", OSName);

    //     return { browserName: browserName, OSName: OSName };
    //   };
    //   const systemData = operatingSytemAndBrowser();

    //   let data = {
    //     method: "resetPassword",
    //     email: this.recoveryemail,
    //     operating_system: systemData.OSName,
    //     browser_name: systemData.browserName,
    //   };
    //   let httpUserPasswordReset = httpsCallable(
    //     this.global.functions,
    //     "httpUserPasswordReset"
    //   );
    //   httpUserPasswordReset(data)
    //     .then(result => {
    //       console.log("PASSWORD RESET:", result);
    //       this.loading = true;
    //       if (result.data.validEmail == false) {
    //         try {
    //           let httpValidatedServices = httpsCallable(
    //             this.global.functions,
    //             "httpValidatedServices"
    //           );
    //           this.loading = true;
    //           httpValidatedServices({
    //             method: "resetPasswordUser",
    //             token: this.global.rapToken,
    //             username: this.recoveryemail.toLowerCase().trim(),
    //           })
    //             .then(result => {
    //               console.log("RESULT VALIDATED SERVICES:", result);
    //               this.loading = true;
    //               if (
    //                 result.data.data.body.OT_SYSDE_SOLICITAR_CONTRASENIA
    //                   .RESULTADO === "INVALIDO"
    //               ) {
    //                 console.log("Usuario no encontrado");
    //                 this.recoveryStep = 2;
    //                 return;
    //               }
    //               this.recoveryStep = 1;
    //             })
    //             .catch(err => {
    //               console.log("ERROR VALIDATED SERVICES:", err);
    //               this.recoveryStep = 2;
    //             })
    //             .finally(() => {
    //               this.loading = false;
    //             });
    //         } catch (error) {
    //           console.log("Error al enviar el correo de recuperación:", error);
    //           this.recoveryStep = 2;
    //         } finally {
    //           this.loading = false;
    //           this.recoveryemail = "";
    //         }
    //       } else {
    //         this.recoveryStep = 1;
    //       }
    //     })
    //     .catch(err => {
    //       console.log("ERROR:", err);
    //       this.loading = false;
    //       this.errorRecovery.show = true;
    //       this.errorRecovery.message =
    //         "No se pudo enviar el correo de recuperación, revisa que el nombre de usuario esté bien escrito.";
    //       this.recoveryStep = 2;
    //     })
    //     .finally(() => {
    //       this.loading = false;
    //       this.recoveryemail = "";
    //     });
    // },

    async sendPasswordReset() {
      this.errorRecovery.show = false;

      if (this.recoveryemail == "") {
        this.errorRecovery.show = true;
        this.errorRecovery.message = "Por favor ingresa tu nombre de usuario.";
        return false;
      }

      this.loading = true;

      try {
        // retrieve system data for password reset email
        const operatingSytemAndBrowser = () => {
          let userAgent = navigator.userAgent;
          let browserName;
          let OSName;

          if (userAgent.match(/chrome|chromium|crios/i)) {
            browserName = "Chrome";
          } else if (userAgent.match(/firefox|fxios/i)) {
            browserName = "Firefox";
          } else if (userAgent.match(/safari/i)) {
            browserName = "Safari";
          } else if (userAgent.match(/opr\//i)) {
            browserName = "Opera";
          } else if (userAgent.match(/edg/i)) {
            browserName = "Edge";
          } else {
            browserName = "No browser detection";
          }

          if (userAgent.match(/Win|win/i)) {
            OSName = "Windows";
          } else if (userAgent.match(/Mac|mac/i)) {
            OSName = "MacOS";
          } else if (userAgent.match(/X11|x11/i)) {
            OSName = "UNIX";
          } else if (userAgent.match(/Linux|linux/i)) {
            OSName = "Linux";
          } else {
            OSName = "Unknown OS";
          }

          return { browserName, OSName };
        };

        const systemData = operatingSytemAndBrowser();

        let data = {
          method: "resetPassword",
          email: this.recoveryemail,
          operating_system: systemData.OSName,
          browser_name: systemData.browserName,
        };

        const httpUserPasswordReset = httpsCallable(
          this.global.functions,
          "httpUserPasswordReset"
        );

        const result = await httpUserPasswordReset(data);

        if (result.data.validEmail == false) {
          const httpValidatedServices = httpsCallable(
            this.global.functions,
            "httpValidatedServices"
          );

          const validatedResult = await httpValidatedServices({
            method: "resetPasswordUser",
            token: this.global.rapToken,
            username: this.recoveryemail.toLowerCase().trim(),
          });

          if (
            validatedResult.data.data.body.OT_SYSDE_SOLICITAR_CONTRASENIA
              .RESULTADO === "INVALIDO"
          ) {
            this.recoveryStep = 2;
          } else {
            this.recoveryStep = 3;
          }
        } else {
          this.recoveryStep = 1;
        }
      } catch (error) {
        this.errorRecovery.show = true;
        this.errorRecovery.message =
          "No se pudo enviar el correo de recuperación, revisa que el nombre de usuario esté bien escrito.";
        this.recoveryStep = 2;
      } finally {
        this.loading = false;
        this.recoveryemail = "";
      }
    },

    //-------------------------------------------------------------------------------------------
    // VERIFY PASSWORD RESET CODE
    //-------------------------------------------------------------------------------------------
    verifyCode() {
      verifyPasswordResetCode(this.global.fireauth, this.$route.query.oobCode)
        .then(result => {
          console.log("VERIFY CODE CHECK:", result);
          this.resetModeEmail = result;
          this.resetModeResolve = true;
        })
        .catch(() => {
          this.resetModeInvalid = true;
          this.resetModeResolve = true;
        });
    },

    //-------------------------------------------------------------------------------------------
    // CONFIRM PASSWORD CHANGE
    //-------------------------------------------------------------------------------------------
    changePassword() {
      this.errorReset.show = false;

      // if (this.resetpassword === "" || this.resetpasswordrepeat === "") {
      if (this.password === "" || this.password1 === "") {
        this.errorReset.show = true;
        this.errorReset.message =
          "Por favor ingresa una nueva contraseña y repite la contraseña.";
        return false;
      }

      // if (!this.passwordStrength(this.resetpassword)) {
      //   this.errorReset.show = true;
      //   this.errorReset.message = "Tu contraseña debe de ser de al menos 8 caracteres y contener una mayúscula, un número y un caracter especial."
      //   return false;
      // }

      // if (this.resetpassword != this.resetpasswordrepeat) {
      //   this.errorReset.show = true;
      //   this.errorReset.message = "Las contraseñas deben coincidir."
      //   return false;
      // }

      this.loading = true;

      // confirmPasswordReset(this.global.fireauth,this.$route.query.oobCode,this.resetpassword)
      confirmPasswordReset(
        this.global.fireauth,
        this.$route.query.oobCode,
        this.password
      )
        .then(() => {
          this.resetModeSent = true;
          this.loading = false;

          this.clearInputs(); //llamando la funcion de limpieza de entradas
        })
        .catch(err => {
          switch (err.code) {
            case 400:
              this.errorReset.message =
                "La contraseña debe contener al menos 6 caracteres.";
              break;

            default:
              this.errorReset.message =
                "Algo salió mal y no pudimos cambiar tu contraseña o es muy débil.";
              break;
          }
          this.errorReset.show = true;
          this.loading = false;
        });
    },

    //-------------------------------------------------------------------------------------------
    // CLOSE PASSWORD RESET
    //-------------------------------------------------------------------------------------------
    closePasswordReset() {
      this.resetMode = false;
      this.clearInputs(); //limpiando entradas
      this.$router.push("/");
    },

    //-------------------------------------------------------------------------------------------
    // CLOSE PASSWORD UPDATE
    //-------------------------------------------------------------------------------------------
    closePasswordUpdate() {
      this.clearInputs(); //limpiando entradas
      this.updateMode = false;
      this.$router.push("/");
    },

    //-------------------------------------------------------------------------------------------
    // PASSWORD UPDATE
    //-------------------------------------------------------------------------------------------
    passwordUpdate() {
      this.errorReset.show = false;
      //evitar ecargo de la pagina por segurida del token y del flujo

      if (this.password === "" || this.password1 === "") {
        this.errorReset.show = true;
        this.errorReset.message =
          "Por favor ingresa una nueva contraseña y repite la contraseña.";
        return false;
      }
      // if (this.resetpassword === "" || this.resetpasswordrepeat === "") {
      //   this.errorReset.show = true;
      //   this.errorReset.message = "Por favor ingresa una nueva contraseña y repite la contraseña."
      //   return false;
      // }

      // if (!this.passwordStrength(this.resetpassword)) {
      //   this.errorReset.show = true;
      //   this.errorReset.message = "Tu contraseña debe de ser de al menos 8 caracteres y contener una mayúscula, un número y un caracter especial."
      //   return false;
      // }

      // if (this.resetpassword != this.resetpasswordrepeat) {
      //   this.errorReset.show = true;
      //   this.errorReset.message = "Las contraseñas deben coincidir."
      //   return false;
      // }

      this.loading = true;
      // updatePassword(this.global.firebaseToken, this.resetpassword)
      updatePassword(this.global.firebaseToken, this.password)
        .then(() => {
          this.resetModeSent = false;
          this.loading = false;
          this.global.updateMode = false;
          // this.global.createMode = false;
          //cierro cualquier sesion ya exixtent ey lo mando al login

          //TODO permito el recargo de pagina de nuevo par aque no tenga probelma de llega ra login
          window.removeEventListener("beforeunload", this.askConfirmation);

          this.user.signOut().then(() => {
            this.$router.replace("/");
          });
        })
        .catch(err => {
          switch (err.code) {
            case 400:
              this.errorReset.message = `${err.message}`;
              break;

            default:
              this.errorReset.message =
                "Algo salió mal y no pudimos cambiar tu contraseña o es muy débil.";
              break;
          }
          this.errorReset.show = true;
          this.loading = false;
        });
    },

    //-------------------------------------------------------------------------------------------
    // HANDLE SUBMIT
    //-------------------------------------------------------------------------------------------
    handleSubmit() {
      console.log(
        "Handle submit to satisfy ternary criteria in password reset..."
      );
      return;
    },
    //-------------------------------------------------------------------------------------------
    // CLEAR INPUTS
    //-------------------------------------------------------------------------------------------
    clearInputs() {
      this.recoveryemail = null;
      this.password = null;
      this.password1 = null;
    },

    //-------------------------------------------------------------------------------------------
    // PASSWORD STRENGTH OLD CODE
    //-------------------------------------------------------------------------------------------
    passwordStrength(password) {
      // const passwordRegex = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      const passwordRegex =
        /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#=_-])[A-Za-z\d@$!%*?&#=_-]{8,}$/;
      return passwordRegex.test(password);
    },
    //
    //-------------------------------------------------------------------------------------------
    // CHECK PASSWORD VALID CHARLIES
    //-------------------------------------------------------------------------------------------
    checkPassword() {
      this.password_length = this.password.length;

      // const format = /[ !@#$%^&*()_+\-=\\"[\]{};':"\\|,.<>\\"/?]/;
      const format = /[!@#$%^&*_+()\-=[\];:|,./?]/;

      if (this.password_length >= 8) {
        this.contains_eight_characters = true;
      } else {
        this.contains_eight_characters = false;
      }

      this.contains_number = /\d/.test(this.password);
      this.contains_uppercase = /[A-Z]/.test(this.password);
      this.contains_special_character = format.test(this.password);
      this.contains_matchPasswords = this.password == this.password1;

      if (
        this.contains_eight_characters === true &&
        this.contains_special_character === true &&
        this.contains_uppercase === true &&
        this.contains_number === true &&
        this.contains_matchPasswords === true
      ) {
        this.valid_password = true;
      } else {
        this.valid_password = false;
      }
    },
    //
    //-------------------------------------------------------------------------------------------
    // CHANGE MODE AUTENTICATE CHARLIES
    //-------------------------------------------------------------------------------------------
    changeModeAutenticate(mode) {
      this.autenticateMode = mode;
    },
  },
  components: { LoadSpinner },
};
</script>

<style lang="scss" scoped>
.above {
  margin-top: calc(env(safe-area-inset-top) - (env(safe-area-inset-top) * 2));
}

.special {
  animation-name: fadeIn;
  animation-duration: 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade {
  background: rgb(246, 251, 254);
  background: linear-gradient(
    0deg,
    rgba(246, 251, 254, 1) 0%,
    rgba(246, 251, 254, 0.9107843821122199) 23%,
    rgba(246, 251, 254, 0.7203081916360294) 58%,
    rgba(246, 251, 254, 0) 100%
  );
}

/* Checkmark & Strikethrough --------- */

.is_valid {
  // color: rgba(136, 170, 141, 0.8);
  // color: #2ecc71;
  color: #afd60f;
  text-decoration: line-through;
}

.is_valid:before {
  width: 100%;
}

.checkmark_container {
  border-radius: 50%;
  position: absolute;
  top: -20px;
  right: 0;
  // background: #2ecc71;
  background: #afd60f;
  width: 50px;
  height: 50px;
  visibility: hidden;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.4s ease;
}

.show_checkmark {
  visibility: visible;
  opacity: 1;
}

.checkmark {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: white;
  stroke-width: 15;
  stroke-linecap: round;
  stroke-dasharray: 180;
  stroke-dashoffset: 180;
}

.checked {
  animation: draw 0.5s ease forwards;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

// .check-list {
//   margin: 0;
//   padding-left: 1.2rem;
// }

// .check-list li {
//   position: relative;
//   list-style-type: none;
//   padding-left: 2.5rem;
//   margin-bottom: 0.5rem;
// }

// .check-list li:before {
//     content: '';
//     display: block;
//     position: absolute;
//     left: 0;
//     top: -2px;
//     width: 5px;
//     height: 11px;
//     border-width: 0 2px 2px 0;
//     border-style: solid;
//     border-color: #00a8a8;
//     transform-origin: bottom left;
//     transform: rotate(45deg);
// }

// Biométrico
.biometric-button {
  width: 60px;
  height: 60px;
  // border-radius: 8px;
  // background-color: #186db5;
  // cursor: pointer;
  // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // margin: 0 auto;
  // margin-bottom: 15px;
}

.biometric-icon {
  width: 35px;
  height: 35px;
}

/* Estilos para tabletas en posición vertical */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  /* Agrega tus estilos aquí */
  .button__biometric {
    display: none;
  }
}

/* Estilos para tabletas en posición horizontal y pantallas más grandes */
@media screen and (min-width: 1024px) {
  /* Agrega tus estilos aquí */
  .button__biometric {
    display: none;
  }
}

/* Estilos para pantallas grandes (monitores de escritorio) */
@media screen and (min-width: 1200px) {
  /* Agrega tus estilos aquí */
  .button__biometric {
    display: none;
  }
}
</style>
