<template>
  <div class="flex p-6 pb-0 md:m-6 md:bg-med-blue md:p-4 md:py-3 rounded-xl">
    <div class="mr-3 md:hidden" v-if="brand">
      <img class="w-16" src="@/assets/png/brand.png" />
    </div>
    <router-link
      class="p-1 mr-3 cursor-pointer"
      v-if="arrow && !isBack"
      :to="arrow"
    >
      <i class="fa-solid fa-chevron-left"></i>
    </router-link>
    <div
      class="p-1 mr-3 cursor-pointer"
      v-if="isBack"
      @click="goToPreviousPage"
    >
      <i class="fa-solid fa-chevron-left"></i>
    </div>
    <div class="w-full font-bold m-auto text-lg bea__container-app-bar-title">
      <p>{{ title }}</p>
      <div v-if="date">
        <span class="text-sm text-gray-500 bea__app-bar-date"
          >SALDOS A LA FECHA:
        </span>
        <span class="text-sm text-gray-500 ml-1 bea__app-bar-date">
          {{ date }}
        </span>
      </div>
    </div>
    <div class="ml-3 md:hidden" v-if="!isBack">
      <img
        class="w-8 cursor-pointer"
        src="@/assets/png/icons/ham.png"
        @click="showMenu()"
      />
    </div>
  </div>
</template>

<script>
import { useGlobalStore } from "@/store/GlobalStore";
import { useRouter } from "vue-router";

export default {
  props: {
    date: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    brand: {
      type: Boolean,
      default: false,
    },
    lines: {
      type: Boolean,
      default: false,
    },
    arrow: {
      type: String,
      default: "",
    },
    isBack: {
      type: Boolean,
      default: false,
    },
    goBack: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const global = useGlobalStore();
    const router = useRouter();

    const showMenu = () => {
      global.showMenu = true;
    };

    const goToPreviousPage = () => {
      router.go(-1);
    };

    return {
      global,
      showMenu,
      goToPreviousPage,
    };
  },
};
</script>

<style scoped lang="scss">
.bea__container-app-bar-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Teléfonos pequeños (por ejemplo, iPhone SE) */
@media (max-width: 320px) {
  .bea__container-app-bar-title {
    justify-content: flex-start;
    align-items: start;
    flex-direction: column;
  }
  .bea__app-bar-date {
    font-size: 12px;
  }
}

/* Teléfonos medianos (por ejemplo, iPhone X, Galaxy S10) */
@media (min-width: 321px) and (max-width: 480px) {
  .bea__container-app-bar-title {
    justify-content: flex-start;
    align-items: start;
    flex-direction: column;
  }
  .bea__app-bar-date {
    font-size: 12px;
  }
}

/* Teléfonos grandes (por ejemplo, iPhone 12 Pro Max, Galaxy Note) */
@media (min-width: 481px) and (max-width: 767px) {
  .bea__container-app-bar-title {
    justify-content: flex-start;
    align-items: start;
    flex-direction: column;
  }
  .bea__app-bar-date {
    font-size: 12px;
  }
}
</style>
