export const useZohoChat = () => {
	const hideChat = () => {
		const chatElement = document.querySelector('.zsiq_floatmain');
		if (chatElement) {
			chatElement.style.display = 'none';
		}
	};

	const showChat = () => {
		const chatElement = document.querySelector('.zsiq_floatmain');
		if (chatElement) {
			chatElement.style.display = 'block';
		}
	};

	return {
		hideChat,
		showChat
	};
};