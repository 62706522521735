<template>
  <div class="main flex flex-col h-screen md:flex-row">
    <div
      class="view w-full h-full max-h-full bg-lit-blue overflow-y-scroll md:order-2 md:bg-white"
    >
      <router-view></router-view>
      <div
        class="overlay-bar bg-lit-blue w-full fixed top-0 left-0"
        v-if="global.firebaseToken"
      ></div>
    </div>
    <MenuBar v-if="global.showMenuInit"></MenuBar>
  </div>

  <!-- Splash Screen -->
  <div
    class="splash bg-lit-blue fixed w-screen h-screen left-0 top-0 flex transition-opacity duration-500 z-10"
    :class="[
      !global.fetchingInternetConnection &&
      !global.firebaseTokenLoading &&
      global.rapToken
        ? ['opacity-0', 'pointer-events-none']
        : '',
    ]"
  >
    <div class="m-auto w-1/3 text-center md:w-36">
      <img src="@/assets/png/brand.png" class="w-full" />
      <LoadSpinner class="w-1/4 mt-6 opacity-25 md:w-1/6"></LoadSpinner>
    </div>
  </div>

  <!-- Error Dialog -->
  <div
    class="error fixed bg-black/50 w-full h-full z-20 top-0 left-0 flex md:bg-black/20 md:backdrop-blur-sm"
    v-if="global.error.active && !global.error.ignore"
  >
    <div class="bg-white p-4 w-5/6 rounded-xl m-auto md:w-2/6">
      <div class="text-center font-bold text-lg mb-2">Error</div>
      <hr />
      <div class="text-red my-2 font-mono">
        {{ global.error.name }} <br />
        {{ global.error.message }}
      </div>
      <hr />
      <div class="text-xs mt-2">
        SO: {{ navigator.oscpu }}<br />
        UA: {{ navigator.userAgent }}
      </div>
    </div>
  </div>

  <!-- Connection Error -->
  <div
    class="error fixed bg-lit-blue w-full h-full z-20 top-0 left-0 flex p-4 flex-col cursor-pointer"
    v-if="!global.isConnectedToInternet && !global.fetchingInternetConnection"
    @click="retryConnection()"
  >
    <img
      src="@/assets/png/brand.png"
      class="w-32 grayscale opacity-75 m-auto mb-0"
    />
    <div class="m-auto text-center mt-2">
      Tenemos problemas conectándonos a nuestros servidores. Revisa tu conexión
      o toca para volver a intentarlo.
    </div>
  </div>

  <!-- Update Dialog -->
  <DialogRap
    v-model:visible="visibleUpdate"
    modal
    header="Solicitud"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
  >
    <p class="m-0">
      Se detecto una nueva versión del app v{{ VERSION_APP }}. Para completar la
      actualización, es necesario recargar.
    </p>
    <ButtonRap class="mt-6" @click="clearCache()">Aceptar</ButtonRap>
  </DialogRap>

  <!-- Bottom Menu Dialog -->
  <DialogRap
    v-model:visible="visibleMenuDialog"
    position="bottom"
    :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    :style="{
      width: '100vw',
      maxWidth: '100vw',
      margin: 0,
      borderRadius: '16px 16px 0 0',
      borderStyle: 'none',
      boxShadow: '0 -4px 8px rgba(0, 0, 0, 0.1)',
    }"
    :modal="false"
    :closeOnEscape="true"
    :closable="false"
    class="bottom-menu-dialog"
  >
    <template #header>
      <div class="w-full flex justify-center">
        <button
          class="pull-handle w-24 h-1.5 bg-gray-400 rounded-md cursor-pointer hover:bg-gray-500 transition-colors"
          @click="onVisibleMenuDialog"
        ></button>
      </div>
    </template>

    <div class="menu-content p-4">
      <div class="grid grid-cols-3 gap-4">
        <!-- Agencies -->
        <div class="menu-item text-center" @click="handleMenuClick('agencies')">
          <div class="icon-wrapper mb-2">
            <i class="fas fa-map-marker-alt text-2xl text-gray-600"></i>
          </div>
          <span class="text-xs">Agencias</span>
        </div>

        <!-- Contact -->
        <div class="menu-item text-center" @click="handleMenuClick('contact')">
          <div class="icon-wrapper mb-2">
            <i class="fas fa-comment text-2xl text-gray-600"></i>
          </div>
          <span class="text-xs">Contáctanos</span>
        </div>

        <!-- FAQ -->
        <div class="menu-item text-center" @click="handleMenuClick('faq')">
          <div class="icon-wrapper mb-2">
            <i class="fas fa-question-circle text-2xl text-gray-600"></i>
          </div>
          <span class="text-xs">Preguntas</span>
        </div>
      </div>
    </div>
  </DialogRap>

  <!-- Pull-up Handle - Solo visible en móvil -->
  <div class="md:hidden fixed bottom-0 w-full flex justify-center z-50">
    <button
      class="pull-handle w-24 h-1.5 mb-8 bg-gray-400 rounded-md cursor-pointer hover:bg-gray-500 transition-colors"
      @click="onVisibleMenuDialog"
    ></button>
  </div>
</template>

<script>
import { useGlobalStore } from "./store/GlobalStore";
import { useUserStore } from "./store/UserStore";
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { initializeAuth, indexedDBLocalPersistence } from "firebase/auth";
import LoadSpinner from "./components/LoadSpinner.vue";
import { Capacitor } from "@capacitor/core";
import { Network } from "@capacitor/network";
import { StatusBar, Style } from "@capacitor/status-bar";
import "./assets/icons/css/all.css";
import MenuBar from "./components/MenuBar.vue";
import { FirebaseMessaging } from "@capacitor-firebase/messaging";
import { LocalNotifications } from "@capacitor/local-notifications";
import { NativeBiometric } from "capacitor-native-biometric";
import { usePrimeVue } from "primevue/config";
import { dbStatement } from "./db/statement";
import { ref, onMounted } from "vue";
import { useZohoChat } from "./composables/useZohoChat";
import { useLog } from "./composables/useLog";
import { useRouter } from "vue-router";

export default {
  name: "App",
  components: {
    MenuBar,
    LoadSpinner,
  },

  data() {
    return {
      navigator: window.navigator,
    };
  },

  setup() {
    const router = useRouter();
    const global = useGlobalStore();
    const user = useUserStore();
    const log = useLog();
    const { hideChat, showChat } = useZohoChat();
    const localStatement = dbStatement();
    const visibleUpdate = ref(false);
    const visibleMenuDialog = ref(false);
    // const VERSION_APP = ref("1.7.3-44"); // QA
    const VERSION_APP = ref("1.7.4-2"); // PRD

    const firebaseConfig = {
      apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
      authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.VUE_APP_FIREBASE_APP_ID,
      measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
    };

    const firebase = initializeApp(firebaseConfig);
    const firestore = getFirestore();
    const firestorage = getStorage();
    const functions = getFunctions(firebase);

    if (Capacitor.isNativePlatform() && Capacitor.getPlatform() == "ios")
      StatusBar.setStyle({ style: Style.Light });

    // const fireauth = initializeAuth(firebase, {
    //   persistence: indexedDBLocalPersistence,
    // });
    const fireauth = initializeAuth(firebase, {
      persistence: indexedDBLocalPersistence,
      experimentalForceRestAPI: true,
      tenantId: null,
      settings: {
        appVerificationDisabledForTesting: true,
      },
    });

    global.firebase = firebase;
    global.firestore = firestore;
    global.functions = functions;
    global.fireauth = fireauth;
    global.firestorage = firestorage;

    const validateAppVersion = () => {
      const storedVersion = localStorage.getItem("versionApp");
      if (!storedVersion || storedVersion !== VERSION_APP.value) {
        visibleUpdate.value = true;
      }
    };

    const deleteCredential = () => {
      NativeBiometric.deleteCredentials({
        server: "https://piloto.rap.hn",
      });
      localStorage.removeItem("credentialsSet");
      localStorage.removeItem("uid");
      localStorage.removeItem("unb");
    };

    const logout = async () => {
      // agrega try catch
      const username = localStorage.getItem("saveusername") || "";
      try {
        await user.signOut();
        log.registerAuthActivity(username, true, "logout");
      } catch (error) {
        log.registerAuthActivity(username, false, "logout");
        console.log(error);
      }
    };

    const clearCache = async () => {
      await logout();
      deleteCredential();
      localStorage.clear();
      sessionStorage.clear();
      localStatement.clearAllStatements();

      if ("caches" in window) {
        try {
          const keyList = await caches.keys();
          await Promise.all(keyList.map(key => caches.delete(key)));
        } catch (error) {
          console.error("Error al eliminar el caché:", error);
        }
      }

      localStorage.setItem("versionApp", VERSION_APP.value);
      visibleUpdate.value = false;
      window.location.reload();
    };

    const changeToSpanish = () => {
      const primevue = usePrimeVue();
      primevue.config.locale.accept = "Aceptar";
      primevue.config.locale.reject = "Rechazar";
      primevue.config.locale.monthNames = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];
      primevue.config.locale.monthNamesShort = [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic",
      ];
    };

    const onVisibleMenuDialog = () => {
      visibleMenuDialog.value = !visibleMenuDialog.value;
      if (visibleMenuDialog.value) {
        hideChat();
      } else {
        showChat();
      }
    };

    const handleMenuClick = action => {
      switch (action) {
        case "agencies":
          // window.open("https://www.rap.hn/canales-de-servicio/", "_blank");
          router.push("/location");
          break;
        case "contact":
          // window.open("https://www.rap.hn/contacto/", "_blank");
          router.push("/social-media");
          break;
        case "faq":
          // window.open("https://www.rap.hn/preguntas-frecuentes/", "_blank");
          router.push("/question");
          break;
      }
      visibleMenuDialog.value = false;
    };

    onMounted(() => {
      changeToSpanish();
      validateAppVersion();
    });

    return {
      global,
      user,
      firebase,
      firestore,
      functions,
      firestorage,
      visibleUpdate,
      validateAppVersion,
      clearCache,
      VERSION_APP,
      visibleMenuDialog,
      onVisibleMenuDialog,
      handleMenuClick,
    };
  },

  created() {
    if (Capacitor.getPlatform() == "android") {
      this.initializeFirebaseMessaging();
    }

    Network.getStatus().then(result => {
      this.global.fetchingInternetConnection = false;
      this.global.isConnectedToInternet = result.connected;
    });
  },

  methods: {
    async initializeFirebaseMessaging() {
      await FirebaseMessaging.addListener("notificationReceived", event => {
        this.scheduleNotification(event.notification);
      });

      await FirebaseMessaging.addListener(
        "notificationActionPerformed",
        event => {
          console.log("notificationActionPerformed", { event });
        }
      );
    },

    async scheduleNotification(notification) {
      await LocalNotifications.schedule({
        notifications: [
          {
            title: notification.title,
            body: notification.body,
            id: 1,
            smallIcon: "ic_notification",
          },
        ],
      });
    },

    async retryConnection() {
      localStorage.setItem("exc", true);
      this.$router.go(0);
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Golos+Text:wght@400;700&display=swap");

html {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

* {
  touch-action: manipulation;
}

#app {
  font-family: "Golos Text", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .view {
    padding-top: env(safe-area-inset-top);
    padding-bottom: calc(env(safe-area-inset-bottom) * 0.5);

    .overlay-bar {
      height: env(safe-area-inset-top);
    }
  }
}

.splash {
  z-index: 50;
}

.error {
  z-index: 100;
}

.firebase-emulator-warning {
  display: none !important;
}

.bottom-menu-dialog {
  padding-bottom: env(safe-area-inset-bottom);
}

.menu-item {
  cursor: pointer;
  transition: transform 0.2s;

  &:active {
    transform: scale(0.95);
  }
}

.icon-wrapper {
  height: 70px;
  width: 70px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background-color: #f3f4f6;
}

.pull-handle:focus {
  outline: none;
}
</style>
