<script setup>
import { ref, computed } from "vue";
import axios from "axios";
import { httpsCallable } from "firebase/functions";
import { useGlobalStore } from "@/store/GlobalStore";
import { useUserStore } from "@/store/UserStore";
import SkeletonStatement from "./SkeletonStatement.vue";
// import { useDataMasking } from "@/composables/dataMasking";
import moment from "moment";

const global = useGlobalStore();
const user = useUserStore();
// const masking = useDataMasking();

const loadingPDF = ref(false);
const visibleErrorStatement = ref(false);
const visibleDialog = ref(false);
const visibleEmailDialog = ref(false);
const loadingDowlandPDF = ref(false);
const loadingStatement = ref(true);
const dateRap = ref();
const loadingDateRap = ref(false);
const periodStatement = ref();

const isValidDate = date => {
  return date instanceof Date && !isNaN(date.getTime());
};

// Computed properties para las fechas
const validatedDates = computed(() => {
  const defaultStart = new Date(2000, 0, 1);
  const defaultEnd = new Date();

  // Obtén las fechas del store
  const rangeStart = user.saldosTypoSaldo?.rangeDate?.start;
  const rangeEnd = user.saldosTypoSaldo?.rangeDate?.end;

  return {
    start: isValidDate(rangeStart) ? rangeStart : defaultStart,
    end: isValidDate(rangeEnd) ? rangeEnd : defaultEnd,
    isValid: isValidDate(rangeStart) && isValidDate(rangeEnd),
  };
});

// Computed property para determinar si mostrar el calendario
const showCalendar = computed(() => {
  return validatedDates.value.isValid;
});

const downlandPdfStatement = async () => {
  loadingDowlandPDF.value = true;

  try {
    let params = {
      data: {
        method: "downlandPDF",
        ecDataPersonal: user.ecDataPersonal,
        ecFundsResumed: user.ecFundsResumed,
        ecCommissionFunds: user.ecCommissionFunds,
        ecFundsResumedGeneralTypeFunds: user.ecFundsResumedGeneralTypeFunds,
        periodStatement: periodStatement.value,
      },
    };

    const response = await axios.post(
      `${process.env.VUE_APP_URL_API}/httpStatement`,
      params,
      {
        responseType: "blob",
      }
    );

    // Crear un objeto blob y URL para la descarga
    const blob = new Blob([response.data], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);
    const documentName = user.ecDataPersonal.identificacion || "document";

    // Crear un enlace <a> invisible y simular un click para iniciar la descarga
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = `ECA_${documentName}.pdf`;
    document.body.appendChild(a);
    a.click();

    // Limpiar el objeto URL después de la descarga
    window.URL.revokeObjectURL(url);
    loadingDowlandPDF.value = false;
    visibleDialog.value = true;
  } catch (error) {
    loadingDowlandPDF.value = false;
    console.error("Error al descargar el PDF:", error);
    visibleErrorStatement.value = true;
  }
};

const sendPdfFund = async () => {
  loadingPDF.value = true; // Indicador de carga activado
  visibleEmailDialog.value = true;

  try {
    // Ejecución de getDetailedStatement
    const dispatch = {
      value: false,
    };

    // Esperar a que getDetailedStatement se complete
    await getDetailedStatement(dispatch);

    // Verificar que getDetailedStatement se haya completado sin errores
    // if (!dispatch.value) {
    //   throw new Error("Error in getting detailed statement");
    // }

    // Obteniendo el email del usuario después de getDetailedStatement
    // const emailTest = "r.betancourth@beanar.io";
    const email = `${user.info.data.email}`;
    let params = {
      data: {
        method: "statementEmailPDF",
        ecDataPersonal: user.ecDataPersonal,
        ecFundsResumed: user.ecFundsResumed,
        ecCommissionFunds: user.ecCommissionFunds,
        ecFundsResumedGeneralTypeFunds: user.ecFundsResumedGeneralTypeFunds,
        periodStatement: periodStatement.value,
        email,
      },
    };

    // Llamada a la función httpStatementPdfSend
    const httpStatementPdfSend = httpsCallable(
      global.functions,
      "httpStatement"
    );
    await httpStatementPdfSend(params.data);

    // Indicador de carga desactivado y log de respuesta
    loadingPDF.value = false; // Indicador de carga desactivado
  } catch (error) {
    // Manejo de errores
    loadingPDF.value = false; // Indicador de carga desactivado en caso de error
    console.error("Error calling function:", error);
    visibleErrorStatement.value = true;
  }
};

// Función de reintento genérica
const retry = async (fn, retries = 3, delay = 1000) => {
  for (let i = 0; i < retries; i++) {
    try {
      return await fn(); // Ejecutar la función
    } catch (error) {
      if (i === retries - 1) throw error; // Si es el último intento, lanzar el error
      console.warn(`Reintentando... (${i + 1}/${retries})`);
      await new Promise(resolve => setTimeout(resolve, delay)); // Esperar antes de reintentar
    }
  }
};

const procesarSaldosAgrupados = (respuesta, etiquetasFondosSaldos) => {
  // Separar el total del resto de registros
  const total = respuesta.find(item => item.cod_fondo === "Total");
  const registros = respuesta.filter(item => item.cod_fondo !== "Total");

  // Agrupar registros por código de fondo
  const registrosAgrupados = registros.reduce((acc, registro) => {
    // Buscar la descripción del fondo
    const fondoEncontrado = etiquetasFondosSaldos.fondos.find(
      fondo => fondo.codigo === registro.cod_fondo
    ) || { descripcion: "Fondo No Identificado" };

    // Buscar la descripción del tipo de saldo
    const tipoSaldoEncontrado = etiquetasFondosSaldos.saldos.find(
      saldo => saldo.codigo === registro.cod_tipsaldo
    ) || { descripcion: "Tipo Saldo No Identificado" };

    // Crear el registro procesado
    const registroProcesado = {
      ...registro,
      descripcion_tipsaldo: tipoSaldoEncontrado.descripcion,
      // Formatear los valores numéricos si es necesario
      mon_saldo_ant: parseFloat(registro.mon_saldo_ant),
      mon_aportes: parseFloat(registro.mon_aportes),
      mon_rendimiento: parseFloat(registro.mon_rendimiento),
      mon_retiros: parseFloat(registro.mon_retiros),
      mon_comisiones: parseFloat(registro.mon_comisiones),
      mon_saldo_actual: parseFloat(registro.mon_saldo_actual),
      cuo_saldo_actual: parseFloat(registro.cuo_saldo_actual),
    };

    // Si el fondo no existe en el acumulador, crear nueva entrada
    if (!acc[registro.cod_fondo]) {
      acc[registro.cod_fondo] = {
        cod_fondo: registro.cod_fondo,
        descripcion_fondo: fondoEncontrado.descripcion,
        registros: [],
      };
    }

    // Agregar el registro al array de registros del fondo
    acc[registro.cod_fondo].registros.push(registroProcesado);

    return acc;
  }, {});

  // Convertir el objeto de grupos a array
  const registrosFinal = Object.values(registrosAgrupados);

  // Calcular totales por fondo
  registrosFinal.forEach(grupo => {
    grupo.totales = grupo.registros.reduce(
      (acc, reg) => ({
        mon_saldo_ant:
          parseFloat(acc.mon_saldo_ant) + parseFloat(reg.mon_saldo_ant),
        mon_aportes: parseFloat(acc.mon_aportes) + parseFloat(reg.mon_aportes),
        mon_rendimiento:
          parseFloat(acc.mon_rendimiento) + parseFloat(reg.mon_rendimiento),
        mon_retiros: parseFloat(acc.mon_retiros) + parseFloat(reg.mon_retiros),
        mon_comisiones:
          parseFloat(acc.mon_comisiones) + parseFloat(reg.mon_comisiones),
        mon_saldo_actual:
          parseFloat(acc.mon_saldo_actual) + parseFloat(reg.mon_saldo_actual),
        cuo_saldo_actual:
          parseFloat(acc.cuo_saldo_actual) + parseFloat(reg.cuo_saldo_actual),
      }),
      {
        mon_saldo_ant: "0",
        mon_aportes: "0",
        mon_rendimiento: "0",
        mon_retiros: "0",
        mon_comisiones: "0",
        mon_saldo_actual: "0",
        cuo_saldo_actual: "0",
      }
    );
  });

  return {
    registros: registrosFinal,
    total: total,
  };
};
const procesarDatosFondos = (respuesta, catalogoFondos) => {
  // Separamos el total y los demás registros
  const total = respuesta.find(item => item.cod_fondo === "Total");
  const registros = respuesta.filter(item => item.cod_fondo !== "Total");

  // Agregamos las descripciones a los registros
  const registrosConDescripcion = registros.map(registro => {
    const fondoEncontrado = catalogoFondos.find(
      fondo => fondo.codigo === registro.cod_fondo
    ) || { descripcion: "Fondo No Identificado" };

    return {
      ...registro,
      descripcion: fondoEncontrado.descripcion,
    };
  });

  return {
    registros: registrosConDescripcion,
    total: total,
  };
};

const procesarComisiones = (respuesta, catalogoFondos) => {
  // Filtramos solo las comisiones sobre saldo promedio anual
  // const comisionesFiltradas = respuesta.filter(
  //   item => item.descripcion === "% sobre el saldo promedio anual"
  // );

  // Procesamos cada comisión para agregar la descripción del fondo
  const comisionesConDescripcion = respuesta.map(comision => {
    // Removemos el 0 inicial del código de inversión
    const codigoSinCero = comision.cod_inversion.replace(/^0+/, "");

    // Buscamos el fondo correspondiente
    const fondoEncontrado = catalogoFondos.find(
      fondo => fondo.codigo === codigoSinCero
    ) || { descripcion: "Fondo No Identificado" };

    return {
      ...comision,
      descripcion2: fondoEncontrado.descripcion,
      cod_inversion: codigoSinCero, // Opcionalmente, actualizamos el código sin el 0
    };
  });

  return comisionesConDescripcion;
};

const getDataInfoGnralClient = async () => {
  try {
    const request = async () => {
      const httpAffiliateRestServices = httpsCallable(
        global.functions,
        "httpAffiliateRestServices"
      );
      const result = await httpAffiliateRestServices({
        method: "getDataInfoGnralClient",
        codClient: user.infoHash.cod_cliente,
      });

      if (result.data.response.body.ec_datos_personales) {
        user.ecDataPersonal = result.data.response.body.ec_datos_personales;
      }
    };

    await retry(request);
  } catch (error) {
    console.error("Error calling function:", error);
  }
};

const getFundsResumedGeneral = async (period = "") => {
  try {
    const request = async () => {
      const accountHash = user.infoAccount[0].cod_cuenta_ref;
      const periodHash = user.infoHash.fecha_saldo;
      const httpAffiliateRestServices = httpsCallable(
        global.functions,
        "httpAffiliateRestServices"
      );

      // Validación del period: si está vacío usa periodHash, sino usa el period proporcionado
      const periodToUse = period === "" ? periodHash : period;

      const result = await httpAffiliateRestServices({
        method: "getRestGeneralClient",
        account: accountHash,
        period: periodToUse,
      });

      if (
        result.data.response.body.ec_saldos_resumen_gral &&
        result.data.response.body.ec_saldos_resumen_gral.length > 0
      ) {
        user.ecFundsResumed = procesarDatosFondos(
          result.data.response.body.ec_saldos_resumen_gral,
          user.etiquetasFondosSaldos.fondos
        );
      }
    };
    await retry(request);
  } catch (error) {
    console.error("Error calling function:", error);
  }
};

const getECcommissionFunds = async () => {
  try {
    const request = async () => {
      const httpAffiliateRestServices = httpsCallable(
        global.functions,
        "httpAffiliateRestServices"
      );
      const result = await httpAffiliateRestServices({
        method: "getInfoCommission",
        codUser: "",
        codClient: user.infoHash.cod_cliente,
      });

      if (result.data.response.body.ec_comisiones_x_fondo) {
        user.ecCommissionFunds = procesarComisiones(
          result.data.response.body.ec_comisiones_x_fondo,
          user.etiquetasFondosSaldos.fondos
        );
      }
    };

    await retry(request);
  } catch (error) {
    console.error("Error calling function:", error);
  }
};

const getECfundsResumedGeneralTypeFunds = async (period = "") => {
  try {
    const request = async () => {
      const accountHash = user.infoAccount[0].cod_cuenta_ref;
      const periodHash = user.infoHash.fecha_saldo;
      const fechaRangoInicial = user.infoHash.fecha_rango_inicial;
      const fechaRangoFinal = user.infoHash.fecha_rango_final;
      const httpAffiliateRestServices = httpsCallable(
        global.functions,
        "httpAffiliateRestServices"
      );

      const periodToUse = period === "" ? periodHash : period;

      const result = await httpAffiliateRestServices({
        method: "getFundRestTypeBalance",
        account: accountHash,
        period: periodToUse,
        dateStart: fechaRangoInicial,
        dateEnd: fechaRangoFinal,
      });

      if (
        result.data.response.body.ec_saldos_resumen_gral_fndo_tipsaldo &&
        result.data.response.body.ec_saldos_resumen_gral_fndo_tipsaldo.length >
          0
      ) {
        user.ecFundsResumedGeneralTypeFunds = procesarSaldosAgrupados(
          result.data.response.body.ec_saldos_resumen_gral_fndo_tipsaldo,
          user.etiquetasFondosSaldos
        );

        user.saldosTypoSaldo.rangeDate = convertDateRange(
          result.data.fecha_rango_inicial,
          result.data.fecha_rango_final
        );
      }

      if (periodToUse.includes("-")) {
        periodStatement.value = periodToUse;
      } else {
        periodStatement.value = user.infoHash.fecha_saldo_des;
      }
    };
    await retry(request);
  } catch (error) {
    console.error("Error calling function:", error);
  } finally {
    loadingStatement.value = false;
  }
};

// const getDetailedStatement = async e => {
//   if (e.value) {
//     return;
//   }

//   try {
//     await getDataInfoGnralClient();
//     await getFundsResumedGeneral();
//     await getECcommissionFunds();
//     await getECfundsResumedGeneralTypeFunds();
//   } catch (error) {
//     console.error("Ocurrió un error al cargar los datos:", error);
//   }
// };

// const formatCurrency = valor => {
//   if (valor === "Cargando...") {
//     return "Cargando...";
//   }

//   if (valor === null || valor === undefined) {
//     return "Valor no disponible";
//   }

//   // Intentar convertir a número si es una cadena
//   if (typeof valor === "string") {
//     valor = parseFloat(valor.replace(/,/g, ""));
//     if (isNaN(valor)) {
//       return "Valor inválido";
//     }
//   }

//   // Verificar si el valor es un número
//   if (typeof valor !== "number" || isNaN(valor)) {
//     return "Valor inválido";
//   }

//   // Formatear el número como moneda Lempira
//   return valor.toLocaleString("es-HN", {
//     style: "currency",
//     currency: "HNL",
//   });
// };

const getDetailedStatement = async e => {
  if (e.value) {
    return;
  }

  try {
    loadingStatement.value = true;

    // Obtener información general del cliente
    const clientInfo = await retry(async () => {
      const result = await getDataInfoGnralClient();
      if (!user.ecDataPersonal) {
        throw new Error("No se pudo obtener la información del cliente");
      }
      return result;
    });

    // Obtener resumen de fondos
    const fundsInfo = await retry(async () => {
      const result = await getFundsResumedGeneral();
      if (!user.ecFundsResumed) {
        throw new Error("No se pudo obtener el resumen de fondos");
      }
      return result;
    });

    // Obtener comisiones de fondos
    const commissionsInfo = await retry(async () => {
      const result = await getECcommissionFunds();
      if (!user.ecCommissionFunds) {
        throw new Error("No se pudo obtener las comisiones de fondos");
      }
      return result;
    });

    // Obtener resumen general por tipo de fondos
    const typeFundsInfo = await retry(async () => {
      const result = await getECfundsResumedGeneralTypeFunds();
      if (!user.ecFundsResumedGeneralTypeFunds) {
        throw new Error("No se pudo obtener el resumen por tipo de fondos");
      }
      return result;
    });

    return {
      clientInfo,
      fundsInfo,
      commissionsInfo,
      typeFundsInfo,
    };
  } catch (error) {
    console.error("Error en getDetailedStatement:", error.message);
    visibleErrorStatement.value = true;
    throw error;
  } finally {
    loadingStatement.value = false;
  }
};

const formatCurrency = (valor, symbol = "", total = false) => {
  if (valor === "Cargando...") {
    return "Cargando...";
  }
  if (valor === null || valor === undefined) {
    return "Valor no disponible";
  }
  // Intentar convertir a número si es una cadena
  if (typeof valor === "string") {
    valor = parseFloat(valor.replace(/,/g, ""));
    if (isNaN(valor)) {
      return "Valor inválido";
    }
  }
  // Verificar si el valor es un número
  if (typeof valor !== "number" || isNaN(valor)) {
    return "Valor inválido";
  }

  // Formatear con lógica especial para total
  let formattedValue;
  if (total) {
    // Convertir a string para analizar los decimales
    const valorString = valor.toString();
    const [integerPart, decimalPart] = valorString.split(".");

    // Si tiene decimales y más de 4
    if (decimalPart && decimalPart.length > 4) {
      formattedValue = parseFloat(valorString.slice(0, integerPart.length + 5));
    } else {
      // Si tiene 4 o menos decimales, dejarlo como está
      formattedValue = valor;
    }
  } else {
    formattedValue = valor;
  }

  // Separar la parte entera de los decimales
  const [integerPart, decimalPart] = formattedValue.toString().split(".");

  // Formatear la parte entera con comas para los miles
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Reconstruir el número
  const formattedNumber = decimalPart
    ? `${formattedInteger}.${decimalPart}`
    : formattedInteger;

  if (symbol) {
    return `${symbol} ${formattedNumber}`;
  }
  return `${formattedNumber}`;
};

const convertDateRange = (start, end) => {
  // Configurar Moment.js para analizar fechas en español

  moment.locale("es");

  // Convertir las fechas de string a objetos moment
  const startDate = moment(start, "DD-MM-YYYY");
  const endDate = moment(end, "DD-MM-YYYY");

  // Verificar si las fechas son válidas
  if (!startDate.isValid() || !endDate.isValid()) {
    throw new Error("Una o ambas fechas no son válidas");
  }

  // Sumar un mes a la fecha de finalización
  const newEndDate = endDate.add(1, "month");

  return {
    start: startDate.toDate(),
    end: newEndDate.toDate(),
  };
};

const getSearchEC = async period => {
  loadingDateRap.value = true;
  try {
    const periodEC = getLastDayOfMonth(period);

    await getFundsResumedGeneral(periodEC);
    await getECfundsResumedGeneralTypeFunds(periodEC);
  } catch (error) {
    console.error("Ocurrido un error al cargar los datos:", error);
  } finally {
    loadingDateRap.value = false;
  }
};

const getLastDayOfMonth = date => {
  // Crear un objeto moment a partir de la fecha proporcionada
  const momentDate = moment(date);

  // Obtener el último día del mes
  const lastDay = momentDate.endOf("month");

  // Formatear la fecha como DD-MM-YYYY
  return lastDay.format("DD-MM-YYYY");
};
</script>

<template>
  <DialogRap
    v-model:visible="visibleErrorStatement"
    modal
    header="Solicitud Rechazada"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
  >
    <p class="m-0">
      La información de tu estado cuenta no esta completa o no se encuentra
      disponible, por favor inténtalo más tarde.
    </p>
    <ButtonRap class="mt-6" @click="visibleErrorStatement = false"
      >Aceptar</ButtonRap
    >
  </DialogRap>
  <div class="button__sendpdf">
    <div
      style="
        width: 95%;
        display: flex;
        justify-content: center;
        /* background-color: #afd60f; */
        padding: 10px;
        border-radius: 10px;
      "
    >
      <ButtonRap
        :loading="loadingPDF"
        @click="sendPdfFund"
        type="button"
        icon="pi"
        label="Solicitar Estado de Cuenta"
        style="width: 100%; height: 60px"
      ></ButtonRap>
    </div>
  </div>
  <FieldsetRap
    legend="Estado de Cuenta"
    :toggleable="true"
    collapsed
    @toggle="
      e => {
        getDetailedStatement(e);
      }
    "
    class="statement__movil"
  >
    <SkeletonStatement
      v-if="
        user.ecFundsResumedGeneralTypeFunds.total.cuo_saldo_actual ==
        'Cargando...'
      "
    />
    <div class="container" v-else>
      <DialogRap
        v-model:visible="visibleDialog"
        modal
        header="Descargas"
        :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
      >
        <p class="m-0">Se descargo exitosamente su estado de cuenta</p>
        <ButtonRap class="mt-6" @click="visibleDialog = false"
          >Aceptar</ButtonRap
        >
      </DialogRap>
      <DialogRap
        v-model:visible="visibleEmailDialog"
        modal
        header="Solicitud"
        :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
      >
        <p class="m-0">
          Tu solicitud esta en proceso, se enviará un correo con tu solicitud a:
          {{ user.info.data.email }}
        </p>
        <ButtonRap class="mt-6" @click="visibleEmailDialog = false"
          >Cerrar</ButtonRap
        >
      </DialogRap>
      <div class="section">
        <p style="font-size: 20px; font-weight: 600; opacity: 0.6">
          PERÍODO: {{ periodStatement }}
        </p>

        <div style="width: 50%; height: 50px">
          <div style="display: flex" v-if="!showCalendar">
            <CalendarRap
              view="month"
              dateFormat="mm/yy"
              style="width: 100%; margin-bottom: 10px"
              disabled
              touchUI
            />
            <ButtonRap
              style="margin-left: 10px; width: 70px; margin-bottom: 15px"
              :disabled="!dateRap"
              :loading="loadingDateRap"
              type="button"
              icon="pi pi-search"
            >
            </ButtonRap>
          </div>
          <div style="display: flex" v-else>
            <CalendarRap
              v-model="dateRap"
              view="month"
              dateFormat="mm/yy"
              style="width: 100%; margin-bottom: 10px"
              :maxDate="user.saldosTypoSaldo?.rangeDate?.end || new Date()"
              :minDate="
                user.saldosTypoSaldo?.rangeDate?.start || new Date(2000, 0, 1)
              "
              touchUI
            />
            <ButtonRap
              style="margin-left: 10px; width: 70px; margin-bottom: 15px"
              @click="getSearchEC(dateRap)"
              :disabled="!dateRap"
              :loading="loadingDateRap"
              type="button"
              icon="pi pi-search"
            >
            </ButtonRap>
          </div>
        </div>
        <div class="section-title">
          <h1>RESUMEN GENERAL</h1>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/beanrap-dev.appspot.com/o/media%2Fimages%2Flogo_blue.png?alt=media&token=f4c94361-d412-4027-a99e-f6220406d32b"
            alt="Info"
          />
          <ButtonRap
            :loading="loadingDowlandPDF"
            @click="downlandPdfStatement()"
            type="button"
            icon="download"
            label="Descargar Estado de Cuenta"
            style="position: absolute; right: 0; top: 0; margin: 10px"
            :disabled="loadingStatement"
          ></ButtonRap>
        </div>
        <div
          class="summary"
          v-for="s in user.ecFundsResumed.registros"
          :key="s"
        >
          <div>
            <h2>Nombre</h2>
            <h1>
              {{ s.descripcion }}
            </h1>
          </div>
          <div>
            <h2>Saldo Anterior</h2>
            <h1>{{ formatCurrency(s.mon_saldo_ant, "L") }}</h1>
          </div>
          <div>
            <h2>Aporte</h2>
            <h1>{{ formatCurrency(s.mon_aportes, "L") }}</h1>
          </div>

          <div>
            <h2>Retiros</h2>
            <h1>{{ formatCurrency(s.mon_retiros, "L") }}</h1>
          </div>
          <div>
            <h2>Comisiones</h2>
            <h1>{{ formatCurrency(s.mon_comisiones, "L") }}</h1>
          </div>
          <div>
            <h2>Rendimiento</h2>
            <h1>{{ formatCurrency(s.mon_rendimiento, "L") }}</h1>
          </div>
          <div>
            <h2>Saldo Actual</h2>
            <h1>{{ formatCurrency(s.mon_saldo_actual, "L") }}</h1>
          </div>
          <div>
            <h2>Cantidad de cuotas</h2>
            <h1>{{ formatCurrency(s.cuo_saldo_actual) }}</h1>
          </div>
          <div>
            <h2>Valor de cuotas</h2>
            <h1>{{ formatCurrency(s.valor_cuota) }}</h1>
          </div>
          <div>
            <h2>% Rendimiento</h2>
            <h1>{{ parseFloat(s.rentabilidad) }}%</h1>
          </div>
        </div>
      </div>

      <div class="section">
        <div class="summary__total">
          <div>
            <h2>TOTAL</h2>
          </div>
          <div>
            <h1>
              {{
                formatCurrency(
                  user.ecFundsResumed.total.mon_saldo_ant,
                  "L",
                  true
                )
              }}
            </h1>
          </div>
          <div>
            <h1>
              {{
                formatCurrency(user.ecFundsResumed.total.mon_aportes, "L", true)
              }}
            </h1>
          </div>
          <div>
            <h1>
              {{
                formatCurrency(user.ecFundsResumed.total.mon_retiros, "L", true)
              }}
            </h1>
          </div>
          <div>
            <h1>
              {{
                formatCurrency(
                  user.ecFundsResumed.total.mon_comisiones,
                  "L",
                  true
                )
              }}
            </h1>
          </div>
          <div>
            <h1>
              {{
                formatCurrency(
                  user.ecFundsResumed.total.mon_rendimiento,
                  "L",
                  true
                )
              }}
            </h1>
          </div>
          <div>
            <h1>
              {{
                formatCurrency(
                  user.ecFundsResumed.total.mon_saldo_actual,
                  "L",
                  true
                )
              }}
            </h1>
          </div>
          <div>
            <h1></h1>
          </div>
          <div>
            <h1></h1>
          </div>
          <div>
            <h1></h1>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="section-title">
          <h1>COBRO POR COMISIÓN POR ADMINISTRACIÓN DE FONDO</h1>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/beanrap-dev.appspot.com/o/media%2Fimages%2Flogo_black.png?alt=media&token=ac09eb1d-478e-4cda-b0d3-a893a0f0f692"
            alt="Account"
          />
        </div>
        <div class="summary__comision">
          <div v-for="s in user.ecCommissionFunds" :key="s">
            <h2>{{ s.descripcion2 }}</h2>
            <!-- <h1>{{ s.porc_comision }}{{ s.descripcion }}</h1> -->
            <h1>{{ `${s.porc_comision} ${s.descripcion}` }}</h1>
          </div>
        </div>
      </div>
      <div class="section-title" style="margin-top: 15px">
        <h1>RESUMEN GENERAL POR TIPO DE FONDO</h1>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/beanrap-dev.appspot.com/o/media%2Fimages%2Flogo_green.png?alt=media&token=ac3e96b8-fcc3-4a52-ad7f-e6fafaf6e9e0"
          alt="Account"
        />
      </div>
      <div
        class="section__out"
        style="
          display: flex;
          justify-content: center;
          flex-direction: column;
          border: 2px solid #65d8f1;
          border-radius: 5px;
          padding: 0px 20px 20px 20px;
          margin-top: 20px;
        "
      >
        <div class="section">
          <div class="summary__fondo">
            <div>
              <h2>ORIGEN DE FONDOS</h2>
            </div>
            <div>
              <h2>SALDO ANTERIOR</h2>
            </div>
            <div>
              <h2>APORTE</h2>
            </div>
            <div>
              <h2>RENDIMIENTO</h2>
            </div>
            <div>
              <h2>RETIROS</h2>
            </div>
            <div>
              <h2>COMISIONES</h2>
            </div>
            <!-- <div>
              <h2>CUOTA ACTUAL</h2>
            </div> -->
            <div>
              <h2>SALDO ACTUAL</h2>
            </div>
          </div>
        </div>
        <div
          class="section"
          v-for="fondo in user.ecFundsResumedGeneralTypeFunds.registros"
          :key="fondo.cod_fondo"
        >
          <div class="section-title__sub">{{ fondo.descripcion_fondo }}</div>
          <div
            class="summary__fondo__sub"
            v-for="registro in fondo.registros"
            :key="registro.cod_tipsaldo"
          >
            <div>
              <h1>{{ registro.descripcion_tipsaldo }}</h1>
            </div>
            <div>
              <h1>{{ formatCurrency(registro.mon_saldo_ant, "L") }}</h1>
            </div>
            <div>
              <h1>{{ formatCurrency(registro.mon_aportes, "L") }}</h1>
            </div>
            <div>
              <h1>{{ formatCurrency(registro.mon_rendimiento, "L") }}</h1>
            </div>
            <div>
              <h1>{{ formatCurrency(registro.mon_retiros, "L") }}</h1>
            </div>
            <div>
              <h1>{{ formatCurrency(registro.mon_comisiones, "L") }}</h1>
            </div>
            <!-- <div>
              <h1>{{ formatCurrency(registro.cuo_saldo_actual, "L") }}</h1>
            </div> -->
            <div>
              <h1>{{ formatCurrency(registro.mon_saldo_actual, "L") }}</h1>
            </div>
          </div>

          <!-- Total por fondo -->
          <div class="summary__fondo" style="margin-top: 15px">
            <div>
              <h2>TOTAL FONDO</h2>
            </div>
            <div>
              <h2>
                {{ formatCurrency(fondo.totales.mon_saldo_ant, "L", true) }}
              </h2>
            </div>
            <div>
              <h2>
                {{ formatCurrency(fondo.totales.mon_aportes, "L", true) }}
              </h2>
            </div>
            <div>
              <h2>
                {{ formatCurrency(fondo.totales.mon_rendimiento, "L", true) }}
              </h2>
            </div>
            <div>
              <h2>
                {{ formatCurrency(fondo.totales.mon_retiros, "L", true) }}
              </h2>
            </div>
            <div>
              <h2>
                {{ formatCurrency(fondo.totales.mon_comisiones, "L", true) }}
              </h2>
            </div>
            <!-- <div>
              <h2>{{ formatCurrency(fondo.totales.cuo_saldo_actual, "L", true) }}</h2>
            </div> -->
            <div>
              <h2>
                {{ formatCurrency(fondo.totales.mon_saldo_actual, "L", true) }}
              </h2>
            </div>
          </div>
        </div>
        <!-- <div class="section" v-for="d in resultado.registros" :key="d">
          <div class="section-title__sub">d.descripcion_fondo</div>
          <div class="summary__fondo__sub" v-for="f in d" :key="f">
            <div>
              <h1>{{f.descripcion_tipsaldo}}</h1>
            </div>
            <div>
              <h1>{{}}</h1>
            </div>
            <div>
              <h1>{{}}</h1>
            </div>
            <div>
              <h1>{{}}</h1>
            </div>
            <div>
              <h1>{{}}</h1>
            </div>
            <div>
              <h1>{{}}</h1>
            </div>
            <div>
              <h1>{{}}</h1>
            </div>
            <div>
              <h1>{{}}</h1>
            </div>
          </div>

          <div
            class="summary__fondo"
            style="margin-top: 15px"
            v-for="group in sumarValoresPorFondo(d)"
            :key="group.FONDO_CODIGO"
          >
            <div>
              <h2>TOTAL FONDO</h2>
            </div>
            <div>
              <h2>{{ group.SALDO_ANTERIOR }}</h2>
            </div>
            <div>
              <h2>{{ group.APORTES_BRUTOS }}</h2>
            </div>
            <div>
              <h2>{{ group.INTERESES }}</h2>
            </div>
            <div>
              <h2>{{ group.RETIROS }}</h2>
            </div>
            <div>
              <h2>{{ group.COMISIONES }}</h2>
            </div>
            <div>
              <h2>{{ group.SEGUROS }}</h2>
            </div>
            <div>
              <h2>{{ group.SALDO_ACTUAL }}</h2>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </FieldsetRap>
</template>

<style scoped>
.container {
  background-color: #f2f2f2;
  padding: 20px;
  position: relative;
  margin: 0 auto;
}
.header {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 10px;
  padding: 10px;
}
.header img {
  width: 100px;
}
.title {
  text-align: start;
}
.title h1 {
  font-size: 42px;
  margin: 0;
  font-weight: 900;
  opacity: 0.7;
}
.title h3 {
  font-size: 14px;
  margin: 0;
  opacity: 0.5;
  font-weight: 800;
}
.date {
  display: flex;
  align-items: center;
  background-color: rgba(255, 151, 0, 0.2);
  border: 2px solid #ff9700;
  padding: 10px;
  border-radius: 5px;
}
.date img {
  width: 30px;
  margin-right: 10px;
}
.date h1 {
  margin: 0;
  font-size: 12px;
  font-weight: 800;
  opacity: 0.8;
}
.date h2 {
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  opacity: 0.5;
}
.section {
  margin-top: 20px;
}
.section-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.section-title h1 {
  font-size: 20px;
  margin: 0;
  font-weight: 800;
  opacity: 0.6;
}
.section-title img {
  width: 30px;
  margin-left: 10px;
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
}
.card {
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 151, 0, 0.1);
  border: 2px solid #ff9700;
  border-radius: 5px;
  padding: 10px;
}
.card h1 {
  font-size: 14px;
  font-weight: 300;
  opacity: 0.7;
  margin: 6px 0px 0px 0px;
}
.card h2 {
  margin: 0;
  font-size: 12px;
  font-weight: 300;
  opacity: 0.5;
}
.summary {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  border: 2px solid #65d8f1;
  border-radius: 5px;
  background-color: #ffffff;
  margin-bottom: 10px;
}
.summary div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 15px;
}
.summary h2 {
  font-size: 12px;
  margin: 0;
  font-weight: 300;
  opacity: 0.5;
}

.summary h1 {
  font-size: 12px;
  margin: 0;
  font-weight: 300;
  opacity: 0.7;
}

.summary__total {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  border: 2px solid #65d8f1;
  border-radius: 5px;
  background-color: #65d8f1;
}
.summary__total div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 15px;
}
.summary__total h2 {
  font-size: 12px;
  margin: 0;
  font-weight: 300;
  opacity: 0.5;
}

.summary__total h1 {
  font-size: 12px;
  margin: 0;
  font-weight: 300;
  opacity: 0.7;
}

.summary__comision {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  border-radius: 5px;
  gap: 20px;
}
.summary__comision div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 15px;
  border: 1px solid #000000;
  border-radius: 5px;
}
.summary__comision h2 {
  font-size: 12px;
  margin: 0;
  font-weight: 300;
  opacity: 0.5;
}

.summary__comision h1 {
  font-size: 12px;
  margin: 0;
  font-weight: 300;
  opacity: 0.7;
}

.summary__fondo {
  display: grid;
  grid-template-columns: 200px repeat(auto-fit, minmax(100px, 1fr));
  border: 2px solid #0165ba;
  border-radius: 5px;
  background-color: #0165ba;
  color: #fff;
}
.summary__fondo div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 15px;
}
.summary__fondo h2 {
  font-size: 10px;
  margin: 0;
  font-weight: 500;
  opacity: 1;
}

.summary__fondo h1 {
  font-size: 10px;
  margin: 0;
  font-weight: 300;
  opacity: 0.7;
}

.summary__fondo__sub {
  display: grid;
  grid-template-columns: 200px repeat(auto-fit, minmax(100px, 1fr));
  border-radius: 5px;
}
.summary__fondo__sub div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px 15px 0px 15px;
}
.summary__fondo__sub h2 {
  font-size: 12px;
  margin: 0;
  font-weight: 300;
  opacity: 0.5;
}

.summary__fondo__sub h1 {
  font-size: 12px;
  margin: 0;
  font-weight: 300;
  opacity: 0.7;
  margin-top: 5px;
}

.section-title__sub {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.section-title__sub h1 {
  font-size: 15px;
  margin: 0;
  font-weight: 300;
  padding: 0px 15px 0px 15px;
}

/* Teléfonos pequeños (por ejemplo, iPhone SE) */
@media (max-width: 320px) {
  .statement__movil {
    display: none;
  }
}

/* Teléfonos medianos (por ejemplo, iPhone X, Galaxy S10) */
@media (min-width: 321px) and (max-width: 480px) {
  .statement__movil {
    display: none;
  }
}

/* Teléfonos grandes (por ejemplo, iPhone 12 Pro Max, Galaxy Note) */
@media (min-width: 481px) and (max-width: 767px) {
  .statement__movil {
    display: none;
  }
}
</style>
