<template>
  <div class="account-statement-skeleton">
    <!-- Header con título y botón de descarga -->
    <div class="section">
      <div class="section-title">
        <div class="skeleton-text h-8 w-48"></div>
        <div class="skeleton-image h-8 w-8 ml-2"></div>
        <div
          class="skeleton-button h-10 w-48 absolute right-0 top-0 mb-4 mr-4"
        ></div>
      </div>
    </div>

    <!-- Resumen General Grid -->
    <div class="section">
      <div class="summary">
        <div v-for="i in 1" :key="`summary-${i}`" class="summary-item">
          <div class="skeleton-text h-3 w-full mb-2"></div>
          <div class="skeleton-text h-4 w-full"></div>
        </div>
      </div>
    </div>

    <!-- Totales -->
    <div class="section">
      <div class="summary__total">
        <div v-for="i in 1" :key="`total-${i}`" class="total-item">
          <div class="skeleton-text h-4 w-full"></div>
        </div>
      </div>
    </div>

    <!-- Sección de Comisiones -->
    <div class="section">
      <div class="section-title">
        <div class="skeleton-text h-6 w-72"></div>
        <div class="skeleton-image h-6 w-6 ml-2"></div>
      </div>
      <div class="summary__comision">
        <div v-for="i in 3" :key="`comision-${i}`" class="comision-item">
          <div class="skeleton-text h-3 w-full mb-2"></div>
        </div>
      </div>
    </div>

    <!-- Resumen por Tipo de Fondo -->
    <div class="section-title mt-4">
      <div class="skeleton-text h-6 w-72"></div>
      <div class="skeleton-image h-6 w-6 ml-2"></div>
    </div>

    <div class="section__out">
      <!-- Headers de Fondo -->
      <div class="section">
        <div class="summary__fondo">
          <div v-for="i in 1" :key="`header-${i}`" class="header-item">
            <div class="skeleton-text h-3 w-full"></div>
          </div>
        </div>
      </div>

      <!-- Detalles de Fondos (2 ejemplos) -->
      <div v-for="fund in 2" :key="`fund-${fund}`" class="section">
        <div class="section-title__sub">
          <div class="skeleton-text h-4 w-48 mb-4"></div>
        </div>
        <!-- Filas de detalles -->
        <div class="summary__fondo__sub mb-4">
          <div v-for="i in 1" :key="`fund-detail-${fund}-${i}`">
            <div class="skeleton-text h-3 w-full"></div>
          </div>
        </div>
        <!-- Total por fondo -->
        <div class="summary__fondo mt-4">
          <div v-for="i in 1" :key="`fund-total-${fund}-${i}`">
            <div class="skeleton-text h-3 w-full"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccountStatementSkeleton",
};
</script>

<style scoped>
.account-statement-skeleton {
  background-color: #f2f2f2;
  padding: 20px;
  position: relative;
  margin: 0 auto;
}

.section {
  margin-top: 20px;
}

.section-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
}

.summary {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  border: 2px solid #65d8f1;
  border-radius: 5px;
  background-color: #ffffff;
  margin-bottom: 10px;
}

.summary-item {
  padding: 15px;
}

.summary__total {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  border: 2px solid #65d8f1;
  border-radius: 5px;
  background-color: #65d8f1;
  padding: 15px;
}

.summary__comision {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  border-radius: 5px;
  gap: 20px;
}

.comision-item {
  border: 1px solid #000000;
  border-radius: 5px;
  padding: 15px;
}

.summary__fondo {
  display: grid;
  grid-template-columns: 200px repeat(auto-fit, minmax(100px, 1fr));
  border: 2px solid #0165ba;
  border-radius: 5px;
  background-color: #0165ba;
  color: #fff;
  padding: 15px;
}

.summary__fondo__sub {
  display: grid;
  grid-template-columns: 200px repeat(auto-fit, minmax(100px, 1fr));
  padding: 0 15px;
}

.section__out {
  border: 2px solid #65d8f1;
  border-radius: 5px;
  padding: 0 20px 20px 20px;
  margin-top: 20px;
}

.section-title__sub {
  padding: 0 15px;
}

/* Skeleton animation styles */
@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}

.skeleton-text {
  background-color: #e2e8f0;
  border-radius: 4px;
  animation: pulse 1.5s infinite ease-in-out;
}

.skeleton-image {
  background-color: #e2e8f0;
  border-radius: 50%;
  animation: pulse 1.5s infinite ease-in-out;
}

.skeleton-button {
  background-color: #e2f296;
  border-radius: 4px;
  animation: pulse 1.5s infinite ease-in-out;
}

/* Responsive styles */
@media (max-width: 768px) {
  .summary__fondo,
  .summary__fondo__sub {
    grid-template-columns: 1fr;
  }

  .summary__comision {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .account-statement-skeleton {
    padding: 10px;
  }
}
</style>
