import { createRouter, createWebHistory } from "vue-router";
import { useGlobalStore } from "../store/GlobalStore";
import { useUserStore } from "../store/UserStore";
import { App } from "@capacitor/app";

import LoginView from "../views/Account/LoginView.vue";
import AccountView from "../views/AccountView.vue";
import OverviewView from "../views/Account/OverviewView.vue";
import LoansView from "../views/LoansView.vue";

const routes = [
  {
    path: "/statement",
    name: "statement",
    component: () => import("../views/StatementView.vue"),
    beforeEnter: routerGuardHome
  },


  {
    path: "/social-media",
    name: "SocialMedia",
    component: () => import("../views/Information/ContactView.vue"),
    beforeEnter: routerGuardPublic,
  },

  {
    path: "/location",
    name: "Location",
    component: () => import("../views/Information/LocationView.vue"),
    beforeEnter: routerGuardPublic,
  },

  {
    path: "/question",
    name: "Question",
    component: () => import("../views/Information/QuestionView.vue"),
    beforeEnter: routerGuardPublic,
  },
  // --------------------------------------------------------------------------------------------
  // LOGIN
  // --------------------------------------------------------------------------------------------
  {
    path: "/",
    name: "home",
    // component: () => import("../views/Account/LoginView.vue"),
    component: LoginView,
    beforeEnter: routerGuardHome,
  },
  {
    path: "/reset",
    name: "ResetPasswordMobile",
    component: () => import("../views/Account/ResetPasswordMobile.vue"),
    beforeEnter: routerGuardHome,
  },
  // {
  //   path: '/reset/:oobCode/:apiKey',
  //   name: 'ResetPasswordMobile',
  //   component: () => import('../views/Account/ResetPasswordMobile.vue'),
  //   beforeEnter : routerGuardHome,
  // },
  {
    path: "/terms",
    name: "Terms",
    beforeEnter: routerGuardHome,
    component: () => import("../components/TermsAndConditions.vue"),
  },
  {
    path: "/login",
    redirect: () => {
      return "/";
    },
  },
  // --------------------------------------------------------------------------------------------
  // RECOVERY
  // --------------------------------------------------------------------------------------------
  /*{
        path: '/recovery',
        name: 'recovery',
        beforeEnter : routerNoGuard,
        component: () => import('../views/Account/RecoveryView.vue'),
      },*/

  // --------------------------------------------------------------------------------------------
  // ACCOUNT
  // --------------------------------------------------------------------------------------------
  {
    path: "/account",
    name: "account",
    redirect: "/account/overview",
    beforeEnter: routerGuardGeneral,
    // component: () => import("../views/AccountView.vue"),
    component: AccountView,
    children: [
      {
        path: "overview",
        name: "account-overview",
        beforeEnter: routerGuardGeneral,
        // component: () => import("../views/Account/OverviewView.vue"),
        component: OverviewView
      },

      {
        path: "overview/:account",
        name: "account-overview-account",
        beforeEnter: routerGuardGeneral,
        // component: () => import("../views/Account/OverviewView.vue"),
        component: OverviewView
      },

      {
        path: "fund/:account/:name/:typecode/",
        name: "account-fund",
        beforeEnter: routerGuardGeneral,
        component: () => import("../views/Account/LegacyFundView.vue"),
      },
      {
        path: "fund/:typecode/",
        name: "account-fund-typecode",
        beforeEnter: routerGuardGeneral,
        component: () => import("../views/Account/FundView.vue"),
      },
      // {
      //   path: 'fund/:account/:name',
      //   name: 'account-fund',
      //   beforeEnter : routerGuardGeneral,
      //   component: () => import('../views/Account/FundView.vue'),
      // },

      {
        path: "history",
        name: "account-history",
        beforeEnter: routerGuardGeneral,
        component: () => import("../views/Account/HistoryView.vue"),
      },

      {
        path: "history/:company/:local",
        name: "account-history-single",
        beforeEnter: routerGuardGeneral,
        component: () => import("../views/Account/CompanyView.vue"),
      },
    ],
  },

  // --------------------------------------------------------------------------------------------
  // INFORMATION
  // --------------------------------------------------------------------------------------------
  {
    path: "/info",
    name: "info",
    redirect: "/info/general",
    beforeEnter: routerGuardGeneral,
    component: () => import("../views/InformationView.vue"),
    children: [
      {
        path: "general",
        name: "info-general",
        beforeEnter: routerGuardGeneral,
        component: () => import("../views/Information/GeneralView.vue"),
      },

      {
        path: "agency/:id",
        name: "info-agency",
        beforeEnter: routerGuardGeneral,
        component: () => import("../views/Information/AgencyView.vue"),
      },

      {
        path: "work-with-us",
        name: "info-work",
        beforeEnter: routerGuardGeneral,
        component: () => import("../views/Information/WorkWithUsView.vue"),
      },
    ],
  },

  // --------------------------------------------------------------------------------------------
  // LOANS
  // --------------------------------------------------------------------------------------------
  {
    path: "/loans",
    name: "loans",
    redirect: "/loans/overview",
    beforeEnter: routerGuardGeneral,
    // component: () => import("../views/LoansView.vue"),
    component: LoansView,
    children: [
      {
        path: "overview",
        name: "loans-overview",
        beforeEnter: routerGuardGeneral,
        component: () => import("../views/Loans/OverviewView.vue"),
      },

      {
        path: ":id",
        name: "loans-single",
        beforeEnter: routerGuardGeneral,
        component: () => import("../views/Loans/SingleView.vue"),
      },
      {
        path: "request",
        name: "loanRequest",
        // beforeEnter: routerGuardGeneral,
        component: () => import("../views/Loans/RequestView.vue"),
      },
      {
        path: "calculate",
        name: "loanCalculate",
        // beforeEnter: routerGuardGeneral,
        component: () => import("../views/Loans/CalculateView.vue"),
      },
    ],
  },

  // --------------------------------------------------------------------------------------------
  // SUPPORT
  // --------------------------------------------------------------------------------------------
  {
    path: "/support",
    name: "support",
    beforeEnter: routerGuardGeneral,
    component: () => import("../views/AccountView.vue"),
  },

  // --------------------------------------------------------------------------------------------
  // PROFILE
  // --------------------------------------------------------------------------------------------
  {
    path: "/profile",
    name: "profile",
    beforeEnter: routerGuardGeneral,
    component: () => import("../views/User/ProfileView.vue"),
  },

  // --------------------------------------------------------------------------------------------
  // UPDATE DATA
  // --------------------------------------------------------------------------------------------
  {
    path: "/updateData",
    name: "user-data",
    beforeEnter: routerGuardGeneral,
    component: () => import("../views/User/UpdateDataView.vue"),
  },

  // --------------------------------------------------------------------------------------------
  // CONFIG DATA
  // --------------------------------------------------------------------------------------------
  {
    path: "/config",
    name: "config",
    beforeEnter: routerGuardGeneral,
    component: () => import("../views/ConfigView.vue"),
  },

  {
    path: "/resetpass",
    name: "ResetPassword",
    component: () => import("../views/Account/ResetPassword.vue"),
    beforeEnter: routerGuardGeneral,
  },

];

// ------------------------------------------------------------------------------------------------
// GENERAL GUARD
// ------------------------------------------------------------------------------------------------
function routerGuardGeneral(to, from, next) {
  const global = useGlobalStore();
  global.getGlobalConfig().then(() => {
    const promises = [];
    promises.push(global.getRAPToken());
    //promises.push(global.getGlobalConfig());
    promises.push(global.getFirebaseUser());

    /*promises.push(global.getGlobalConfig(() =>{
      global.getFirebaseUser()
      }));*/

    Promise.all(promises).then(() => {

      if (global.firebaseToken && global.rapToken) {
        global.showMenuInit = true;
        next();
        useUserStore().handleSessionTime(true);
      } else next("/");
    });
  });
}

// ------------------------------------------------------------------------------------------------
// HOME GUARD
// ------------------------------------------------------------------------------------------------
function routerGuardHome(to, from, next) {
  const global = useGlobalStore();

  global.getGlobalConfig().then(() => {
    const promises = [];
    promises.push(global.getRAPToken());
    //promises.push(global.getGlobalConfig());
    promises.push(global.getFirebaseUser());

    Promise.all(promises).then(() => {

      if (global.firebaseToken && global.rapToken) next("/account/overview");
      else {
        next();
        useUserStore().handleSessionTime(true);
      }
    });
  });
}

function routerGuardPublic(to, from, next) {
  const global = useGlobalStore();

  global.getGlobalConfig().then(() => {
    const promises = [];
    promises.push(global.getRAPToken());
    promises.push(global.getFirebaseUser());

    Promise.all(promises).then(() => {
      next(); // Simplemente permite el acceso sin importar el estado de autenticación
      useUserStore().handleSessionTime(true);
    });
  });
}




// ------------------------------------------------------------------------------------------------
// ROUTER

// ------------------------------------------------------------------------------------------------
const router = createRouter({
  history: createWebHistory(import.meta.BASE_URL),
  routes,
});

try {
  App.addListener("appUrlOpen", function (data) {
    const slug = data.url.split(".app/").pop();
    if (slug) {
      router.push("/reset" + slug);
    } else {
      router.push("/");
    }
  });
} catch (error) {
  console.log("ERROR appUrlOpen ", error);
}

export default router;
