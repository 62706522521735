import { defineStore } from "pinia";
import { useGlobalStore } from "@/store/GlobalStore";
import { httpsCallable } from "firebase/functions";
import { doc, onSnapshot } from "firebase/firestore";
import { signOut } from "firebase/auth";
import Router from "../router";
import { ClientJS } from "clientjs";
import Axios from "axios";
import { dbStatement } from '../db/statement'
import { useLog } from "../composables/useLog";


export const useUserStore = defineStore("user", {
    state() {
        const global = useGlobalStore();
        const localStatement = dbStatement();
        const log = useLog();
        return {
            token: null,
            jwt: null,
            sid: null,
            global,
            log,
            infoHash: {
                fecha_saldo: "",
                fecha_rango_final: "",
                cod_cliente: "",
                fecha_rango_inicial: "",
                fecha_saldo_des: "",
            },
            infoAccount: [
                {
                    cod_moneda: "",
                    cod_cuenta: "",
                    cod_empresa: "",
                    cod_cuenta_ref: ""
                }
            ],
            info: {
                code: "",
                id: "",
                idType: "",

                firstName: "",
                secondName: "",
                firstLastName: "",
                secondLastName: "",
                dob: "",

                picture: null,

                codPatrono: "",
                patrono: "",
            },
            visibleUserData: false,
            visibleUserTerms: false,
            visibleUserBiometric: false,
            accounts: [],
            account: [],

            jobs: [],
            terms: [], //plazos para prestamos del afiliado
            destinations: [], //destinos para prestamos del afiliado

            loans: [],
            loanDetail: [],
            loanRequest: [],
            quoteLoan: [],
            interestRate: null,
            preApprovedLoans: null,

            formLink: null,
            amountMaxLoan: 0.01, //TODO este campo debe terne el valor minino del prestamo y maximo de usuario

            loadState: {
                validate: false,
                info: false,
                accounts: false,
                funds: false,
                summary: false,
                jobs: false,
                loans: false,
                loanDetail: false,
                loanRequest: false,
                quoteLoan: false,
                formLink: false,
            },
            fatalErrorUser: false,

            statementData: {
                data: {},
                summary: [],
                sumSummary: []
            },
            userDataLoan: {},
            localStatement,
            initStore: false,

            loansPageStorage: {
                progresPay: 100,
                page: null,
                account: null,
                pievalues: [1, 1],

                loadState: {
                    page: false,
                    user: false,
                },
                loanRequest: {
                    state: null,
                    mount: 0,
                    loading: false,
                },
                interestRate: null,
                preApprovedLoans: {
                    msg: null,
                    total_aportes: null,
                },
                loanSelected: null,
                arrayLoans: [],
                reasons: null,
            },

            etiquetasFondosSaldos: {
                fondos: [
                    { descripcion: 'FONDO DE VIVIENDA', codigo: '101' },
                    { descripcion: 'FONDO DE PENSIONES', codigo: '102' },
                    // { descripcion: 'FONDO DE CESANTÍAS', codigo: '105' },
                    { descripcion: 'FONDO DE RESERVA LABORAL', codigo: '105' },
                    { descripcion: "FONDO CESANTIAS USD", codigo: "110" },
                    { descripcion: 'FONDO DE PENSIONES USD', codigo: '111' },
                ],
                saldos: [
                    {
                        descripcion: 'FP - INDIVIDUAL OBLIGATORIO',
                        codigo: '10'
                    },
                    {
                        descripcion: 'FP - INDIVIDUAL VOLUNTARIO',
                        codigo: '11'
                    },
                    {
                        descripcion: 'FP - EMPRESARIAL OBLIGATORIO',
                        codigo: '12'
                    },
                    {
                        descripcion: 'FC - EMPRESARIAL OBLIGATORIO',
                        codigo: '13'
                    },
                    {
                        descripcion: 'RECAUDOS EN PROCESO',
                        codigo: '14'
                    },
                    {
                        descripcion: 'REZAGOS',
                        codigo: '15'
                    },
                    {
                        descripcion: 'EXCEDENTES (DEVOLUCION)',
                        codigo: '16'
                    },
                    {
                        descripcion: 'FV - HISTÓRICO',
                        codigo: '17'
                    },
                    {
                        descripcion: 'FV - EXCEDENTES',
                        codigo: '18'
                    },
                    {
                        descripcion: 'AHORRO VOLUNTARIO',
                        codigo: '24'
                    },
                    {
                        descripcion: 'INGRESOS POR MORA',
                        codigo: '25'
                    },
                    {
                        descripcion: 'CUOTAS PRESTAMOS PERSONALES',
                        codigo: '26'
                    },
                    {
                        descripcion: 'FP - INDIVIDUAL VOLUNTARIO USD',
                        codigo: '30'
                    },
                    {
                        descripcion: 'FP - INDIVIDUAL OBLIGATORIO USD',
                        codigo: '31'
                    },
                    {
                        descripcion: 'RECAUDOS EN PROCESO USD',
                        codigo: '32'
                    },
                    {
                        descripcion: 'REZAGOS USD',
                        codigo: '33'
                    },
                    {
                        descripcion: 'EXCESOS USD',
                        codigo: '34'
                    },
                    {
                        descripcion: 'FC EMPRESARIAL OBLIGATORIO USD',
                        codigo: '37'
                    },
                    {
                        descripcion: 'FC EMPRESARIAL VOLUNTARIO USD',
                        codigo: '38'
                    },
                    {
                        descripcion: 'FP EMPRESARIAL OBLIGATORIO US',
                        codigo: '35'
                    },
                    {
                        descripcion: 'FP EMPRESARIAL VOLUNTARIO US',
                        codigo: '36'
                    },
                    {
                        descripcion: 'FC - EMPRESARIAL VOLUNTARIO',
                        codigo: '43'
                    },
                    {
                        descripcion: 'FP - EMPRESARIAL VOLUNTARIO',
                        codigo: '44'
                    },
                    {
                        descripcion: 'FV-APORT. VOL. PERSONAL',
                        codigo: '19'
                    },
                    {
                        descripcion: 'FV-APORT. VOL. PATRONAL',
                        codigo: '20'
                    },
                    {
                        descripcion: 'FV-NUEVO HISTORICO EMPLEADO',
                        codigo: '27'
                    },
                    {
                        descripcion: 'FV-NUEVO HISTORICO PATRONO',
                        codigo: '28'
                    },
                    {
                        descripcion: 'CUOTAS SEGURO MAPFRE',
                        codigo: '29'
                    },
                    {
                        descripcion: 'FV-APORT. VOL. EDUCARAP',
                        codigo: '51'
                    },
                    {
                        descripcion: 'FV-PRESTAHORRO',
                        codigo: '52'
                    }
                ]
            },

            saldosFondos: {
                total: {
                    porcentaje: '',
                    mon_saldo_actual: '',
                    cod_inversion: ''
                },
                funds: [
                    {
                        porcentaje: '0.00', cod_inversion: '', mon_saldo_actual: '0.00', descripcion: 'Cargando...', palette: '1'
                    }
                ]
            },

            saldosTypoSaldo: {
                registros: [
                    {
                        porcentaje: 'Cargando...',
                        cod_inversion: 'Cargando...',
                        cod_tipsaldo: 'Cargando...',
                        mon_saldo_actual: 'Cargando...'
                    },
                    {
                        porcentaje: 'Cargando...',
                        cod_inversion: 'Cargando...',
                        cod_tipsaldo: 'Cargando...',
                        mon_saldo_actual: 'Cargando...'
                    },
                ],
                history: [
                    {
                        tip_transac: "",
                        num_movimto_deta: "",
                        monto: "",
                        num_movimto: "",
                        cod_inversion: "",
                        cod_tipsaldo: "",
                        subtip_transac: "",
                    },
                ],
                rangeDate: {
                    start: new Date(new Date().getFullYear() - 1, 0, 1),
                    end: new Date()
                }
            },

            ecDataPersonal: {
                identificacion: "",
                datos_del_cliente: {
                    segundo_apellido: "",
                    direccion: "",
                    primer_apellido: "",
                    segundo_nombre: "",
                    fecha_ingreso: "",
                    telefono: "",
                    primer_nombre: "",
                    email: "",
                    cod_cliente_mask: "",
                },
            },

            ecFundsResumed: {
                registros: [
                    {
                        rentabilidad: "0.00",
                        mon_aportes: "Cargando...",
                        mon_comisiones: "Cargando...",
                        valor_cuota: "Cargando...",
                        mon_retiros: "Cargando...",
                        mon_rendimiento: "Cargando...",
                        mon_saldo_ant: "Cargando...",
                        cuo_saldo_actual: "Cargando...",
                        mon_saldo_actual: "Cargando...",
                        cod_fondo: "110",
                        descripcion: "Cargando...",
                    },
                    {
                        rentabilidad: "0.00",
                        mon_aportes: "Cargando...",
                        mon_comisiones: "Cargando...",
                        valor_cuota: "Cargando...",
                        mon_retiros: "Cargando...",
                        mon_rendimiento: "Cargando...",
                        mon_saldo_ant: "Cargando...",
                        cuo_saldo_actual: "Cargando...",
                        mon_saldo_actual: "Cargando...",
                        cod_fondo: "112",
                        descripcion: "Cargando...",
                    },
                ],
                total: {
                    rentabilidad: "0.00",
                    mon_aportes: "Cargando...",
                    mon_comisiones: "Cargando...",
                    valor_cuota: "Cargando...",
                    mon_retiros: "Cargando...",
                    mon_rendimiento: "Cargando...",
                    mon_saldo_ant: "Cargando...",
                    cuo_saldo_actual: "Cargando...",
                    mon_saldo_actual: "Cargando...",
                    cod_fondo: "Total",
                },
            },

            ecCommissionFunds: [
                {
                    descripcion: "% sobre el saldo promedio anual",
                    tip_comision: "",
                    Cod_inversion: "",
                    Porc_comision: "",
                },
            ],

            ecFundsResumedGeneralTypeFunds: {
                registros: [
                    {
                        cod_fondo: "101",
                        descripcion_fondo: "FONDO DE VIVIENDA",
                        registros: [
                            {
                                mon_aportes: "Cargando...",
                                mon_comisiones: "Cargando...",
                                mon_retiros: "Cargando...",
                                mon_rendimiento: "Cargando...",
                                mon_saldo_ant: "Cargando...",
                                cod_tipsaldo: "Cargando...",
                                cuo_saldo_actual: "Cargando...",
                                mon_saldo_actual: "Cargando...",
                                cod_fondo: "Cargando...",
                                descripcion_tipsaldo: "Cargando...",
                            },
                        ],
                        totales: {
                            mon_saldo_ant: "Cargando...",
                            mon_aportes: "Cargando...",
                            mon_rendimiento: "Cargando...",
                            mon_retiros: "Cargando...",
                            mon_comisiones: "Cargando...",
                            mon_saldo_actual: "Cargando...",
                            cuo_saldo_actual: "Cargando...",
                        },
                    },
                ],

                total: {
                    mon_aportes: "Cargando...",
                    mon_comisiones: "Cargando...",
                    mon_retiros: "Cargando...",
                    mon_rendimiento: "Cargando...",
                    mon_saldo_ant: "Cargando...",
                    cod_tipsaldo: "Cargando...",
                    cuo_saldo_actual: "Cargando...",
                    mon_saldo_actual: "Cargando...",
                    cod_fondo: "Total",
                },
            },

            cotVPercentRCI: 40,


        };
    },

    actions: {
        rellenarSaldosFondos(respuestaHttp, fondos) {
            const saldosFondos = {
                total: {
                    porcentaje: '',
                    mon_saldo_actual: '',
                    cod_inversion: ''
                },
                funds: []
            };

            // Verificar si fondos es un array antes de usar map
            const mapaDescripciones = new Map(
                Array.isArray(fondos)
                    ? fondos.map(fondo => [fondo.codigo, fondo.descripcion])
                    : []
            );

            // Asegurarse de que respuestaHttp sea un array
            const saldosArray = Array.isArray(respuestaHttp) ? respuestaHttp : [];

            let paletteCounter = 1;

            saldosArray.forEach(saldo => {
                if (saldo.cod_inversion === 'Total') {
                    // Rellenar el objeto total
                    saldosFondos.total = { ...saldo };
                } else {
                    // Agregar a la lista de funds
                    saldosFondos.funds.push({
                        ...saldo,
                        descripcion: mapaDescripciones.get(saldo.cod_inversion) || '',
                        palette: paletteCounter.toString() // Añadir palette como string
                    });

                    // Incrementar el contador de palette
                    paletteCounter = paletteCounter % 3 + 1; // Esto hará que paletteCounter alterne entre 1, 2 y 3
                }
            });

            // Si no hay fondos, agregar un objeto vacío como se especifica
            if (saldosFondos.funds.length === 0) {
                saldosFondos.funds.push({
                    porcentaje: '0.00',
                    cod_inversion: '',
                    mon_saldo_actual: '0.00',
                    descripcion: '',
                    palette: '1' // Añadir palette como '1' para el caso de fondo vacío
                });
            }

            return saldosFondos;
        },

        async getFundBalance() {


            try {
                const accountHash = this.infoAccount[0].cod_cuenta_ref || '';
                const periodHash = this.infoHash.fecha_saldo;
                // console.log("accountHast:", accountHash);
                const httpAffiliateRestServices = httpsCallable(
                    this.global.functions,
                    "httpAffiliateRestServices"
                );
                const result = await httpAffiliateRestServices({
                    method: "getFundBalance",
                    account: accountHash,
                    period: periodHash
                });
                if (result.data.response.body.ec_saldos_x_fondo) {
                    this.saldosFondos = this.rellenarSaldosFondos(result.data.response.body.ec_saldos_x_fondo, this.etiquetasFondosSaldos.fondos);
                }

                await this.log.registerUserLogHistory(
                    "success",
                    "getFundBalance",
                    "Inicio",
                );

            } catch (error) {
                await this.log.registerUserLogHistory(
                    "failed",
                    "getFundBalance",
                    "Inicio",
                );
                console.error("Error calling function:", error);
            }
        },

        async getInfoHashAffiliate() {
            const account = this.info.code;
            try {
                const httpAffiliateRestServices = httpsCallable(
                    this.global.functions,
                    "httpAffiliateRestServices"
                );
                const result = await httpAffiliateRestServices({
                    method: "getInfoHashAffiliate",
                    codUser: "",
                    codClient: account,
                });
                if (result.data.response.body.ec_hash_datos_cliente) {
                    this.infoHash = result.data.response.body.ec_hash_datos_cliente;
                    // console.log("infoHashAffiliate:", this.infoHash);
                }

                await this.log.registerUserLogHistory(
                    "success",
                    "getRestGeneralClient",
                    "Inicio",
                );

            } catch (error) {
                await this.log.registerUserLogHistory(
                    "failed",
                    "getRestGeneralClient",
                    "Inicio",
                );
                console.error("Error calling function:", error);
            }
        },

        async getInfoAffiliate() {

            try {
                const httpAffiliateRestServices = httpsCallable(
                    this.global.functions,
                    "httpAffiliateRestServices"
                );
                const result = await httpAffiliateRestServices({
                    method: "getInfoAffiliate",
                    codUser: "",
                    codClient: this.infoHash.cod_cliente,
                });
                if (result.data.response.body.ec_cuentas_cliente) {
                    // console.log("infoAffiliate:", result.data.response.body.ec_cuentas_cliente);
                    this.infoAccount = result.data.response.body.ec_cuentas_cliente;
                    // console.log("infoAffiliate:", this.infoAffiliate);
                    // console.log("infoAffiliate:", this.infoAccount);
                }

                await this.log.registerUserLogHistory(
                    "success",
                    "getInfoAffiliate",
                    "Inicio",
                );

            } catch (error) {
                await this.log.registerUserLogHistory(
                    "failed",
                    "getInfoAffiliate",
                    "Inicio",
                );
                console.error("Error calling function:", error);
            }
        },

        // async getMovementBalanceDate(period) {
        //     const account = this.account.code;
        //     console.log("periodo:", period);

        //     console.log("identificacion:", account);

        //     try {
        //         const httpAffiliateRestServices = httpsCallable(
        //             this.global.functions,
        //             "httpAffiliateRestServices"
        //         );
        //         const result = await httpAffiliateRestServices({
        //             method: "getMovementBalanceDate",
        //             codUser: "",
        //             codClient: account,
        //             period: period
        //         });
        //         console.log("Response:", result.data.response.body.ec_saldos_movimientos);

        // if (result.data.response.body.ec_saldos_movimientos) {
        //     console.log("If response");

        //     this.saldosTypoSaldo.history = filtrarSaldosPorTipo(
        //       result.data.response.body.ec_saldos_movimientos,
        //       this.etiquetasFondosSaldos.saldos
        //     );

        //     this.saldosTypoSaldo.rangeDate = convertDateRange(
        //       result.data.fecha_rango_inicial,
        //       result.data.fecha_rango_final
        //     );

        //     // user.saldosTypoSaldo.rangeDate = {
        //     //   start: result.data.fecha_rango_inicial,
        //     //   end: result.data.fecha_rango_final,
        //     // };
        //   }

        //     } catch (error) {
        //         console.error("Error calling function:", error);
        //     }
        // },
        async loadFromIndexedDB(id) {
            try {
                const storedData = await this.localStatement.getStatement(id)
                if (storedData.statement == undefined || storedData.statement.token == null) {
                    return;
                }
                // Actualiza el estado de Pinia con los datos de IndexedDB
                Object.keys(storedData.statement).forEach(key => {
                    if (this[key] !== undefined) {
                        this[key] = storedData.statement[key]
                    }
                })

                return storedData;

            } catch (error) {
                console.log('Not proxy data');
            }
        },

        async updateToken(id, token, sid, jwt) {
            try {
                await this.localStatement.updateAuthFields(id, { token, jwt, sid })
                // console.log('Token updated:', token);
            } catch (error) {
                console.log('Not proxy data');

            }
        },
        //---------------------------------------------------------------------------------------------
        // GET USER TOKEN
        //---------------------------------------------------------------------------------------------
        getUserToken() {
            if (this.loadState.validate) {
                return Promise.resolve(this.info);
            } else {
                try {
                    let data = {
                        method: "getUserToken",
                        token: this.global.rapToken,
                    };

                    let httpUserServices = httpsCallable(
                        this.global.functions,
                        "httpUserServices"
                    );
                    return Promise.resolve(
                        httpUserServices(data)
                            .then((result) => {
                                if (result.data.status == 99) {
                                    // this.localStatement.deleteStatement(this.global.firebaseToken.uid);
                                    this.signOut();
                                    return false;
                                }

                                if (result.data.status !== 2) {
                                    this.token = result.data.data.TOKEN;
                                    // this.token = "abcd1234!"
                                    this.jwt = result.data.data.jwt;
                                    this.sid = result.data.data.sid;



                                    onSnapshot(
                                        doc(
                                            this.global.firestore,
                                            "user",
                                            this.global.firebaseToken.uid
                                        ),
                                        (doc) => {
                                            if (doc.data().sid != this.sid)
                                                this.signOut();


                                            //TODO este cpdigo es para ver si agreo funcionalida de cierre se sision pro passwor iseguro
                                            // if(doc.data().changePassword==false){
                                            //     this.fatalErrorUser=true
                                            //     this.global.throwError('Hemos detectado que no cuentas con un contraseña robusta,por favor cambia tu contraseña');
                                            // }
                                        }
                                    );

                                    this.info.code =
                                        result.data.data.COD_CLIENTE;
                                    this.info.id =
                                        result.data.data.NUMERO_ID.replaceAll(
                                            "-",
                                            ""
                                        );
                                    this.info.idType = result.data.data.TIPO_ID;
                                    this.loadState.funds = true;
                                    this.accounts = [];

                                    result.data.data.CUENTAS.OT_SYSDE_INF_CUENTA.forEach(
                                        (account) => {
                                            // console.log(this.loadState.funds);
                                            // console.log(account);
                                            this.accounts.push({
                                                code: account.COD_CUENTA,
                                                info: account,
                                                coin: account.DES_MONEDA,
                                                funds: {
                                                    home: {
                                                        name: "Fondo de Vivienda",
                                                        value: 0,
                                                        percent: 0,
                                                        palette: 0,
                                                        symbol: "L",
                                                        typecode: 0,
                                                        component: {
                                                            individualVoluntary: {
                                                                name: "Individual voluntario",
                                                                value: 0,
                                                                percent: 0,
                                                                palette: 0,
                                                                symbol: "L"
                                                            },
                                                            individualMandatory: {
                                                                name: "Individual obligatorio",
                                                                value: 0,
                                                                percent: 0,
                                                                palette: 0,
                                                                symbol: "L"
                                                            },
                                                            companyVoluntary: {
                                                                name: "Empresarial voluntario",
                                                                value: 0,
                                                                percent: 0,
                                                                palette: 0,
                                                                symbol: "L"
                                                            },
                                                            companyMandatory: {
                                                                name: "Empresarial obligatorio",
                                                                value: 0,
                                                                percent: 0,
                                                                palette: 0,
                                                                symbol: "L"
                                                            },
                                                            historic: {
                                                                name: "Fondo histórico",
                                                                value: 0,
                                                                percent: 0,
                                                                palette: 0,
                                                                symbol: "L"
                                                            },
                                                            surplus: {
                                                                name: "Excedentes/Rendimientos",
                                                                value: 0,
                                                                percent: 0,
                                                                palette: 0,
                                                                symbol: "L"
                                                            }
                                                        }
                                                    },
                                                    retirement: {
                                                        name: "Fondo de Pensión",
                                                        value: 0,
                                                        percent: 0,
                                                        palette: 0,
                                                        symbol: "L",
                                                        typecode: 0,
                                                        component: {
                                                            individualVoluntary: {
                                                                name: "Individual voluntario",
                                                                value: 0,
                                                                percent: 0,
                                                                palette: 0,
                                                                symbol: "L"
                                                            },
                                                            individualMandatory: {
                                                                name: "Individual obligatorio",
                                                                value: 0,
                                                                percent: 0,
                                                                palette: 0,
                                                                symbol: "L"
                                                            },
                                                            companyVoluntary: {
                                                                name: "Empresarial voluntario",
                                                                value: 0,
                                                                percent: 0,
                                                                palette: 0,
                                                                symbol: "L"
                                                            },
                                                            companyMandatory: {
                                                                name: "Empresarial obligatorio",
                                                                value: 0,
                                                                percent: 0,
                                                                palette: 0,
                                                                symbol: "L"
                                                            }
                                                        }
                                                    },
                                                    unemployment: {
                                                        // name: "Fondo de reserva laboral",
                                                        name: "Fondo de Reserva Laboral",
                                                        value: 0,
                                                        percent: 0,
                                                        palette: 0,
                                                        symbol: "L",
                                                        typecode: 0,
                                                        component: {
                                                            companyVoluntary: {
                                                                name: "Empresarial voluntario",
                                                                value: 0,
                                                                percent: 0,
                                                                palette: 0,
                                                                symbol: "L"
                                                            },
                                                            companyMandatory: {
                                                                name: "Empresarial obligatorio",
                                                                value: 0,
                                                                percent: 0,
                                                                palette: 0,
                                                                symbol: "L"
                                                            }
                                                        }
                                                    }
                                                },
                                                value: account.SALDO,
                                                loaded: false,
                                            });
                                        }
                                    );
                                    this.account = this.accounts[0];

                                    this.loadState.validate = true;
                                    this.loadState.accounts = true;
                                    this.loadState.funds = false;
                                    this.updateToken(this.global.firebaseToken.uid, this.token, this.sid, this.jwt);
                                    // this.localStatement.updateValidateLoadState(this.global.firebaseToken.uid, true);
                                    this.sessionTimeTimer();

                                    return this.token;
                                } else {
                                    // this.global.throwError(result,'user.getUserToken().results');
                                    // this.fatalErrorUser = true;
                                    // this.global.throwError(
                                    //     "Ha ocurrido un error al obtener tu información"
                                    // );
                                    // throw result;
                                    this.loadState.validate = false;
                                }
                            })
                            .catch((err) => {
                                // this.global.throwError(err,'user.getUserToken().results');
                                // this.fatalErrorUser = true;

                                this.loadState.validate = false;
                                // this.global.throwError(
                                //     "Ha ocurrido un error al obtener tu información. 6667"
                                // );
                                // throw err;
                                console.log("err", err);
                            })
                    );
                } catch (err) {
                    // this.global.throwError(err, "user.getUserToken()");
                    // throw err;
                    console.log("err", err);
                }
            }
        },

        //---------------------------------------------------------------------------------------------
        // GET ACCOUNT INFO
        //---------------------------------------------------------------------------------------------
        getAccountInfo() {
            let data = {
                token: this.global.rapToken,
                method: "getUserInfo",
            };

            let httpUserServices = httpsCallable(
                this.global.functions,
                "httpUserServices"
            );
            return Promise.resolve(
                httpUserServices(data)
                    .then(() => {
                        // console.log("CACHED USER INFO:", result);
                        return true;
                    })
                    .catch((err) => {
                        // this.global.throwError(err,'user.getAccountInfo');
                        // this.global.throwError(
                        //     "No se obtuvo información de tu usuario"
                        // );
                        // throw err;
                        console.log("err", err);
                    })
            );
        },

        //---------------------------------------------------------------------------------------------
        // GET USER INFO
        //---------------------------------------------------------------------------------------------
        getUserInfo() {
            if (this.loadState.info && !this.initStore) {
                return Promise.resolve(this.info);
            } else {
                try {
                    let data = {
                        token: this.global.rapToken,
                        jwt: this.jwt,
                        documentNumber: this.info.id,
                        documentType: this.info.idType,
                        method: "getGeneralInfo",
                    };


                    let httpAffiliateServices = httpsCallable(
                        this.global.functions,
                        "httpAffiliateServices"
                    );
                    return Promise.resolve(
                        httpAffiliateServices(data)
                            .then((result) => {

                                if (result.data.status == 99) {
                                    // this.localStatement.deleteStatement(this.global.firebaseToken.uid);
                                    if (!this.initStore) {
                                        this.signOut();
                                    }
                                    return false;
                                }

                                try {
                                    const general =
                                        result.data.info["enc-Dtos-grales"];
                                    this.info.data = general;


                                    if (general["prim-nom"]) {
                                        this.info.firstName =
                                            general["prim-nom"].toLowerCase();
                                        this.info.firstName =
                                            this.info.firstName
                                                .charAt(0)
                                                .toUpperCase() +
                                            this.info.firstName.slice(1);
                                    }

                                    if (general["seg-nom"]) {
                                        this.info.secondName =
                                            general["seg-nom"].toLowerCase();
                                        this.info.secondName =
                                            this.info.secondName
                                                .charAt(0)
                                                .toUpperCase() +
                                            this.info.secondName.slice(1);
                                    }

                                    if (general["prim-ape"]) {
                                        this.info.firstLastName =
                                            general["prim-ape"].toLowerCase();
                                        this.info.firstLastName =
                                            this.info.firstLastName
                                                .charAt(0)
                                                .toUpperCase() +
                                            this.info.firstLastName.slice(1);
                                    }

                                    if (general["seg-ape"]) {
                                        this.info.secondLastName =
                                            general["seg-ape"].toLowerCase();
                                        this.info.secondLastName =
                                            this.info.secondLastName
                                                .charAt(0)
                                                .toUpperCase() +
                                            this.info.secondLastName.slice(1);
                                    }

                                    this.loadState.info = true;
                                    return this.info;
                                } catch (err) {
                                    // this.fatalErrorUser = true;
                                    // this.global.throwError(err,'user.getUserInfo().result');
                                    // this.global.throwError(
                                    //     "Ha ocurrido un error al obtener información del usuario (CODE ERROR:900)"
                                    // );
                                    // throw err;

                                    console.log("err", err);
                                }
                            })
                            .catch((err) => {
                                console.log("err =>", err);

                                // this.fatalErrorUser = true;
                                // // this.global.throwError(err,'user.getUserInfo()');
                                // this.global.throwError(
                                //     "Ha ocurrido un error al obtener información del usuario (CODE ERROR:901)"
                                // );
                                // throw err;
                            })
                    );
                } catch (err) {
                    this.loadState.info = false;
                    // this.fatalErrorUser = true;
                    // this.global.throwError(err,'user.getUserInfo()');
                    // this.global.throwError(
                    //     "Ha ocurrido un error al obtener información del usuario (CODE ERROR:902)"
                    // );
                    // throw err;
                }
            }
        },
        //---------------------------------------------------------------------------------------------
        // GET STATEMENT
        //---------------------------------------------------------------------------------------------
        getStatementInfo: function (account) {
            try {
                let data = {
                    token: this.global.rapToken,
                    jwt: this.jwt,
                    accountNumber: account.code,
                    method: "getDetailedAccountStatement",
                };


                let httpAffiliateServices = httpsCallable(
                    this.global.functions,
                    "httpAffiliateServices"
                );
                return Promise.resolve(
                    httpAffiliateServices(data)
                        .then((result) => {



                            if (result.data.status == 99) {
                                // this.localStatement.deleteStatement(this.global.firebaseToken.uid);
                                this.signOut();
                                return false;
                            }

                        })
                        .catch((err) => {
                            // this.global.throwError(
                            //     err.message,
                            //     "Por favor contacte a soporte técnico"
                            // );
                            console.log("err =>", err);
                            throw err;
                        })
                );
            } catch (err) {
                this.global.throwError(err, "user.getStatementInfo()");
                throw err;
            }

        },

        //---------------------------------------------------------------------------------------------
        // GET FUNDS LEGACY
        //---------------------------------------------------------------------------------------------
        getFundsInfo: function (account) {
            if (account.loaded) {
                return Promise.resolve(account.funds);
            } else {
                try {
                    let data = {
                        token: this.global.rapToken,
                        jwt: this.jwt,
                        accountNumber: account.code,
                        method: "getFundInfo",
                    };


                    let httpAffiliateServices = httpsCallable(
                        this.global.functions,
                        "httpAffiliateServices"
                    );
                    return Promise.resolve(
                        httpAffiliateServices(data)
                            .then((result) => {


                                if (result.data.status == 99) {
                                    // this.localStatement.deleteStatement(this.global.firebaseToken.uid);
                                    this.signOut();
                                    return false;
                                }

                                const home = {
                                    name: "Fondo de Vivienda",
                                    value: 0,
                                    percent: 0,
                                    palette: 1,
                                    symbol: "L",
                                    typecode: 101,

                                    component: {
                                        individualVoluntary: {
                                            name: "Individual voluntario",
                                            value: 0,
                                            percent: 0,
                                            palette: 1,
                                            symbol: "L",
                                        },
                                        individualMandatory: {
                                            name: "Individual obligatorio",
                                            value: 0,
                                            percent: 0,
                                            palette: 2,
                                            symbol: "L",
                                        },
                                        companyVoluntary: {
                                            name: "Empresarial voluntario",
                                            value: 0,
                                            percent: 0,
                                            palette: 3,
                                            symbol: "L",
                                        },
                                        companyMandatory: {
                                            name: "Empresarial obligatorio",
                                            value: 0,
                                            percent: 0,
                                            palette: 4,
                                            symbol: "L",
                                        },
                                        historic: {
                                            name: "Fondo histórico",
                                            value: 0,
                                            percent: 0,
                                            palette: 5,
                                            symbol: "L",
                                        },
                                        surplus: {
                                            name: "Excedentes/Rendimientos",
                                            value: 0,
                                            percent: 0,
                                            palette: 6,
                                            symbol: "L",
                                        },
                                        /*incomeByDebt: {
                      name: 'Ingresos Por Mora',
                      value: 0, percent: 0, palette: 7, symbol: 'L',
                    },
                    personalLoans: {
                      name: 'Cuotas Présamos Personales',
                      value: 0, percent: 0, palette: 8, symbol: 'L',
                    }*/
                                    },
                                };

                                const retirement = {
                                    name: "Fondo de Pensión",
                                    value: 0,
                                    percent: 0,
                                    palette: 2,
                                    symbol: "L",
                                    typecode: 102,
                                    component: {
                                        individualVoluntary: {
                                            name: "Individual voluntario",
                                            value: 0,
                                            percent: 0,
                                            palette: 1,
                                            symbol: "L",
                                        },
                                        individualMandatory: {
                                            name: "Individual obligatorio",
                                            value: 0,
                                            percent: 0,
                                            palette: 2,
                                            symbol: "L",
                                        },
                                        companyVoluntary: {
                                            name: "Empresarial voluntario",
                                            value: 0,
                                            percent: 0,
                                            palette: 3,
                                            symbol: "L",
                                        },
                                        companyMandatory: {
                                            name: "Empresarial obligatorio",
                                            value: 0,
                                            percent: 0,
                                            palette: 4,
                                            symbol: "L",
                                        },
                                    },
                                };

                                const unemployment = {
                                    name: "Fondo de reserva laboral",
                                    value: 0,
                                    percent: 0,
                                    palette: 3,
                                    symbol: "L",
                                    typecode: 105,
                                    component: {
                                        companyVoluntary: {
                                            name: "Empresarial voluntario",
                                            value: 0,
                                            percent: 0,
                                            palette: 1,
                                            symbol: "L",
                                        },
                                        companyMandatory: {
                                            name: "Empresarial obligatorio",
                                            value: 0,
                                            percent: 0,
                                            palette: 2,
                                            symbol: "L",
                                        },
                                    },
                                };

                                const retirementusd = {
                                    name: "Fondo de Pensión USD",
                                    value: 0,
                                    percent: 0,
                                    palette: 1,
                                    symbol: "$",
                                    typecode: 111,
                                    component: {
                                        individualVoluntary: {
                                            name: "Individual voluntario",
                                            value: 0,
                                            percent: 0,
                                            palette: 1,
                                            symbol: "$",
                                        },
                                        individualMandatory: {
                                            name: "Individual obligatorio",
                                            value: 0,
                                            percent: 0,
                                            palette: 2,
                                            symbol: "$",
                                        },
                                        companyVoluntary: {
                                            name: "Empresarial voluntario",
                                            value: 0,
                                            percent: 0,
                                            palette: 3,
                                            symbol: "$",
                                        },
                                        companyMandatory: {
                                            name: "Empresarial obligatorio",
                                            value: 0,
                                            percent: 0,
                                            palette: 4,
                                            symbol: "$",
                                        },
                                    },
                                };

                                const unemploymentusd = {
                                    name: "Fondo de reserva laboral USD",
                                    value: 0,
                                    percent: 0,
                                    palette: 2,
                                    symbol: "$",
                                    typecode: 110,
                                    component: {
                                        companyVoluntary: {
                                            name: "Empresarial voluntario",
                                            value: 0,
                                            percent: 0,
                                            palette: 1,
                                            symbol: "$",
                                        },
                                        companyMandatory: {
                                            name: "Empresarial obligatorio",
                                            value: 0,
                                            percent: 0,
                                            palette: 2,
                                            symbol: "$",
                                        },
                                    },
                                };

                                const fundsArray = result.data.funds && result.data.funds.sdo ? result.data.funds.sdo : [];
                                // const fundsArray = result.data.funds.sdo;
                                // console.log("FUNDS ARRAY:", result.data);
                                fundsArray.forEach((value) => {
                                    switch (value["cod-tipsaldo"]) {
                                        case 13:
                                            unemployment.component.companyMandatory.value =
                                                value["mto-sdo"];
                                            break;

                                        case 43:
                                            unemployment.component.companyVoluntary.value =
                                                value["mto-sdo"];
                                            break;

                                        case 10:
                                            retirement.component.individualMandatory.value =
                                                value["mto-sdo"];
                                            break;

                                        case 11:
                                            retirement.component.individualVoluntary.value =
                                                value["mto-sdo"];
                                            break;

                                        case 12:
                                            retirement.component.companyMandatory.value =
                                                value["mto-sdo"];
                                            break;

                                        case 44:
                                            retirement.component.companyVoluntary.value =
                                                value["mto-sdo"];
                                            break;

                                        case 17:
                                            home.component.historic.value =
                                                value["mto-sdo"];
                                            break;

                                        case 18:
                                            home.component.surplus.value =
                                                value["mto-sdo"];
                                            break;

                                        case 19:
                                            home.component.individualVoluntary.value =
                                                value["mto-sdo"];
                                            break;

                                        case 20:
                                            home.component.companyVoluntary.value =
                                                value["mto-sdo"];
                                            break;

                                        /*case 25:
                      home.component.incomeByDebt.value = value['mto-sdo'];
                      break;*/

                                        /*case 26:
                      home.component.personalLoans.value = value['mto-sdo'];
                      break;*/

                                        case 27:
                                            home.component.individualMandatory.value =
                                                value["mto-sdo"];
                                            break;

                                        case 28:
                                            home.component.companyMandatory.value =
                                                value["mto-sdo"];
                                            break;

                                        case 37:
                                            unemploymentusd.component.companyMandatory.value =
                                                value["mto-sdo"];
                                            break;

                                        case 38:
                                            unemploymentusd.component.companyVoluntary.value =
                                                value["mto-sdo"];
                                            break;

                                        case 31:
                                            retirementusd.component.individualMandatory.value =
                                                value["mto-sdo"];
                                            break;

                                        case 30:
                                            retirementusd.component.individualVoluntary.value =
                                                value["mto-sdo"];
                                            break;

                                        case 35:
                                            retirementusd.component.companyMandatory.value =
                                                value["mto-sdo"];
                                            break;

                                        case 36:
                                            retirementusd.component.companyVoluntary.value =
                                                value["mto-sdo"];
                                            break;
                                    }
                                });

                                switch (account.coin) {
                                    case "DOLARES ESTADOUNIDENSES":
                                        account.funds = {
                                            retirementusd,
                                            unemploymentusd,
                                        };
                                        account.symbol = "$";
                                        break;

                                    case "LEMPIRAS":
                                    default:
                                        account.funds = {
                                            home,
                                            retirement,
                                            unemployment,
                                        };
                                        account.symbol = "L";
                                        break;
                                }
                                account.value = 0;


                                for (let key in account.funds) {
                                    let fund = account.funds[key];

                                    fund.value = 0;

                                    for (let key in fund.component)
                                        fund.value += fund.component[key].value;

                                    if (fund.value > 0)
                                        //fund.component[key].percent = fund.component[key].value/fund.value*100
                                        for (let key in fund.component)
                                            fund.component[key].percent =
                                                Math.round(
                                                    (fund.component[key].value /
                                                        fund.value) *
                                                    100
                                                );

                                    account.value += fund.value;
                                }

                                for (let key in account.funds) {
                                    let fund = account.funds[key];
                                    //fund.percent = fund.value/account.value*100);
                                    fund.percent = Math.round(
                                        (fund.value / account.value) * 100
                                    );
                                }

                                account.loaded = true;
                                return account.funds;
                            })
                            .catch((err) => {
                                // this.global.throwError(
                                //     err.message,
                                //     "Por favor contacte a soporte técnico"
                                // );
                                // console.log('Error in getFundsInfo:', err);
                                throw err;
                            })
                    );
                } catch (err) {
                    this.global.throwError(err, "user.getFundsInfo()");
                    throw err;
                }
            }
        },

        //---------------------------------------------------------------------------------------------
        // GET FUNDS
        //---------------------------------------------------------------------------------------------
        // getFundsInfo: function (account) {
        //     if (account.loaded) {
        //         return Promise.resolve(account.funds);
        //     }

        //     let data = {
        //         token: this.global.rapToken,
        //         jwt: this.jwt,
        //         accountNumber: account.code,
        //         method: "getFundInfo",
        //     };

        //     let httpAffiliateServices = httpsCallable(this.global.functions, "httpAffiliateServices");

        //     const initializeFund = (name, palette, symbol, typecode, components) => ({
        //         name,
        //         value: 0,
        //         percent: 0,
        //         palette,
        //         symbol,
        //         typecode,
        //         component: components
        //     });

        //     const componentsMap = {
        //         home: {
        //             17: 'historic',
        //             18: 'surplus',
        //             19: 'individualVoluntary',
        //             20: 'companyVoluntary',
        //             27: 'individualMandatory',
        //             28: 'companyMandatory',
        //         },
        //         retirement: {
        //             10: 'individualMandatory',
        //             11: 'individualVoluntary',
        //             12: 'companyMandatory',
        //             44: 'companyVoluntary',
        //         },
        //         unemployment: {
        //             13: 'companyMandatory',
        //             43: 'companyVoluntary',
        //         },
        //         retirementusd: {
        //             30: 'individualVoluntary',
        //             31: 'individualMandatory',
        //             35: 'companyMandatory',
        //             36: 'companyVoluntary',
        //         },
        //         unemploymentusd: {
        //             37: 'companyMandatory',
        //             38: 'companyVoluntary',
        //         },
        //     };

        //     const home = initializeFund("Fondo de Vivienda", 1, "L", 101, {
        //         individualVoluntary: { name: "Individual voluntario", value: 0, percent: 0, palette: 1, symbol: "L" },
        //         individualMandatory: { name: "Individual obligatorio", value: 0, percent: 0, palette: 2, symbol: "L" },
        //         companyVoluntary: { name: "Empresarial voluntario", value: 0, percent: 0, palette: 3, symbol: "L" },
        //         companyMandatory: { name: "Empresarial obligatorio", value: 0, percent: 0, palette: 4, symbol: "L" },
        //         historic: { name: "Fondo histórico", value: 0, percent: 0, palette: 5, symbol: "L" },
        //         surplus: { name: "Excedentes/Rendimientos", value: 0, percent: 0, palette: 6, symbol: "L" },
        //     });

        //     const retirement = initializeFund("Fondo de Pensión", 2, "L", 102, {
        //         individualVoluntary: { name: "Individual voluntario", value: 0, percent: 0, palette: 1, symbol: "L" },
        //         individualMandatory: { name: "Individual obligatorio", value: 0, percent: 0, palette: 2, symbol: "L" },
        //         companyVoluntary: { name: "Empresarial voluntario", value: 0, percent: 0, palette: 3, symbol: "L" },
        //         companyMandatory: { name: "Empresarial obligatorio", value: 0, percent: 0, palette: 4, symbol: "L" },
        //     });

        //     const unemployment = initializeFund("Fondo de reserva laboral", 3, "L", 105, {
        //         companyVoluntary: { name: "Empresarial voluntario", value: 0, percent: 0, palette: 1, symbol: "L" },
        //         companyMandatory: { name: "Empresarial obligatorio", value: 0, percent: 0, palette: 2, symbol: "L" },
        //     });

        //     const retirementusd = initializeFund("Fondo de Pensión USD", 1, "$", 111, {
        //         individualVoluntary: { name: "Individual voluntario", value: 0, percent: 0, palette: 1, symbol: "$" },
        //         individualMandatory: { name: "Individual obligatorio", value: 0, percent: 0, palette: 2, symbol: "$" },
        //         companyVoluntary: { name: "Empresarial voluntario", value: 0, percent: 0, palette: 3, symbol: "$" },
        //         companyMandatory: { name: "Empresarial obligatorio", value: 0, percent: 0, palette: 4, symbol: "$" },
        //     });

        //     const unemploymentusd = initializeFund("Fondo de reserva laboral USD", 2, "$", 110, {
        //         companyVoluntary: { name: "Empresarial voluntario", value: 0, percent: 0, palette: 1, symbol: "$" },
        //         companyMandatory: { name: "Empresarial obligatorio", value: 0, percent: 0, palette: 2, symbol: "$" },
        //     });

        //     const updateComponents = (fund, value, typecode) => {
        //         if (componentsMap[fund] && componentsMap[fund][typecode]) {
        //             fund.component[componentsMap[fund][typecode]].value = value["mto-sdo"];
        //         }
        //     };

        //     return httpAffiliateServices(data)
        //         .then((result) => {
        //             console.log("GET FUNDS INFO:", result.data.status);

        //             if (result.data.status == 99) {
        //                 this.signOut();
        //                 return false;
        //             }

        //             const fundsArray = result.data.funds && result.data.funds.sdo ? result.data.funds.sdo : [];
        //             console.log("FUNDS ARRAY:", result.data);
        //             fundsArray.forEach((value) => {
        //                 updateComponents(home, value, value["cod-tipsaldo"]);
        //                 updateComponents(retirement, value, value["cod-tipsaldo"]);
        //                 updateComponents(unemployment, value, value["cod-tipsaldo"]);
        //                 updateComponents(retirementusd, value, value["cod-tipsaldo"]);
        //                 updateComponents(unemploymentusd, value, value["cod-tipsaldo"]);
        //             });

        //             switch (account.coin) {
        //                 case "DOLARES ESTADOUNIDENSES":
        //                     account.funds = { retirementusd, unemploymentusd };
        //                     account.symbol = "$";
        //                     break;
        //                 case "LEMPIRAS":
        //                 default:
        //                     account.funds = { home, retirement, unemployment };
        //                     account.symbol = "L";
        //                     break;
        //             }
        //             account.value = 0;

        //             for (let key in account.funds) {
        //                 let fund = account.funds[key];
        //                 fund.value = Object.values(fund.component).reduce((acc, comp) => acc + comp.value, 0);
        //                 for (let key in fund.component) {
        //                     fund.component[key].percent = fund.value ? Math.round((fund.component[key].value / fund.value) * 100) : 0;
        //                 }
        //                 account.value += fund.value;
        //             }

        //             for (let key in account.funds) {
        //                 let fund = account.funds[key];
        //                 fund.percent = account.value ? Math.round((fund.value / account.value) * 100) : 0;
        //             }

        //             account.loaded = true;
        //             return account.funds;
        //         })
        //         .catch((err) => {
        //             this.global.throwError(err.message, "Por favor contacte a soporte técnico");
        //             throw err;
        //         });
        // },

        //---------------------------------------------------------------------------------------------
        // GET ACCOUNT SUMMARY
        //---------------------------------------------------------------------------------------------
        getAccountStatementSummary: function (account, initialdate, finaldate) {
            let data = {
                token: this.token,
                jwt: this.jwt,
                documentNumber: account.code,
                initialDate: initialdate,
                finalDate: finaldate,
                historical: true,
                method: "getAccountStatementSummary",
            };

            let httpAffiliateServices = httpsCallable(
                this.global.functions,
                "httpAffiliateServices"
            );
            return Promise.resolve(
                httpAffiliateServices(data)
                    .then(() => {
                        return this.account;
                    })
                    .catch((err) => {
                        // this.global.throwError(err,'user.getAccountStatementSummary()');
                        this.global.throwError(
                            "Ha ocurrido un error al obtener el resumen (Código de error: 000)"
                        );
                        throw err;
                    })
            );
        },

        //---------------------------------------------------------------------------------------------
        // GET ACCOUNT DETAIL
        //---------------------------------------------------------------------------------------------
        getAccountStatementDetail: function (
            account,
            initialdate,
            finaldate,
            typecode
        ) {
            let data = {
                token: this.token,
                jwt: this.jwt,
                documentNumber: account.code,
                initialDate: initialdate, //initialDate,
                finalDate: finaldate, //finalDate,
                historical: true, //historical,
                typeCode: typecode, //historical,
                method: "getAccountStatementDetail",
            };
            let httpAffiliateServices = httpsCallable(
                this.global.functions,
                "httpAffiliateServices"
            );
            return Promise.resolve(
                httpAffiliateServices(data)
                    .then((result) => {
                        if (result.data.status == 99) {
                            // this.localStatement.deleteStatement(this.global.firebaseToken.uid);
                            this.signOut();
                            return false;
                        }
                        return result;
                    })
                    .catch((err) => {
                        console.log("err", err);
                        // this.global.throwError('user.getAccountStatementDetail()');
                        // this.global.throwError(
                        //     "Ha ocurrido un error al obtener detalle de resumen (Código de error: 001)"
                        // );
                        throw err;
                    })
            );
        },


        //---------------------------------------------------------------------------------------------
        // GET ACCOUNT DETAIL
        //---------------------------------------------------------------------------------------------
        getStatementDetail: function (
            initialdate,
            finaldate
        ) {
            // console.log("getDetailedAccountStatement typecode", typecode);
            let data = {
                token: this.token,
                jwt: this.jwt,
                documentNumber: this.account.code,
                initialDate: initialdate, //initialDate,
                finalDate: finaldate, //finalDate,
                historical: false, //historical,
                // typeCode: typecode, //historical,
                method: "getDetailedStatementGeneral",
            };
            let httpAffiliateServices = httpsCallable(
                this.global.functions,
                "httpAffiliateServices"
            );
            return Promise.resolve(
                httpAffiliateServices(data)
                    .then((result) => {

                        if (result.data.status == 99) {
                            // this.localStatement.deleteStatement(this.global.firebaseToken.uid);
                            this.signOut();
                            return false;
                        }
                        this.statementData.data = result;
                        return result;
                    })
                    .catch((err) => {
                        console.log("err", err);

                        // this.global.throwError(
                        //     "Ha ocurrido un error al obtener detalle de resumen (Código de error: 001)"
                        // );
                        throw err;
                    })
            );
        },

        //---------------------------------------------------------------------------------------------
        // GET JOBS
        //---------------------------------------------------------------------------------------------
        getJobs: function () {
            if (this.loadState.jobs) {
                return Promise.resolve(this.jobs);
            } else {
                let data = {
                    token: this.token,
                    jwt: this.jwt,
                    documentNumber: this.info.id,
                    method: "getJobs",
                };

                let httpAffiliateServices = httpsCallable(
                    this.global.functions,
                    "httpAffiliateServices"
                );
                return Promise.resolve(
                    httpAffiliateServices(data)
                        .then((result) => {
                            if (result.data.status == 99) {
                                // this.localStatement.deleteStatement(this.global.firebaseToken.uid);
                                this.signOut();
                                return false;
                            }

                            this.jobs = result.data.info.EMPRESA;

                            if (!Array.isArray(this.jobs))
                                this.jobs = [this.jobs];

                            this.jobs.forEach((job) => {
                                job.code = job["@_IDEMP"];
                                job["FEC-INI-APO"] = Number(
                                    job["FEC-INI-APO"].toString().slice(0, 4)
                                );
                                job["FEC-FIN-APO"] = Number(
                                    job["FEC-FIN-APO"].toString().slice(0, 4)
                                );
                                job.loaded = false;
                                job.funds = {};
                            });

                            this.jobs.sort(
                                (b, a) => a["FEC-INI-APO"] - b["FEC-INI-APO"]
                            );

                            this.loadState.jobs = true;
                            return result;
                        })
                        .catch((err) => {
                            // this.global.throwError(err,'user.getJobs()');
                            // this.fatalErrorUser=true
                            this.global.throwError(
                                "No se obtuvo información de empleo (Código de error: 305)"
                            );
                            throw err;
                        })
                );
            }
        },

        //---------------------------------------------------------------------------------------------
        // GET URL FORM LINK
        //---------------------------------------------------------------------------------------------
        getUrlFormLink: function () {
            if (this.loadState.formLink) {
                return Promise.resolve(this.formLink);
            } else {
                const client = new ClientJS();
                const fingerprint = client.getFingerprint();
                let data = {
                    token: this.token,
                    jwt: this.jwt,
                    documentNumber: this.info.id,
                    fingerprint: fingerprint,
                    method: "getUrlFormUpdateData",
                };
                let httpAffiliateServices = httpsCallable(
                    this.global.functions,
                    "httpAffiliateServices"
                );
                return Promise.resolve(
                    httpAffiliateServices(data)
                        .then((result) => {
                            if (result.data.status == 99) {
                                // this.localStatement.deleteStatement(this.global.firebaseToken.uid);
                                this.signOut();
                                return false;
                            }

                            // this.formLink = result.data.info.EMPRESA;

                            // if (!Array.isArray(this.formLink))
                            //   this.formLink = [this.formLink];

                            this.formLink = result;

                            // this.jobs.forEach((job) =>{
                            //   job.code = job['@_IDEMP'];
                            //   job['FEC-INI-APO'] = Number(job['FEC-INI-APO'].toString().slice(0, 4));
                            //   job['FEC-FIN-APO'] = Number(job['FEC-FIN-APO'].toString().slice(0, 4));
                            //   job.loaded = false;
                            //   job.funds = {};
                            // });

                            // this.jobs.sort((b, a) => (a['FEC-INI-APO'] - b['FEC-INI-APO']))

                            this.loadState.formLink = true;
                            return result;
                        })
                        .catch((err) => {
                            this.global.throwError(
                                err,
                                "user.getUrlFormLink()"
                            );
                            throw err;
                        })
                );
            }
        },

        //---------------------------------------------------------------------------------------------
        // GET CONTRIBUTIONS BY COMPANY
        //---------------------------------------------------------------------------------------------
        getContributionsByCompany: function (company) {
            if (company.loaded) {
                return Promise.resolve(company);
            } else {
                let data = {
                    token: this.token,
                    jwt: this.jwt,
                    documentNumber: this.info.id,
                    accountNumber: company.code,
                    locationCode: company.LOCAL,
                    method: "getContributionsByCompany",
                };


                let httpAffiliateServices = httpsCallable(
                    this.global.functions,
                    "httpAffiliateServices"
                );
                return Promise.resolve(
                    httpAffiliateServices(data)
                        .then((result) => {

                            if (result.data.status == 99) {
                                // this.localStatement.deleteStatement(this.global.firebaseToken.uid);
                                this.signOut();
                                return false;
                            }

                            company.loaded = true;

                            const home = {
                                name: "Fondo de Vivienda",
                                value: 0,
                                percent: 0,
                                palette: 1,

                                component: {
                                    individualVoluntary: {
                                        name: "Individual voluntario",
                                        value: 0,
                                        percent: 0,
                                        palette: 1,
                                    },
                                    individualMandatory: {
                                        name: "Individual obligatorio",
                                        value: 0,
                                        percent: 0,
                                        palette: 2,
                                    },
                                    companyVoluntary: {
                                        name: "Empresarial voluntario",
                                        value: 0,
                                        percent: 0,
                                        palette: 3,
                                    },
                                    companyMandatory: {
                                        name: "Empresarial obligatorio",
                                        value: 0,
                                        percent: 0,
                                        palette: 4,
                                    },
                                    historic: {
                                        name: "Fondo histórico",
                                        value: 0,
                                        percent: 0,
                                        palette: 5,
                                    },
                                    surplus: {
                                        name: "Excedentes/Rendimientos",
                                        value: 0,
                                        percent: 0,
                                        palette: 6,
                                    },
                                },
                            };

                            const retirement = {
                                name: "Fondo de Pensión",
                                value: 0,
                                percent: 0,
                                palette: 2,

                                component: {
                                    individualVoluntary: {
                                        name: "Individual voluntario",
                                        value: 0,
                                        percent: 0,
                                        palette: 1,
                                    },
                                    individualMandatory: {
                                        name: "Individual obligatorio",
                                        value: 0,
                                        percent: 0,
                                        palette: 2,
                                    },
                                    companyVoluntary: {
                                        name: "Empresarial voluntario",
                                        value: 0,
                                        percent: 0,
                                        palette: 3,
                                    },
                                    companyMandatory: {
                                        name: "Empresarial obligatorio",
                                        value: 0,
                                        percent: 0,
                                        palette: 4,
                                    },
                                },
                            };

                            const unemployment = {
                                name: "Fondo de reserva laboral",
                                value: 0,
                                percent: 0,
                                palette: 3,

                                component: {
                                    companyVoluntary: {
                                        name: "Empresarial voluntario",
                                        value: 0,
                                        percent: 0,
                                        palette: 1,
                                    },
                                    companyMandatory: {
                                        name: "Empresarial obligatorio",
                                        value: 0,
                                        percent: 0,
                                        palette: 2,
                                    },
                                },
                            };

                            const fundsArray = Array.isArray(
                                result.data.info.DETALLE
                            )
                                ? result.data.info.DETALLE
                                : [result.data.info.DETALLE];
                            fundsArray.forEach((data) => {
                                home.component.individualVoluntary.value +=
                                    data.COTOBL;
                                home.component.companyVoluntary.value +=
                                    data.OTRAPO;
                                home.component.historic.value += data.RENHIST;
                                home.component.surplus.value += data.RENPREV;

                                retirement.component.individualMandatory.value +=
                                    data.PREVIAFI;
                                retirement.component.companyMandatory.value +=
                                    data.PREVIPAT;
                                retirement.component.individualVoluntary.value +=
                                    data.PREVOAFI;
                                retirement.component.companyVoluntary.value +=
                                    data.PREVOPAT;

                                unemployment.component.companyMandatory.value +=
                                    data.RLABORAL;
                                unemployment.component.companyVoluntary.value +=
                                    data.RLABOVOL;
                            });

                            company.funds = {
                                home,
                                retirement,
                                unemployment,
                            };
                            company.value = 0;

                            for (let key in company.funds) {
                                let fund = company.funds[key];

                                fund.value = 0;

                                for (let key in fund.component)
                                    fund.value += fund.component[key].value;

                                if (fund.value > 0)
                                    // console.log('fund.component[key]',fund.component[key])
                                    // console.log('fund.value',fund.value)
                                    for (let key in fund.component)
                                        if (fund.value !== 0)
                                            fund.component[key].percent =
                                                (fund.component[key].value /
                                                    fund.value) *
                                                100;
                                //fund.component[key].percent = Math.round(fund.component[key].value/fund.value*100)

                                company.value += fund.value;
                            }

                            for (let key in company.funds) {
                                let fund = company.funds[key];
                                if (company.value !== 0) {
                                    fund.percent =
                                        (fund.value / company.value) * 100;
                                } else {
                                    fund.percent = 0;
                                }

                                //fund.percent = Math.round(fund.value/company.value*100);
                            }

                            company.request = result;
                            company.loaded = true;
                            return company;
                        })
                        .catch((err) => {
                            this.global.throwError(
                                err,
                                "user.getContributionsByCompany()"
                            );
                            throw err;
                        })
                );
            }
        },

        //---------------------------------------------------------------------------------------------
        // HANDLE SESSION TIME
        //---------------------------------------------------------------------------------------------
        handleSessionTime(extend) {
            var time = localStorage.getItem("sti");

            if (time) {
                time = new Date(time);
                const diff = Math.abs(new Date() - time);
                const mins = diff / 1000 / 60;
                if (mins >= useGlobalStore().config.sessionTime) {
                    this.signOut();
                    // console.log("SESSION TIMED OUT:", mins);
                } else if (extend) localStorage.setItem("sti", new Date());
            } else if (this.jwt && extend)
                localStorage.setItem("sti", new Date());
        },

        //---------------------------------------------------------------------------------------------
        // SESSION TIME TIMER
        //---------------------------------------------------------------------------------------------
        sessionTimeTimer() {
            this.handleSessionTime(false);
            setTimeout(this.sessionTimeTimer, 1000 * 2);
        },

        //---------------------------------------------------------------------------------------------
        // LOG OUT
        //---------------------------------------------------------------------------------------------
        async signOut() {
            try {
                this.global.showMenu = false;
                // await this.localStatement.updateValidateLoadState(this.global.firebaseToken.uid, false);

                if (this.global.firebaseToken) {

                    const storedData = await this.localStatement.getStatement(this.global.firebaseToken.uid)
                    if (storedData == undefined || storedData.statement == undefined || storedData.statement.token == null) {
                        return Promise.resolve(
                            signOut(this.global.fireauth)
                                .then(() => {
                                    this.global.rapToken = null;
                                    localStorage.removeItem("rto");
                                    localStorage.removeItem("rti");
                                    localStorage.removeItem("sti");
                                    this.global.error.active = false;
                                    this.global.error.ignore = true;

                                    Router.push("/").then(Router.go());
                                })
                                .catch((error) => {
                                    throw error;
                                })
                        );
                    }

                    // this.localStatement.updateStatement(this.global.firebaseToken.uid, { ...storedData.statement, loadState: { ...storedData.statement.loadState, validate: false } });

                    // this.updateToken(this.global.firebaseToken.uid, this.token, this.sid, this.jwt);

                    return Promise.resolve(
                        signOut(this.global.fireauth)
                            .then(() => {
                                this.global.rapToken = null;
                                localStorage.removeItem("rto");
                                localStorage.removeItem("rti");
                                localStorage.removeItem("sti");
                                this.global.error.active = false;
                                this.global.error.ignore = true;

                                Router.push("/").then(Router.go());
                            })
                            .catch((error) => {
                                throw error;
                            })
                    );
                }

            } catch (error) {
                // console.log("SIGN OUT ERROR", error);
                return Promise.resolve(
                    signOut(this.global.fireauth)
                        .then(() => {
                            this.global.rapToken = null;
                            localStorage.removeItem("rto");
                            localStorage.removeItem("rti");
                            localStorage.removeItem("sti");
                            this.global.error.active = false;
                            this.global.error.ignore = true;

                            Router.push("/").then(Router.go());
                        })
                        .catch((error) => {
                            throw error;
                        })
                );
            }

        },

        //=======CHARLIES CODE========
        //========= Prestamos =======
        //============================
        //---------------------------------------------------------------------------------------------
        // GET LOANS
        //---------------------------------------------------------------------------------------------
        getLoans() {
            if (this.loadState.loans) {
                return Promise.resolve(this.loans);
            } else {
                let data = {
                    token: this.token,
                    jwt: this.jwt,
                    documentNumber: this.info.id,
                    method: "getLoans",
                };

                let httpAffiliateServices = httpsCallable(
                    this.global.functions,
                    "httpAffiliateServices"
                );
                try {
                    return Promise.resolve(
                        httpAffiliateServices(data)
                            .then((result) => {
                                if (result.data.status == 99) {
                                    // console.log('SingOut')
                                    // this.localStatement.deleteStatement(this.global.firebaseToken.uid);
                                    this.signOut();
                                    return false;
                                }

                                // return true
                                if (result.data.status === 2) {
                                    //usuario no tiene prestamos
                                    return [];
                                }
                                this.loans = result.data.loans.PRESTAMO;
                                this.userDataLoan = result.data.loans;

                                // console.log("this.loans", this.loans);
                                if (!Array.isArray(this.loans)) {
                                    // console.log('if !Array.isArray(this.loans)');
                                    this.loans = [this.loans];
                                }

                                this.loadState.loans = true;
                                return this.loans;
                            })
                            .catch((err) => {
                                console.log("ERR ==>>> ", err);
                                this.global.throwError(
                                    "No se obtuvo información de préstamos"
                                );
                                throw err;
                            })
                    );
                } catch (error) {
                    this.global.throwError("ERROR de función  préstamos");
                    throw error;
                }
            }
        },

        // GET LOAN DETAIL
        //---------------------------------------------------------------------------------------------
        getLoanDetail(numberLoan) {
            // if (this.loadState.loanDetail) {
            //     // console.log("Hay prestamos en la promesa");
            //     return Promise.resolve(this.loans);
            // } else {
            // console.log("NO hay prestamos en la promesa");
            let data = {
                token: this.token,
                jwt: this.jwt,
                numberLoan: numberLoan,
                method: "getLoanDetail",
            };

            let httpAffiliateServices = httpsCallable(
                this.global.functions,
                "httpAffiliateServices"
            );
            try {
                return Promise.resolve(
                    httpAffiliateServices(data)
                        .then((result) => {
                            if (result.data.status == 99) {
                                // this.localStatement.deleteStatement(this.global.firebaseToken.uid);
                                this.signOut();
                                return false;
                            }

                            // return true
                            // console.log('result LOANS', result);
                            if (result?.data?.loan === null) {
                                this.loanDetail = {
                                    CUOTA: [],
                                }
                                return this.loanDetail;
                            }

                            this.loanDetail = result.data.loanDetail;
                            // console.log("this.loanDetail", this.loanDetail);

                            if (!Array.isArray(this.loanDetail.CUOTA)) {
                                this.loanDetail = {
                                    CUOTA: [this.loanDetail.CUOTA],
                                };
                            }

                            this.loadState.loanDetail = true;
                            return this.loanDetail;
                            // return true
                        })
                        .catch((err) => {
                            console.log("ERR ==>>> ", err);
                            // this.global.throwError(
                            //     "No se obtuvo información de préstamos"
                            // );
                            // throw err;
                        })
                );
            } catch (error) {
                this.global.throwError("ERROR de función  préstamos");
                throw error;
            }
            // }
        },

        // GET LOAN REQUEST
        //---------------------------------------------------------------------------------------------
        getStartLoanRequest() {
            if (this.loadState.loanRequest) {
                return Promise.resolve(this.loanRequest);
            } else {
                let data = {
                    token: this.token,
                    jwt: this.jwt,
                    // numberLoan: numberLoan,
                    terminal: "999",
                    tipo: "PR-APO",
                    clase: "PER",
                    grupo: "CNSMO",
                    salario: 35000,
                    // identidad: "0101196801417",
                    identidad: this.info.id,
                    refinanciado: "N",
                    // cod_cliente: "332238",
                    // cod_cliente: this.info.data["cod-clie"],
                    cod_cliente: this.info.code,
                    // cod_cuenta: "342563",
                    cod_cuenta: this.accounts[0].code,
                    // cod_patronal: "RI-20120040318",
                    cod_patronal: "",
                    method: "getStartLoanRequest",
                };
                // console.log("this.global.functions", this.global.functions);

                let httpAffiliateServices = httpsCallable(
                    this.global.functions,
                    "httpAffiliateServices"
                );
                try {
                    return Promise.resolve(
                        httpAffiliateServices(data)
                            .then((result) => {
                                if (result.data.status == 99) {
                                    // this.localStatement.deleteStatement(this.global.firebaseToken.uid);
                                    this.signOut();
                                    return false;
                                }

                                this.loanRequest = result.data.loanRequest;

                                this.loadState.loanRequest = true;

                                //guardando lo plazos disponibles para prestamos del afiliado
                                if (result.data.loanRequest) {
                                    if (result.data.loanRequest.INICIALIZACION_PRESTAMO) {
                                        if (result.data.loanRequest.INICIALIZACION_PRESTAMO.PLAZOS &&
                                            result.data.loanRequest.INICIALIZACION_PRESTAMO.PLAZOS.PLAZO
                                        ) {
                                            this.terms =
                                                result.data.loanRequest.INICIALIZACION_PRESTAMO.PLAZOS.PLAZO;
                                        }
                                        if (result.data.loanRequest.INICIALIZACION_PRESTAMO.DESTINOS &&
                                            result.data.loanRequest.INICIALIZACION_PRESTAMO.DESTINOS.DESTINO
                                        ) {
                                            this.destinations =
                                                result.data.loanRequest.INICIALIZACION_PRESTAMO.DESTINOS.DESTINO;
                                        }
                                        if (result.data.loanRequest.INICIALIZACION_PRESTAMO.SOLICITUDES) {
                                            if (result.data.loanRequest.INICIALIZACION_PRESTAMO.SOLICITUDES.SOLICITUD) {
                                                if (
                                                    result.data.loanRequest.INICIALIZACION_PRESTAMO.SOLICITUDES.SOLICITUD.MONTO
                                                ) {
                                                    this.amountMaxLoan =
                                                        result.data.loanRequest.INICIALIZACION_PRESTAMO.SOLICITUDES.SOLICITUD.MONTO;
                                                }
                                            }
                                        }
                                        if (result.data.loanRequest.INICIALIZACION_PRESTAMO.AFILIADO) {
                                            if (result.data.loanRequest.INICIALIZACION_PRESTAMO.AFILIADO.DISPONIBLE && result.data.loanRequest.INICIALIZACION_PRESTAMO.AFILIADO.DISPONIBLE != "") {
                                                this.amountMaxLoan =
                                                    result.data.loanRequest.INICIALIZACION_PRESTAMO.AFILIADO.DISPONIBLE;
                                            } else if (result.data.loanRequest.INICIALIZACION_PRESTAMO.AFILIADO.APORTACIONES) {
                                                this.amountMaxLoan = result.data.loanRequest.INICIALIZACION_PRESTAMO.AFILIADO.APORTACIONES;
                                            }
                                            if (result.data.loanRequest.INICIALIZACION_PRESTAMO.AFILIADO.CODPATRONO) {
                                                this.info.codPatrono = result.data.loanRequest.INICIALIZACION_PRESTAMO.AFILIADO.CODPATRONO;
                                            }
                                            if (result.data.loanRequest.INICIALIZACION_PRESTAMO.AFILIADO.PATRONO) {
                                                this.info.patrono = result.data.loanRequest.INICIALIZACION_PRESTAMO.AFILIADO.PATRONO;
                                            }
                                        }
                                    } else {
                                        if (result.data.loanRequest.PLAZOS && result.data.loanRequest.PLAZOS.PLAZO) {
                                            this.terms = result.data.loanRequest.PLAZOS.PLAZO;
                                        }
                                        if (result.data.loanRequest.DESTINOS && result.data.loanRequest.DESTINOS.DESTINO) {
                                            this.destinations = result.data.loanRequest.DESTINOS.DESTINO;
                                        }
                                        if (result.data.loanRequest.AFILIADO) {
                                            if (result.data.loanRequest.AFILIADO.DISPONIBLE) {
                                                this.amountMaxLoan = result.data.loanRequest.AFILIADO.DISPONIBLE == "" ? 0.01 : result.data.loanRequest.AFILIADO.DISPONIBLE;
                                            }
                                            if (result.data.loanRequest.AFILIADO.CODPATRONO) {
                                                this.info.codPatrono = result.data.loanRequest.AFILIADO.CODPATRONO;
                                            }
                                            if (result.data.loanRequest.AFILIADO.PATRONO) {
                                                this.info.patrono = result.data.loanRequest.AFILIADO.PATRONO;
                                            }
                                        }
                                    }
                                }

                                return this.loanRequest;
                            })
                            .catch((err) => {
                                console.log("ERR ==>>> ", err);
                                this.global.throwError(
                                    "No se obtuvo información de préstamos"
                                );
                                throw err;
                            })
                    );
                } catch (error) {
                    this.global.throwError("ERROR de función  préstamos");
                    throw error;
                }
            }
        },

        getQuoteLoan(dataCalculate) {
            // if (this.loadState.quoteLoan) {
            //   return Promise.resolve(this.quoteLoan);
            // } else {
            // console.log("this.info", this.info);
            // console.log("this.accounts", this.accounts);
            let data = {
                token: this.token,
                jwt: this.jwt,
                // usuario:"ARMARTINEZ2",
                usuario: (
                    localStorage.getItem("saveusername") || ""
                ).toUpperCase(),
                // cod_cliente:"806426",
                // cod_cliente: this.info.data["cod-clie"],
                cod_cliente: this.info.code,
                // monto_credito:"31000",
                monto_credito: dataCalculate.amount,
                destino: dataCalculate.destination,
                monto_ahorro: "0",
                // plazo:"12",
                plazo: dataCalculate.term,
                // salario: "25000",
                salario: dataCalculate.salary,
                // cod_patronal:"201830028",
                cod_patronal: "",
                tipo_tramite: "N",
                method: "getQuoteLoan",
            };

            // console.log("this.global.functions", this.global.functions);

            let httpAffiliateServices = httpsCallable(
                this.global.functions,
                "httpAffiliateServices"
            );
            try {
                return Promise.resolve(
                    httpAffiliateServices(data)
                        .then((result) => {
                            if (result.data.status == 99) {
                                // this.localStatement.deleteStatement(this.global.firebaseToken.uid);
                                this.signOut();
                                return false;
                            }

                            this.quoteLoan = result.data.quoteLoan;

                            this.loadState.quoteLoan = true;
                            return this.quoteLoan;
                        })
                        .catch((err) => {
                            this.global.throwError(
                                "No se obtuvo información calculadora"
                            );
                            throw err;
                        })
                );
            } catch (error) {
                this.global.throwError("ERROR de función calculadora");
                throw error;
            }
            // }
        },

        getInterestRate(dataCalculate) {
            // if (this.loadState.quoteLoan) {
            //   return Promise.resolve(this.quoteLoan);
            // } else {
            // console.log("this.info", this.info);
            // console.log("this.accounts", this.accounts);
            let data = {
                token: this.token,
                jwt: this.jwt,
                identificacion: this.info.id,
                salario: dataCalculate.salary,
                tipo_tramite: "N",
                method: "getInterestRate",
            };

            // console.log("this.global.functions", this.global.functions);

            let httpAffiliateServices = httpsCallable(
                this.global.functions,
                "httpAffiliateServices"
            );
            try {
                return Promise.resolve(
                    httpAffiliateServices(data)
                        .then((result) => {
                            if (result.data.status == 99) {
                                // this.localStatement.deleteStatement(this.global.firebaseToken.uid);
                                this.signOut();
                                return false;
                            }

                            this.interestRate = result.data;

                            // this.loadState.quoteLoan = true;
                            return this.interestRate;
                        })
                        .catch((err) => {
                            console.log("ERR ==>>> ", err);
                            this.global.throwError(
                                "No se obtuvo información de tasa de interés"
                            );
                            throw err;
                        })
                );
            } catch (error) {
                this.global.throwError("ERROR de función get tasa de interés");
                throw error;
            }
            // }
        },

        getPreApprovedLoans() {
            // if (this.loadState.quoteLoan) {
            //   return Promise.resolve(this.quoteLoan);
            // } else {
            // console.log("this.info", this.info);
            // console.log("this.accounts", this.accounts);
            let data = {
                token: this.token,
                jwt: this.jwt,
                identificacion: this.info.id,
                method: "getPreApprovedLoans",
            };

            // console.log("this.global.functions", this.global.functions);

            let httpAffiliateServices = httpsCallable(
                this.global.functions,
                "httpAffiliateServices"
            );
            try {
                return Promise.resolve(
                    httpAffiliateServices(data)
                        .then((result) => {
                            if (result.data) {
                                if (result.data.status && result.data.status == 99) {
                                    // this.localStatement.deleteStatement(this.global.firebaseToken.uid);
                                    this.signOut();
                                    return false;
                                }
                                if (result.data.preApprovedLoans) {
                                    this.preApprovedLoans = result.data.preApprovedLoans;

                                    // this.loadState.quoteLoan = true;
                                    return this.preApprovedLoans;
                                } else {
                                    return {
                                        result: {
                                            data: {
                                                preApprovedLoans: ["No hay préstamos pre aprobados"],
                                            }
                                        }
                                    };
                                }
                            }
                        })
                        .catch((err) => {
                            console.log("ERR ==>>> ", err);
                            this.global.throwError(
                                "No se obtuvo información de préstamos pre aprobados"
                            );
                            throw err;
                        })
                );
            } catch (error) {
                this.global.throwError("ERROR de función get préstamos pre aprobados");
                throw error;
            }
            // }
        },

        getBrowserName(userAgentString) {
            if (userAgentString.includes("Firefox")) {
                return "Firefox";
            } else if (userAgentString.includes("Chrome") && userAgentString.includes("Safari")) {
                return "Chrome";
            } else if (userAgentString.includes("Safari") && !userAgentString.includes("Chrome")) {
                return "Safari";
            } else if (userAgentString.includes("Edge")) {
                return "Edge";
            } else if (userAgentString.includes("Opera") || userAgentString.includes("OPR")) {
                return "Opera";
            } else {
                return "Unknown Browser";
            }
        },

        // retrieve system data for password reset email
        operatingSytemAndBrowser() {
            let userAgent = navigator.userAgent;
            let browserName;
            let OSName;

            if (userAgent.match(/chrome|chromium|crios/i)) {
                browserName = "Chrome";
            } else if (userAgent.match(/firefox|fxios/i)) {
                browserName = "Firefox";
            } else if (userAgent.match(/safari/i)) {
                browserName = "Safari";
            } else if (userAgent.match(/opr\//i)) {
                browserName = "Opera";
            } else if (userAgent.match(/edg/i)) {
                browserName = "Edge";
            } else {
                browserName = "No browser detection";
            }
            // console.log("browserName...:", browserName);

            if (userAgent.match(/Win|win/i)) {
                OSName = "Windows";
            } else if (userAgent.match(/Mac|mac/i)) {
                OSName = "MacOS";
            } else if (userAgent.match(/X11|x11/i)) {
                OSName = "UNIX";
            } else if (userAgent.match(/Linux|linux/i)) {
                OSName = "Linux";
            } else {
                OSName = "Unknown OS";
            }
            // console.log("OSName...:", OSName);

            return { browserName: browserName, OSName: OSName };
        },

        async sendLoanRequest(dataCalculate) {
            const ip_address = await Axios.get("https://api.ipify.org?format=json");
            const systemData = this.operatingSytemAndBrowser();
            const browser = systemData.browserName;
            let data = {
                token: this.token,
                jwt: this.jwt,
                cod_cliente: this.info.code,
                cod_cuenta: this.accounts[0].code,
                tasa: dataCalculate.interest_rate,
                identificacion: this.info.id,
                salary: dataCalculate.salary,
                cod_patrono: this.info.codPatrono,
                patrono: this.info.patrono,
                monto: dataCalculate.amount,
                plazo: dataCalculate.term,
                cuota: dataCalculate.installment,
                destino: dataCalculate.destination,
                usuario: (localStorage.getItem("saveusername") || "").toUpperCase(),
                ip: ip_address.data.ip,
                browser: browser,
                os: systemData.OSName,
                method: "sendLoanRequest"
            };


            let httpAffiliateServices = httpsCallable(
                this.global.functions,
                "httpAffiliateServices"
            );
            try {
                return Promise.resolve(
                    httpAffiliateServices(data)
                        .then((result) => {
                            if (result.data) {
                                if (result.data.status && result.data.status == 99) {
                                    // this.localStatement.deleteStatement(this.global.firebaseToken.uid);
                                    this.signOut();
                                    return false;
                                }
                                if (result.data.loanRequest) {
                                    this.loanRequest = result.data.loanRequest;

                                    // this.loadState.quoteLoan = true;
                                    return this.loanRequest;
                                } else {
                                    if (result.data.message) {
                                        return {
                                            result: {
                                                data: {
                                                    status: 2,
                                                    message: result.data.message,
                                                    loanRequest: result.data.message,
                                                }
                                            }
                                        }
                                    }
                                    return {
                                        result: {
                                            data: {
                                                status: 2,
                                                message: 'No se pudo enviar la solicitud',
                                                loanRequest: ["No se pudo enviar la solicitud de préstamo"],
                                            }
                                        }
                                    };
                                }
                            }
                        })
                        .catch((err) => {
                            console.log("ERR ==>>> ", err);
                            this.global.throwError(
                                "Ocurrió un error al enviar la solicitud de préstamo"
                            );
                            throw err;
                        })
                );
            } catch (error) {
                this.global.throwError("ERROR de función enviar solicitud de préstamo");
                throw error;
            }
        }
    },
});
